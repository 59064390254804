import React from 'react';
import useStyles from './style';
import { Button, Typography, AlertTitle, Alert, LinearProgress, TextField, InputAdornment, IconButton } from '@mui/material';
import { FaPencilAlt as EditIcon, FaTrashAlt as DeleteIcon, FaPlus as AddIcon } from 'react-icons/fa';
import AddCategoryDialog from './AddCategoryDialog';
import { useGetAllCategoriesAdminQuery } from '../../../store/reduxApi';
import clsx from 'clsx';
import UpdateCategoryDialog from './UpdateCategoryDialog';
import DeleteCategoryDialog from './DeleteCategoryDialog';
import { RxMagnifyingGlass as MagnifyIcon } from 'react-icons/rx';
import ShowSuccess from '../../Alerts/ShowSuccess';
import ShowError from '../../Alerts/ShowError';
import ShowLoading from '../../Alerts/ShowLoading';

const Categories = () => {

  const classes = useStyles();

  const [openAddCategoryDialog, setOpenAddCategoryDialog] = React.useState(false);

  const [search, setSearch] = React.useState('');
  const [searchStart, setSearchStart] = React.useState(false);

  const [updateCategoryDialog, setUpdateCategoryDialog] = React.useState({
    open: false,
    category: false
  });

  const [deleteCategoryDialog, setDeleteCategoryDialog] = React.useState({
    open: false,
    category: false
  });

  const { isLoading, isError, data = {}, refetch } = useGetAllCategoriesAdminQuery({ searchWord: search });


  // React.useEffect(() => {
  //   if (searchStart) {
  //     search.length > 0 ? searchCategories(search) : refetch();
  //   }
  // }, [searchStart, search]);

  return (
    <div className={classes.bigDiv}>
      <div className={classes.addButtonDiv}>
        <Button
          onClick={() => { setOpenAddCategoryDialog(true); }}
          style={{ backgroundColor: '#26A65B' }}
          variant='contained'
          disableElevation
        >
          <AddIcon style={{ marginRight: '5px' }} />
          dodaj
        </Button>

      </div>
      <div className={classes.searchDiv}>
        <TextField
          disabled={isError}
          autoComplete='off'
          sx={{
            '& .MuiInputAdornment-root': {
              marginRight: '15px'
            },
            '& .MuiOutlinedInput-root': {
              height: '40px',
              paddingLeft: '0px',
              paddingRight: '0px',
              "&.Mui-focused fieldset": {
                borderColor: "#f86673"
              }
            },
          }}
          fullWidth
          inputProps={{ style: { fontSize: '14px' } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <MagnifyIcon />
              </InputAdornment>
            ),
          }}
          placeholder='Pretraži kategorije'
          value={search}
          onChange={(e) => { setSearchStart(true); setSearch(e.target.value); }}
          size="small"
        //helperText='Minimum 3 karaktera'
        />

      </div>

      <div className={classes.categoriesList}>


        <table className={classes.table}>
          <tr className={classes.main}>
            <th>Naziv</th>
            <th>Seo</th>
            <th style={{ textAlign: 'center' }}>Akcija</th>
          </tr>

          {isLoading ? <ShowLoading /> : null}
          {isError ? <ShowError errorText='Neuspješno prikazivanje svih kategorija' /> : null}
          
          {
            data?.categories?.length === 0
              ?
              <Typography style={{ marginTop: '20px' }}>Kategorije nisu pronađene</Typography>
              :
              null
          }
          {
            data?.categories?.map((category, index) => {
              return (
                <>
                  <tr
                    style={{ backgroundColor: category.parent_category === null ? '#bfbfbf' : 'inherit' }}
                    key={index}>
                    <td>{category.name}</td>
                    <td className={classes.subrow}>{category.seo}</td>
                    <td >
                      <div className={classes.buttons}>
                        <Button
                          onClick={() => {
                            setUpdateCategoryDialog({
                              open: true,
                              category: category
                            })
                          }}
                          variant='contained'
                          disableElevation style={{ backgroundColor: '#006442', minWidth: '15px' }}>
                          <EditIcon fontSize='10' />
                        </Button>
                        <Button
                          onClick={() => {
                            setDeleteCategoryDialog({
                              open: true,
                              category: category
                            })
                          }}
                          variant='contained'
                          disableElevation style={{ backgroundColor: '#DC3023', minWidth: '15px' }}>
                          <DeleteIcon fontSize='10' />
                        </Button>
                      </div>
                    </td>
                  </tr>
                  {
                    category.childCategories
                      ?
                      category.childCategories.map((childCategory, index) => {
                        return (
                          <tr className={classes.subrow} key={index}>
                            <td>{childCategory.name}</td>
                            <td>{childCategory.seo}</td>
                            <td >
                              <div className={classes.buttons}>
                                <Button
                                  onClick={() => {
                                    setUpdateCategoryDialog({
                                      open: true,
                                      category: childCategory
                                    })
                                  }}
                                  variant='contained'
                                  disableElevation style={{ backgroundColor: '#006442', minWidth: '15px' }}>
                                  <EditIcon fontSize='10' />
                                </Button>
                                <Button
                                  onClick={() => {
                                    setDeleteCategoryDialog({
                                      open: true,
                                      category: childCategory
                                    })
                                  }}
                                  variant='contained'
                                  disableElevation style={{ backgroundColor: '#DC3023', minWidth: '15px' }}>
                                  <DeleteIcon fontSize='10' />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                      :
                      null
                  }
                </>
              )
            })
          }
        </table>
      </div>
      <AddCategoryDialog
        open={openAddCategoryDialog}
        setOpen={setOpenAddCategoryDialog}
        ShowLoading={ShowLoading}
        ShowSuccess={ShowSuccess}
        ShowError={ShowError} />
      <UpdateCategoryDialog
        open={updateCategoryDialog.open}
        setOpen={setUpdateCategoryDialog}
        category={updateCategoryDialog.category}
        ShowLoading={ShowLoading}
        ShowSuccess={ShowSuccess}
        ShowError={ShowError} />
      <DeleteCategoryDialog
        open={deleteCategoryDialog.open}
        setOpen={setDeleteCategoryDialog}
        category={deleteCategoryDialog.category}
        ShowLoading={ShowLoading}
        ShowSuccess={ShowSuccess}
        ShowError={ShowError} />
    </div>
  );
};

export default Categories