import React from 'react';
import useStyle from './style';
import { Button, IconButton, Typography } from '@mui/material';
import { MdArrowBack as BackIcon } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { GoLocation as LocationIcon } from 'react-icons/go';
import { CiSquareMinus as MinusIcon, CiSquarePlus as PlusIcon } from 'react-icons/ci';
import { GlobalContext } from '../../App';

const Cart = () => {

    const classes = useStyle();
    const navigate = useNavigate();

    const [emptyCartState, setEmptyCartState] = React.useState(false);

    const { cart, setCart, currentPage, setCurrentPage, setCurrentProduct } = React.useContext(GlobalContext);

    const increaseQty = (product) => {
        const updatedCart = [...cart];
        const index = updatedCart.findIndex(cartItem => cartItem.id === product.id);
        if (updatedCart[index].qty + 1 > product.limit_per_customer) {
            alert(`Maksimum ${product.limit_per_customer} komada po kupcu`);
        } else {
            updatedCart[index].qty = updatedCart[index].qty + 1;
            localStorage.setItem('cart', JSON.stringify(updatedCart));
            setCart([...updatedCart]);
        }
    };

    const decreaseQty = (product) => {
        const updatedCart = [...cart];
        const index = updatedCart.findIndex(cartItem => cartItem.id === product.id);
        if (updatedCart[index].qty - 1 === 0) {
            updatedCart.splice(index, 1);
            localStorage.setItem('cart', JSON.stringify(updatedCart));
            setCart([...updatedCart]);
        } else {
            updatedCart[index].qty = updatedCart[index].qty - 1;
            localStorage.setItem('cart', JSON.stringify(updatedCart));
            setCart([...updatedCart]);
        }
    };

    const removeItem = (productId) => {
        const updatedCart = [...cart];
        const index = updatedCart.findIndex(cartItem => cartItem.id === productId);
        updatedCart.splice(index, 1);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        setCart([...updatedCart]);
    };

    const getTotalPrice = () => {
        const totalPrice = cart.reduce((acc, item) => {
            const price = item.discount ? +item.discount_price : +item.regular_price;
            return acc + price * item.qty;
        }, 0);
        return totalPrice.toFixed(2);
    };

    const handleEmptyCart = () => {
        localStorage.removeItem('cart');
        setCart([]);
        setEmptyCartState(false);
    };

    const handleCartItemClick = (id) => {
        navigate(`/products/${id}`, { state: { previousPath: currentPage } });
        localStorage.setItem('currentPage', JSON.stringify(2));
        localStorage.setItem('currentProduct', JSON.stringify(id));
        setCurrentPage(2);
        setCurrentProduct(id);
    };

    return (
        <div className={classes.cartPageBigDiv}>
            <div className={classes.cartPageNav}>
                <BackIcon style={{ fontSize: '22px', marginLeft: '8px' }} onClick={() => { navigate(-1); }} />
                <div className={classes.cartPageAddress}>
                    <LocationIcon style={{ marginRight: '5px', fontSize: '14px' }} />
                    <Typography variant='subtitle2'>Podgorica</Typography>
                </div>
            </div>
            <div className={classes.cartItemsNumDiv}>
                <Typography style={{ fontSize: '26px', fontWeight: 'bold' }}>
                    Korpa ( {cart.length} )
                </Typography>
            </div>
            <div className={classes.cartItemsDiv}>
                <div style={{ marginBottom: '5px' }}>
                    <Typography variant='subtitle2' style={{ color: '#BDC3C7' }}>
                        {
                            cart.length
                                ?
                                `Lista proizvoda (${cart.length})`
                                :
                                'Trenutno nema prozivoda u korpi'
                        }
                    </Typography>
                </div>

                <div className={classes.cartItemsList}>
                    {
                        cart.map((cartItem, index) => {
                            return (
                                <div
                                    onClick={() => { handleCartItemClick(cartItem.productId); }}
                                    key={index}
                                    className={classes.cartItem}>
                                    <div
                                        style={{ backgroundImage: `url('${process.env.REACT_APP_UPLOADS_PATH + cartItem.photo}')` }}
                                        className={classes.cartItemPhoto}>
                                    </div>
                                    <div className={classes.cartItemData}>
                                        <div className={classes.discountAndRemove}>
                                            <div className={classes.discount}>
                                                {
                                                    cartItem.discount
                                                        ?
                                                        <Typography style={{ fontSize: '14px' }}>
                                                            - {cartItem.discount} %
                                                        </Typography>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <Typography
                                                onClick={(e) => {
                                                    e.stopPropagation(); 
                                                    removeItem(cartItem.id); 
                                                }}
                                                style={{ fontSize: '14px', color: '#3f51b5' }}>
                                                Ukloni
                                            </Typography>
                                        </div>
                                        <div className={classes.data}>
                                            <Typography style={{ fontSize: '14px' }}>
                                                {cartItem.name}
                                            </Typography>
                                            <Typography style={{ fontSize: '12px', color: '#BDC3C7' }}>
                                                {cartItem.specs}
                                            </Typography>
                                            {
                                                cartItem.discount
                                                    ?
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                                        <Typography style={{ fontSize: '17px' }}>
                                                            {cartItem.discount_price}&euro;
                                                        </Typography>
                                                        <Typography style={{ fontSize: '14px', textDecoration: 'line-through', color: '#BDC3C7' }}>
                                                            {cartItem.regular_price}&euro;
                                                        </Typography>
                                                    </div>
                                                    :
                                                    <Typography style={{ fontSize: '17px' }}>
                                                        {cartItem.regular_price}&euro;
                                                    </Typography>
                                            }
                                        </div>
                                        <div className={classes.qtyDiv}>
                                            <div className={classes.qty}>

                                                <MinusIcon
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        decreaseQty(cartItem);
                                                    }}
                                                    fontSize='30'
                                                    color='#BDC3C7' />

                                                <div style={{ marginLeft: '5px', marginRight: '5px' }}>
                                                    <Typography style={{ fontSize: '22px' }}>
                                                        {cartItem.qty}
                                                    </Typography>
                                                </div>

                                                <PlusIcon
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        increaseQty(cartItem);
                                                    }}
                                                    fontSize='30'
                                                    color='#BDC3C7' />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                {
                    cart.length
                        ?
                        !emptyCartState
                            ?
                            <div className={classes.emptyButton}>
                                <Button
                                    onClick={() => { setEmptyCartState(true); }}
                                    disableElevation
                                    variant='contained'
                                    style={{ backgroundColor: 'black', textTransform: 'none' }}
                                    size='small'>
                                    Isprazni korpu
                                </Button>
                            </div>
                            :
                            <div className={classes.emptyButtonQuestion}>
                                <Typography style={{ fontSize: "14px" }}>
                                    Da li ste sigurni ?
                                </Typography>
                                <Button
                                    onClick={handleEmptyCart}
                                    disableElevation
                                    variant='contained'
                                    size='small'
                                    style={{
                                        backgroundColor: 'black',
                                        textTransform: 'none',
                                        padding: '1px',
                                        minWidth: '50px'
                                    }}>
                                    Da
                                </Button>
                                <Button
                                    onClick={() => { setEmptyCartState(false); }}
                                    disableElevation
                                    variant='contained'
                                    size='small'
                                    style={{
                                        backgroundColor: 'black',
                                        textTransform: 'none',
                                        padding: '1px',
                                        minWidth: '50px'
                                    }}>
                                    Ne
                                </Button>
                            </div>
                        :
                        null
                }

            </div>
            <div className={classes.bottomBar}>
                <Typography style={{ fontSize: '26px', color: '#ec1767' }}>
                    {getTotalPrice()}&euro;
                </Typography>
                <Button
                    disabled={!cart.length}
                    size='small'
                    variant='contained'
                    disableElevation
                    style={{ backgroundColor: '#ec1767' }}
                >
                    Nastavi
                </Button>
            </div>
        </div>
    );
};

export default Cart;