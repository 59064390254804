import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    adminPageBigDiv : {
        marginBottom : '20px'
    },
    adminPageNav : {
        width: '100%',
        margin: 'auto',
        height: '45px',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        marginBottom: '20px',
        backgroundColor: '#ec1767',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color : 'white'
    },
    adminPageData : {
        marginLeft : '20px'
    },
    pageTypes : {
        //border : '2px solid red',
        width : '90%',
        margin : 'auto',
        marginTop : '25px',
        display : 'grid',
        gridTemplateColumns : '1fr 1fr',
        gap : '10px',
    },
    pageType : {
        //border : '2px solid #f86673',
        //width: 'calc(45vw - 10px)',
        height : '100px',
        borderRadius : '8px',
        backgroundColor : '#26A65B',
        color : 'white',
        display : 'flex',
        flexDirection : 'column',
        justifyContent : 'center',
        alignItems : 'flex-start',
        paddingLeft : '15px'
    },
    adminPageLogoutDiv : {
        color : 'white',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-start',
        alignItems : 'center',
        marginRight : '8px'
    }
}));

export default useStyles;