import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const reduxApi = createApi({
    reducerPath: 'reduxApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL, credentials: 'include' }),
    tagTypes: [
        'Auth', 'NotificationsAdmin', 'Notifications',
        'Slides', 'CategoriesAdmin', 'Specifications',
        'ProductsAdmin', 'GlobalDiscount'
    ],
    endpoints: (builder) => ({
        //Users
        getUsers: builder.query({
            query: () => 'users/all'
        }),
        loginUser: builder.mutation({
            query: (body) => ({
                url: 'users/login',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Auth'],
            transformResponse: (response) => {
                localStorage.setItem('loggedUserData', JSON.stringify(response.user));
                return response;
            }
        }),
        registerUser: builder.mutation({
            query: (body) => ({
                url: 'users/register',
                method: 'POST',
                body
            })
        }),
        checkAuth: builder.query({
            query: () => 'users/checkAuth',
            providesTags: ['Auth']
        }),
        logout: builder.mutation({
            query: () => ({
                url: 'users/logout',
                method: 'POST'
            }),
            invalidatesTags: ['Auth', 'Notifications'],
            transformResponse: () => {
                localStorage.removeItem('loggedUserData');
            }
        }),
        //Notifications
        addNewNotification: builder.mutation({
            query: (body) => ({
                url: 'notifications/add',
                method: 'POST',
                body
            }),
            invalidatesTags: ['NotificationsAdmin']
        }),
        getAllNotificationsAdmin: builder.query({
            query: () => 'notifications/getAdmin',
            providesTags: ['NotificationsAdmin']
        }),
        getAllNotificationsUser: builder.query({
            query: () => 'notifications/all',
            providesTags: ['Notifications']
        }),
        updateNotification: builder.mutation({
            query: (body) => ({
                url: 'notifications/update',
                method: 'POST',
                body
            }),
            invalidatesTags: ['NotificationsAdmin']
        }),
        deleteNotification: builder.mutation({
            query: (body) => ({
                url: 'notifications/delete',
                method: 'POST',
                body
            }),
            invalidatesTags: ['NotificationsAdmin']
        }),
        markNotificationAsRead: builder.mutation({
            query: (body) => ({
                url: 'notifications/markAsRead',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Notifications']
        }),
        checkForNotifications: builder.query({
            query: () => 'notifications/checkForNotifications',
            providesTags: ['Notifications']
        }),
        //Slides
        addSlide: builder.mutation({
            query: (body) => ({
                url: 'slides/add',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Slides']
        }),
        deleteSlide: builder.mutation({
            query: (body) => ({
                url: 'slides/delete',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Slides']
        }),
        updateSlide: builder.mutation({
            query: (body) => ({
                url: 'slides/update',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Slides']
        }),
        getSlides: builder.query({
            query: () => 'slides/all',
            providesTags: ['Slides']
        }),
        //Categories
        addCategory: builder.mutation({
            query: (body) => ({
                url: 'categories/add',
                method: 'POST',
                body
            }),
            invalidatesTags: ['CategoriesAdmin']
        }),
        updateCategory: builder.mutation({
            query: (body) => ({
                url: 'categories/update',
                method: 'POST',
                body
            }),
            invalidatesTags: ['CategoriesAdmin']
        }),
        getParentCategoriesAdmin: builder.query({
            query: () => 'categories/parentCategoriesAdmin',
            providesTags: ['CategoriesAdmin']
        }),
        getParentCategories: builder.query({
            query: () => 'categories/parentCategories'
        }),
        getChildCategories: builder.query({
            query: () => 'categories/childCategories'
        }),
        getAllCategories: builder.query({
            query: () => 'categories/all'
        }),
        getAllCategoriesAdmin: builder.query({
            query: ({ searchWord }) => `categories/allAdmin?searchWord=${searchWord}`,
            providesTags: ['CategoriesAdmin']
        }),
        deleteCategory: builder.mutation({
            query: (body) => ({
                url: 'categories/delete',
                method: 'POST',
                body
            }),
            invalidatesTags: ['CategoriesAdmin'],
        }),
        // Specifications
        addSpecification: builder.mutation({
            query: (body) => ({
                url: 'specifications/add',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Specifications']
        }),
        deleteSpecification: builder.mutation({
            query: (body) => ({
                url: 'specifications/delete',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Specifications']
        }),
        getSpecifications: builder.query({
            query: () => 'specifications/all',
            //providesTags: ['Specifications']
        }),
        getSpecificationsAdmin: builder.query({
            query: (specName) => `specifications/allAdmin?specName=${specName}`,
            providesTags: ['Specifications']
        }),
        updateSpecification: builder.mutation({
            query: (body) => ({
                url: 'specifications/update',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Specifications']
        }),
        getSpecOfCat: builder.query({
            query: ({ categoryId }) => `specifications/getSpecOfCat?categoryId=${categoryId}`,
        }),
        //Products
        addProduct: builder.mutation({
            query: (body) => ({
                url: 'products/add',
                method: 'POST',
                body
            }),
            invalidatesTags: ['ProductsAdmin']
        }),
        getAllProductsAdmin: builder.query({
            query: ({ name, category, dateAdded }) => `products/allProductsAdmin?name=${name}&category=${category}&dateAdded=${dateAdded}`,
            providesTags: ['ProductsAdmin']
        }),
        updateProduct: builder.mutation({
            query: (body) => ({
                url: 'products/update',
                method: 'POST',
                body
            }),
            invalidatesTags: ['ProductsAdmin']
        }),
        deleteProduct: builder.mutation({
            query: (body) => ({
                url: 'products/delete',
                method: 'POST',
                body
            }),
            invalidatesTags: ['ProductsAdmin']
        }),
        deleteVariation: builder.mutation({
            query: (body) => ({
                url: 'products/deleteVariation',
                method: 'POST',
                body
            }),
            invalidatesTags: ['ProductsAdmin']
        }),
        updateVariation: builder.mutation({
            query: (body) => ({
                url: 'products/updateVariation',
                method: 'POST',
                body
            }),
            invalidatesTags: ['ProductsAdmin']
        }),
        getNewestProducts: builder.query({
            query: () => `products/newest`
        }),
        getProducts: builder.query({
            query: ({ category, searchProductWord, page }) => `products/search?category=${category}&name=${searchProductWord}&page=${page}`
        }),
        getProduct: builder.query({
            query: ({ id }) => `products/product/${id}`
        }),
        getGlobalDiscount: builder.query({
            query: () => `users/globalDiscount`,
            providesTags: ['GlobalDiscount']
        }),
        setGlobalDiscount: builder.mutation({
            query: (body) => ({
                url: 'users/globalDiscount',
                method: 'POST',
                body
            }),
            invalidatesTags: ['GlobalDiscount']
        }),
    }),
});

export const {
    useGetUsersQuery,
    useLoginUserMutation,
    useRegisterUserMutation,
    useCheckAuthQuery,
    useLogoutMutation,
    useAddNewNotificationMutation,
    useGetAllNotificationsAdminQuery,
    useDeleteNotificationMutation,
    useUpdateNotificationMutation,
    useGetAllNotificationsUserQuery,
    useMarkNotificationAsReadMutation,
    useLazyCheckForNotificationsQuery,
    useAddSlideMutation,
    useGetSlidesQuery,
    useDeleteSlideMutation,
    useUpdateSlideMutation,
    useAddCategoryMutation,
    useLazyGetParentCategoriesAdminQuery,
    useGetParentCategoriesQuery,
    useGetAllCategoriesQuery,
    useGetAllCategoriesAdminQuery,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation,
    useAddSpecificationMutation,
    useLazyGetSpecificationsQuery,
    useGetSpecificationsAdminQuery,
    useUpdateSpecificationMutation,
    useDeleteSpecificationMutation,
    useLazyGetChildCategoriesQuery,
    useAddProductMutation,
    useGetAllProductsAdminQuery,
    useUpdateProductMutation,
    useGetNewestProductsQuery,
    useLazyGetProductsQuery,
    useDeleteProductMutation,
    useGetProductQuery,
    useLazyGetSpecOfCatQuery,
    useDeleteVariationMutation,
    useUpdateVariationMutation,
    useGetGlobalDiscountQuery,
    useSetGlobalDiscountMutation } = reduxApi;