import React from 'react';
import {
  Toolbar, AppBar, Button, Slide, Typography,
  Dialog, DialogContent, Grid, TextField,
  FormControl, Select, InputLabel, MenuItem, InputAdornment, FormHelperText, Divider
} from '@mui/material';
import { TfiClose as CloseIcon } from 'react-icons/tfi';
import useStyles from './style';
import { FaTrashAlt as DeleteIcon } from 'react-icons/fa';
import moment from 'moment';
import { FaPlus as AddIcon } from 'react-icons/fa';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { MdDateRange as DateIcon } from 'react-icons/md';
import { useUpdateVariationMutation } from '../../../store/reduxApi';
import ShowLoading from '../../Alerts/ShowLoading';
import ShowError from '../../Alerts/ShowError';
import ShowSuccess from '../../Alerts/ShowSuccess';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialProductVariationData = {
  id: '',
  price: '',
  discount: '',
  discount_price: '',
  discount_date: '',
  barcode: '',
  qty: '',
  variationGallery: [],
  variationSpecs: []
};


const UpdateProductVariation = ({
  open,
  setOpen,
  category,
  productPrice,
  specs,
  getTextFieldType,
  variation,
  getSpecOptions,
  getInputData
}) => {

  const classes = useStyles();

  const [productVariationData, setProductVariationData] = React.useState(initialProductVariationData);

  const [photosToDelete, setPhotosToDelete] = React.useState([]);

  const [openDate, setOpenDate] = React.useState(false);

  const [previewPhotoGallery, setPreviewPhotoGallery] = React.useState([]);

  const [updateVariation, { isLoading, isError, isSuccess, error, reset }] = useUpdateVariationMutation();

  const inputRef = React.useRef();

  const handleImageChange = (e) => {
    if (e.target.files) {

      const newFiles = Array.from(e.target.files).map((file) => ({
        path: URL.createObjectURL(file),
        id: null
      }));

      setProductVariationData((prevData) => {
        return {
          ...prevData,
          variationGallery: prevData.variationGallery.concat([...e.target.files])
        }
      });
      setPreviewPhotoGallery((prevImageGallery) => prevImageGallery.concat(newFiles));
    }
  };

  const handleImageRemoval = (id = null, index) => {

    setPhotosToDelete((prevData) => [...prevData, id]);

    setProductVariationData((prevData) => {
      return {
        ...prevData,
        variationGallery: prevData.variationGallery.filter((photo, photoIndex) => photoIndex !== index)
      }
    });

    setPreviewPhotoGallery((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
  };

  const handleClose = () => {
    setOpen({
      open: false,
      variation: null
    });
    setProductVariationData(initialProductVariationData);
    setPreviewPhotoGallery([]);
    reset();
  };

  React.useEffect(() => {
    if (variation) {
      console.log(variation);
      setProductVariationData({ ...variation });
      setPreviewPhotoGallery([...variation.variationGallery]);
    }
  }, [variation]);

  React.useEffect(() => {
    if(productVariationData.discount){
      if (productVariationData.discount.length && productVariationData.price.length) {
        const newPrice = productVariationData.price - productVariationData.discount / 100 * productVariationData.price;
        setProductVariationData({
          ...productVariationData,
          discount_price: newPrice.toFixed(2)
        })
      }
      if (!productVariationData.discount.length) {
        setProductVariationData({
          ...productVariationData,
          discount_price: ''
        })
      }
    }
  }, [productVariationData.discount]);

  const handleUpdateProductVariation = () => {
    const formData = new FormData();

    formData.append('photosToDelete', JSON.stringify(photosToDelete));
    productVariationData.variationGallery.filter(photo => !photo.id)
      .forEach(photo => {
        formData.append('variationGallery', photo);
      });

    delete productVariationData.variationGallery;

    formData.append('variationData',JSON.stringify(productVariationData));

    updateVariation(formData);
  };


  const GetAlert = () => {
    if (isLoading) return <ShowLoading />
    if (isError) return <ShowError
      errorText={error?.status === 403 ? error?.data?.message : 'Neuspješno ažuriranje varijacije'}
      errCode={error?.status} />
    if (isSuccess) return <ShowSuccess successText='Uspješno ažuriranje varijacija' />
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: '#d92130'
        }}>
        <Toolbar sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'white'
        }}>

          <CloseIcon
            fontSize='22'
            onClick={handleClose}
            color='#ffffff'
            style={{ color: 'white' }} />

          <Button
            onClick={handleUpdateProductVariation}
            variant='outlined'
            disableElevation
            type='submit'
            style={{ backgroundColor: 'white', color: 'black', border: 'black' }}
          >
            Azuriraj varijaciju
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent style={{ marginTop: '15px' }}>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <GetAlert />
          </Grid>

          <Grid item xs={12}>
            <TextField
              autoComplete='off'
              label='Cijena varijacije &euro;'
              type='number'
              value={productVariationData.price || ''}
              onChange={(e) => { setProductVariationData({ ...productVariationData, price: e.target.value }) }}
              size='small'
              name='price'
              fullWidth
              variant='outlined'
            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              autoComplete='off'
              label='Popust %'
              type='number'
              value={productVariationData.discount || ''}
              onChange={(e) => { setProductVariationData({ ...productVariationData, discount: e.target.value }) }}
              size='small'
              fullWidth
              variant='outlined'
            />
          </Grid>

          <Grid item xs={7}>
            <TextField
              disabled={!Boolean(productVariationData.discount)}
              autoComplete='off'
              label='Cijena na popust &euro;'
              type='number'
              value={productVariationData.discount_price || ''}
              onChange={(e) => { setProductVariationData({ ...productVariationData, discount_price: e.target.value }) }}
              size='small'
              name='discount_price'
              fullWidth
              variant='outlined'
            />
          </Grid>

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                closeOnSelect
                disablePast
                showToolbar={false}
                inputFormat="DD/MM/YYYY"
                open={openDate}
                onChange={(e) => {
                  setProductVariationData({
                    ...productVariationData,
                    discount_date: moment(e._d).format('DD/MM/YYYY')
                  })
                }}
                onClose={() => { setOpenDate(false); }}
                renderInput={(params) => <TextField style={{ display: 'none' }} />}
              />
            </LocalizationProvider>
            <TextField
              disabled={!Boolean(productVariationData.discount)}
              onClick={() => { if (Boolean(productVariationData.discount)) { setOpenDate(true); } }}
              value={productVariationData.discount_date || ''}
              label="Datum popusta"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DateIcon size='22' />
                  </InputAdornment>
                )
              }}
              // {...params}
              fullWidth
              size='small' />
          </Grid>

          <Grid item xs={6}>
            <TextField
              autoComplete='off'
              label='Barkod'
              type='text'
              value={productVariationData.barcode || ''}
              onChange={(e) => {
                setProductVariationData({
                  ...productVariationData,
                  barcode: e.target.value
                })
              }}
              size='small'
              name='barcode'
              fullWidth
              variant='outlined'
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              autoComplete='off'
              label='Dostupnost'
              type='number'
              value={productVariationData.qty || ''}
              onChange={(e) => {
                setProductVariationData({
                  ...productVariationData,
                  qty: e.target.value
                })
              }}
              size='small'
              name='qty_available'
              fullWidth
              variant='outlined'
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='subtitle2'>
              Slike varijacije proizvoda:
            </Typography>
            <Button
              onClick={() => { inputRef.current.click(); }}
              variant='contained'
              disableElevation
              style={{ backgroundColor: '#26A65B', marginTop: '5px' }}
            >
              <AddIcon />
              <input
                ref={inputRef}
                id='dugme'
                type="file"
                onChange={handleImageChange}
                multiple
                accept='image/*'
                hidden
              />
            </Button>
            <div className={classes.productGallery}>
              {previewPhotoGallery.map((image, index) => (
                <div
                  key={index}
                  className={classes.singleImage}>
                  <div
                    className={classes.image}
                    style={{ backgroundImage: `url('${image.id ? process.env.REACT_APP_UPLOADS_PATH + image.path : image.path}')` }}>
                    <div
                      onTouchStartCapture={(e) => {
                        handleImageRemoval(image.id, index)
                      }}
                      className={classes.deleteImage}>
                      <DeleteIcon style={{ color: '#DC3023', fontSize: '20px' }} />
                    </div>
                  </div>
                </div>
              ))}

            </div>

          </Grid>

          <Grid item xs={12} textAlign='center'>
            {
              category
                ?
                <Typography variant='body1'>
                  Specifikacije
                </Typography>
                :
                <Typography variant='body1'>
                  Izaberite kategoriju da biste dobili specifikacije
                </Typography>
            }
          </Grid>

          {
            specs
              ?
              productVariationData?.variationSpecs.map((sps, index) => {
                return (
                  sps.type === 'select'
                    ?
                    <Grid item xs={12} key={index}>
                      <FormControl fullWidth size='small'>
                        <InputLabel
                          id="demo-simple-select-label">{sps.specification_name}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          value={sps.specification_option || ''}
                          label={sps.specification_name}
                          name={sps.specification_name}
                          onChange={(e) => {

                            const upSpecs = [...productVariationData.variationSpecs];
                            const index = upSpecs.findIndex(spec => spec.specification_name === sps.specification_name);

                            const newObj = { ...upSpecs[index] };
                            newObj.specification_option = e.target.value;


                            upSpecs[index] = newObj;
                            setProductVariationData({ ...productVariationData, variationSpecs: upSpecs });

                          }}
                        >
                          {
                            getSpecOptions(sps.specification_name)
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    :
                    <Grid item xs={12} key={index}>
                      <TextField
                        helperText={getInputData(sps.specification_name).helperText}
                        autoComplete='off'
                        type={getTextFieldType(getInputData(sps.specification_name).allowed_characters)}
                        value={sps.value || ''}
                        label={sps.specification_name}
                        onChange={(e) => {
                          const upSpecs = [...productVariationData.variationSpecs];
                          const index = upSpecs.findIndex(spec => spec.specification_name === sps.specification_name);

                          const newObj = { ...upSpecs[index] };
                          newObj.value = e.target.value;


                          upSpecs[index] = newObj;
                          setProductVariationData({ ...productVariationData, variationSpecs: upSpecs });
                        }}
                        size='small'
                        fullWidth
                        variant='outlined'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography>{getInputData(sps.specification_name).measure}</Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                )
              })
              :
              null

          }

        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateProductVariation;