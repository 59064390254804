import React from 'react';
import useStyles from './style';
import {
    Button, TextField,
    Grid, Alert, AlertTitle, LinearProgress,
    DialogContent, DialogActions,
    Dialog, DialogTitle, Typography
} from '@mui/material';
import { useDeleteNotificationMutation } from '../../../store/reduxApi';

const DeleteNotificationDialog = ({ open, setOpen, notificationId,ShowLoading, ShowError, ShowSuccess }) => {

    const classes = useStyles();

    const [deleteNotification, deleteNotificationResult] = useDeleteNotificationMutation();
    const { isLoading, isError, isSuccess, data: deleteNotificationData, reset } = deleteNotificationResult;

    const handleClose = () => {
        setOpen({
            open: false,
            notificationId: false
        });
        reset();
    };

    const handleDeleteNotification = () => {
        if (notificationId) deleteNotification({ id: notificationId });
    };

    const ShowDeleteState = () => {
        if (isLoading) return <ShowLoading />
        if (isError) return <ShowError errorText='Neuspješno brisanje obaviještenja' />
    };

    React.useEffect(()=>{ if(isSuccess) handleClose(); },[isSuccess]);

    return (
        <Dialog
            fullWidth
            onClose={handleClose}
            open={open}>
            <DialogTitle>
                Brisanje obaviještenja
            </DialogTitle>
            <DialogContent>

                <Typography>Da li ste sigurni da želite da obrišete obaviještenje ?</Typography>

            </DialogContent>

            <ShowDeleteState />

            <DialogActions style={{ padding: '10px' }}>
                <Button
                    variant='outlined'
                    disableElevation
                    onClick={handleClose}
                >
                    Odustani
                </Button>
                <Button
                    disabled={isLoading}
                    onClick={handleDeleteNotification}
                    style={{ backgroundColor: '#DC3023' }}
                    variant='contained'
                    disableElevation
                >
                    Obriši
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteNotificationDialog;