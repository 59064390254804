import React, { useContext } from 'react';
import { Button, Typography, Badge, IconButton } from '@mui/material';
import useStyles from './style';
import { 
        AiOutlineHome as HomeIcon, 
        AiOutlineHeart as FavoriteIcon, 
        AiOutlineShoppingCart as CartIcon,
        AiFillHome as HomeIconFill
} from 'react-icons/ai';
import { GoLocation as LocationIcon } from 'react-icons/go';
import { CgProfile as ProfileIcon } from 'react-icons/cg';
import { GlobalContext } from '../../App';

const bottomBarData = [
    {
        name: 'Početna',
        icon: <HomeIcon />
    },
    {
        name: 'Lokacije',
        icon: <LocationIcon />
    },
]

const BottomBar = ({ auth, navigate }) => {

    const classes = useStyles();

    const { currentPage, setCurrentPage, cart } = useContext(GlobalContext);

    return (
        <div className={classes.bottomBar}>
            <div className={classes.bar}>
                <div
                    onClick={() => { auth ? navigate('/userPage') : navigate('/login') }}
                    className={classes.menuToggleBar}
                    style={{ backgroundColor: auth ? '#ec1767' : '#95A5A6' }}>
                    <IconButton size='large'>
                        <ProfileIcon style={{ color: 'white' }} />
                    </IconButton>
                </div>
                <div className={classes.menuBar}>
                    <div className={classes.menuBarSection}>
                        <div
                            onClick={() => {
                                navigate('/');
                                localStorage.setItem('currentPage', JSON.stringify(0));
                                setCurrentPage(0);
                            }}
                            className={classes.bottomBarButton}>
                            <div style={{ fontSize: '20px' }}>
                                {
                                    currentPage === 0 
                                    ?
                                    <HomeIconFill color='#ec1767' />
                                    :
                                    <HomeIcon />
                                }
                            </div>
                            <div>
                                <Typography style={{ fontSize: '11px', marginTop: '-5px' }}>
                                    Početna
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.bottomBarButton}>
                            <div style={{ fontSize: '20px' }}>
                                <LocationIcon />
                            </div>
                            <div>
                                <Typography style={{ fontSize: '11px', marginTop: '-5px' }}>
                                    Lokacije
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className={classes.menuBarSection}>

                        <div
                            // onClick={() => { navigate('/cart'); }}
                            className={classes.bottomBarButton}>
                            <div style={{ fontSize: '20px' }}>
                                <FavoriteIcon />
                            </div>
                            <div>
                                <Typography style={{ fontSize: '11px', marginTop: '-5px' }}>
                                    Sačuvano
                                </Typography>
                            </div>
                        </div>

                        <div
                            onClick={() => {
                                navigate(`/cart`);
                                localStorage.setItem('currentPage', JSON.stringify(3));
                                setCurrentPage(3);
                            }}
                            className={classes.bottomBarButton}>
                            <div style={{ fontSize: '20px' }}>
                                {
                                    auth
                                        ?
                                        <Badge
                                            // onClick={()=>{
                                            //     navigate(`/products?id=${category.id}`);
                                            //     localStorage.setItem('currentPage', JSON.stringify(1));
                                            //     setCurrentPage(1); 
                                            // }}
                                            sx={{
                                                "& .MuiBadge-standard": {
                                                    backgroundColor: '#ec1767',
                                                    fontSize: '15px'
                                                }
                                            }}
                                            color='primary'
                                            variant='standard'
                                            badgeContent={cart.length}
                                        >
                                            <CartIcon />
                                        </Badge>
                                        :
                                        <CartIcon />
                                }
                            </div>
                            <div>
                                <Typography style={{ fontSize: '11px', marginTop: '-5px' }}>
                                    Korpa
                                </Typography>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default BottomBar;