import React from 'react';
import useStyles from './style';
import {
  Button, TextField, Grid, DialogContent, DialogActions,
  Dialog, DialogTitle, Typography, InputLabel, FormControl, Select, MenuItem, IconButton
} from '@mui/material';
import { useUpdateSpecificationMutation } from '../../../store/reduxApi';
import { FaPlus as AddIcon, FaTrashAlt as DeleteIcon } from 'react-icons/fa';

const specificationInitialData = {
  name: '',
  type: '',
  placeholder: '',
  measure: '',
  allowed_characters: '',
  selectOptions: []
};

const allowedCharactersSelect = [
  {
    name: 'Broj',
    value: 'Number'
  },
  {
    name: 'Prirodan broj',
    value: 'Natural number'
  },
  {
    name: 'Slova (a-z)',
    value: 'Letters'
  },
  {
    name: 'Sve navedeno',
    value: 'All'
  },
];

const UpdateSpecDialog = ({ open, setOpen, spec, ShowLoading, ShowError, ShowSuccess  }) => {

  const classes = useStyles();

  const [specificationData, setSpecificationData] = React.useState(specificationInitialData);

  const [currentSelectOption, setCurrentSelectOption] = React.useState('');

  const [selectOptionsToDelete, setSelectOptionsToDelete] = React.useState([]);

  const [typeSelection, setTypeSelection] = React.useState({
    isSelected: false,
    selectedType: false
  });

  const [updateSpecification, updatedSpecificationResult] = useUpdateSpecificationMutation();
  const { isLoading, isError, isSuccess, reset } = updatedSpecificationResult;

  React.useEffect(() => {
    if (spec) {
      setTypeSelection({
        isSelected: true,
        selectedType: spec.type
      });
      setSpecificationData({
        ...spec,
        allowed_characters: spec.allowed_characters,
        selectOptions: spec.selectOptions ? spec.selectOptions : []
      })
    }
  }, [spec]);

  const handleClose = () => {
    setOpen({
      open: false,
      spec: false
    });
    setSpecificationData(specificationInitialData);
    setTypeSelection({ isSelected: false, selectedType: false });
    reset();
  };

  const handleUpdateSpecification = (e) => {
    e.preventDefault();
    if (typeSelection.selectedType === 'input') {
      const { selectOptions, ...rest } = specificationData;
      updateSpecification(rest);
    } else if (typeSelection.selectedType === 'select') {
      updateSpecification({
        id : specificationData.id,
        name: specificationData.name,
        type: specificationData.type,
        newSelectOptions: specificationData.selectOptions.filter(option => option.id === null).map(option => option.title),
        selectOptionsToDelete
      });
    }
  };

  const handleChange = (e) => {
    setSpecificationData({
      ...specificationData,
      [e.target.name]: e.target.value
    });
  };

  const getStatusType = () => {
    if (isLoading) return <ShowLoading />
    if (isError) return <ShowError errorText='Neuspješno ažuriranje specifikacije pokušajte ponovo' />
    if (isSuccess) return <ShowSuccess successText='Specifikacija uspješno ažurirana'/>
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          margin: '0',
          width: 'calc(100% - 21px)'
        }
      }}
      fullWidth
      onClose={handleClose}
      open={open}>
      <DialogTitle>
        Ažuriraj specifikaciju
      </DialogTitle>
      <DialogContent className={classes.dialogContent} style={{ paddingTop: '15px' }}>
        <form onSubmit={handleUpdateSpecification} id="myform">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete='off'
                label='Naziv'
                type='text'
                value={specificationData.name || ''}
                name='name'
                onChange={handleChange}
                fullWidth
                variant='outlined'
                required
                size='small'
              />
            </Grid>
            <Grid item xs={12} >
              <Typography style={{ fontSize: '14px' }}>Izaberite tip specifikacije</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  setSpecificationData({ ...specificationData, type: 'input' });
                  setTypeSelection({ selectedType: 'input' })
                }}
                style={{ backgroundColor: typeSelection.selectedType === 'input' ? '#26A65B' : '#ABB7B7' }}
                disableElevation
                required
                fullWidth
                variant='contained'>
                Input
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  setSpecificationData({ ...specificationData, type: 'select' });
                  setTypeSelection({ selectedType: 'select' })
                }}
                style={{ backgroundColor: typeSelection.selectedType === 'select' ? '#26A65B' : '#ABB7B7' }}
                disableElevation
                fullWidth
                variant='contained'>
                Select
              </Button>
            </Grid>

            {
              typeSelection.selectedType === 'input'
                ?
                <>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete='off'
                      label='Placeholder'
                      type='text'
                      value={specificationData.placeholder || ''}
                      name='placeholder'
                      onChange={handleChange}
                      fullWidth
                      variant='outlined'
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete='off'
                      label='Mjera npr. kg, GB, cm...'
                      type='text'
                      value={specificationData.measure || ''}
                      name='measure'
                      onChange={handleChange}
                      fullWidth
                      variant='outlined'
                      size='small'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth size='small'>
                      <InputLabel id="demo-simple-select-label">Dozvoljeni karakteri</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        value={specificationData.allowed_characters || ''}
                        label="Dozvoljeni karakteri"
                        onChange={(e) => {
                          setSpecificationData({ ...specificationData, allowed_characters: e.target.value })
                        }}
                      >
                        {
                          allowedCharactersSelect.map((allowedType, index) => {
                            return <MenuItem key={index} value={allowedType.value}>{allowedType.name}</MenuItem>
                          })
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                </>
                :
                <>
                  <Grid item xs={12} >
                    <Typography style={{ fontSize: '14px' }}>Unesite opcije:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      value={currentSelectOption}
                      onChange={(e) => { setCurrentSelectOption(e.target.value); }}
                      autoComplete='off'
                      type='text'
                      size='small'
                      fullWidth
                      variant='outlined'
                      placeholder='Opcija'

                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      onClick={() => {
                        if (!currentSelectOption.length) {
                          alert('Unesite opciju')
                        } else {
                          setSpecificationData({
                            ...specificationData,
                            selectOptions: [...specificationData.selectOptions, { id: null, title: currentSelectOption }]
                          });
                          setCurrentSelectOption('');
                        }
                      }}
                      style={{ border: '1px solid #26A65B', height: '100%', backgroundColor: 'white', color: "#26A65B" }}
                      variant='contained'
                      fullWidth
                      disableElevation
                    >
                      <AddIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={12} display='grid' gap='10px'>
                    {
                      specificationData.selectOptions.map((option, index) => {
                        return (
                          <div key={index} className={classes.singleOption} draggable>
                            <Typography>{option.title}</Typography>
                            <IconButton onClick={() => {
                              const newArr = specificationData.selectOptions.filter((el, elIndex) => elIndex !== index);
                              setSpecificationData({ ...specificationData, selectOptions: [...newArr] });
                              if (option.id) {
                                setSelectOptionsToDelete([...selectOptionsToDelete, option.id]);
                              }
                            }}>
                              <DeleteIcon color='#DC3023' fontSize='20' />
                            </IconButton>
                          </div>
                        )
                      })
                    }
                    {
                      specificationData.selectOptions.length === 0
                        ?
                        <Typography variant='subtitle2'>Nema opcija</Typography>
                        :
                        null
                    }
                  </Grid>
                </>
            }

          </Grid>
        </form>
      </DialogContent>

      {getStatusType()}

      <DialogActions>
        <Button
          variant='outlined'
          disableElevation
          onClick={handleClose}
        >
          Odustani
        </Button>
        <Button
          disabled={isLoading}
          form='myform'
          type='submit'
          style={{ backgroundColor: '#26A65B' }}
          variant='contained'
          disableElevation
        >
          Potvrdi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateSpecDialog;