import { DialogContent, DialogTitle, Dialog, TextField, Typography, Button, Grid, InputAdornment } from '@mui/material'
import React from 'react'
import { useGetGlobalDiscountQuery, useSetGlobalDiscountMutation } from '../../../store/reduxApi';
import ShowLoading from '../../Alerts/ShowLoading';
import ShowSuccess from '../../Alerts/ShowSuccess';
import ShowError from '../../Alerts/ShowError';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MdDateRange as DateIcon } from 'react-icons/md';
import moment from 'moment';

const GlobalDiscountDialog = ({ open, setOpen, setCurrentAdminDisplay }) => {

    const [discountData, setDiscountData] = React.useState({
        discount : '',
        expiry_date : ''
    });

    const [openDate, setOpenDate] = React.useState(false);

    const handleClose = () => {
        setDiscountData({
            discount : '',
            expiry_date : ''
        });
        setCurrentAdminDisplay(0);
        setOpen(false);
        reset();
    };

    const { isLoading, isError, isSuccess, error, data = {} } = useGetGlobalDiscountQuery();

    const [setDiscount, setDiscountResult] = useSetGlobalDiscountMutation();
    const {
        isLoading: isLoadingSetDiscount,
        isSuccess: isSuccessSetDiscount,
        isError: isErrorSetDiscount,
        data: loginResultData,
        reset } = setDiscountResult;

    React.useEffect(() => {
        if (open && isSuccess) {
            if (data.global_discount) {
                setDiscountData({
                    discount : data.global_discount,
                    expiry_date : moment(data.expiry_date).format('YYYY-MM-DD HH:mm:ss')
                })
            }
        }
    }, [open]);

    const GetAlert = () => {
        if (isLoadingSetDiscount) return <ShowLoading />
        if (isErrorSetDiscount) return <ShowError errorText={'Neuspješno azuriranje popusta'} />
        if (isSuccessSetDiscount) return <ShowSuccess successText='Uspješno ažuriranje popusta' />
    };


    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Globalni popust</DialogTitle>
            <DialogContent>

                <div style={{ margin: '10px' }}>
                    <GetAlert />
                </div>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete='off'
                            placeholder='Unesite popust'
                            type='number'
                            value={discountData.discount}
                            name='emailOrPhone'
                            onChange={(e) => { setDiscountData({...discountData, discount : e.target.value}); }}
                            size='small'
                            fullWidth
                            variant='outlined'
                            helperText='Ako zelite ukloniti popust unesite 0'
                        />

                    </Grid>

                    <Grid item xs={12}>

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MobileDateTimePicker
                                //ampm={false}
                                closeOnSelect
                                disablePast
                                showToolbar={false}
                                inputFormat="DD/MM/YYYY"
                                //minutesStep={5}
                                open={openDate}                  
                                onChange={(e) => {
                                    setDiscountData({
                                        ...discountData,
                                        expiry_date: moment(e._d).format('YYYY-MM-DD HH:mm:ss')
                                    })
                                }}
                                onClose={() => { setOpenDate(false); }}
                                renderInput={(params) => <TextField style={{ display: 'none' }} />}
                            />
                        </LocalizationProvider>
                        <TextField
                            // error={Boolean(errors.discount_date)}
                            // helperText={errors.discount_date ?? errors.discount_date}
                            // disabled={!Boolean(simpleProductData.discount)}
                            onClick={() => { setOpenDate(true); }}
                            value={discountData.expiry_date}
                            label="Datum vazenja"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <DateIcon size='22' />
                                    </InputAdornment>
                                )
                            }}
                            // {...params}
                            fullWidth
                            helperText='Datum i vrijeme do kad vazi popust'
                            size='small' 
                            />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            onClick={() => {
                                if( discountData.discount === '' || discountData.expiry_date === '' ){
                                    alert('Izaberite popust i datum vazenja.');
                                }else {
                                    setDiscount(discountData);
                                }
                            }}
                            style={{ backgroundColor: '#ec1767' }}
                            type='submit'
                            fullWidth
                            variant='contained'
                            disableElevation >
                            Potvrdi
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default GlobalDiscountDialog