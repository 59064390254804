import React from 'react';
import useStyles from './style';
import { useParams } from 'react-router-dom';
import { useGetProductQuery, useGetGlobalDiscountQuery } from '../../store/reduxApi';
import { Grid, Button, FormHelperText } from '@mui/material';
import Slider from 'react-slick';
import ShowLoading from '../Alerts/ShowLoading';
import ShowError from '../Alerts/ShowError';
import { Divider, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { GlobalContext } from '../../App';
import {
  BsCartPlusFill as AddToCartIcon,
  BsCartXFill as RemoveFromCartIcon,
  BsChevronLeft as LeftArrow,
  BsChevronRight as RightArrow
} from 'react-icons/bs';
//import { HiChevronLeft as LeftArrow, HiChevronRight as RightArrow } from 'react-icons/hi';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


const initialVariationData = {
  id: '',
  discount: '',
  discount_date: '',
  discount_price: '',
  barcode: '',
  qty: '',
};

const ShowProduct = ({ auth }) => {

  const classes = useStyles();

  const { id } = useParams();

  const { cart, setCart } = React.useContext(GlobalContext);

  const [currentSliderPhoto, setCurrentSliderPhoto] = React.useState(0);

  const [showLightBox, setShowLightBox] = React.useState(false);

  const { data: globalDiscountData = {}, isSuccess: isSuccessGlobalDiscount } = useGetGlobalDiscountQuery();

  const { isLoading, isError, isSuccess, error, data = {} } = useGetProductQuery({ id });

  const [selectedSpecifications, setSelectedSpecifications] = React.useState({});

  const [variationPhotoForCart, setVariationPhotoForCart] = React.useState(null);

  const [availableSpecs, setAvailableSpecs] = React.useState({});

  const [checkAddToCart, setCheckAddToCart] = React.useState(true);

  const [galleryToShow, setGalleryToShow] = React.useState([]);

  const [isVariationGallery, setIsVariationGallery] = React.useState(false);

  const [variationData, setVariationData] = React.useState(initialVariationData);

  const [areAllVarSpecsFilled, setAreAllVarSpecsFilled] = React.useState(false);

  const checkCart = () => {
    if (isSuccess) {
      const idToFind = data.product.has_lager ? areAllVarSpecsFilled ? variationData.id : -1 : data.product.simpleProduct.id;
      const index = cart.findIndex(cartItem => cartItem.id === idToFind);
      return index !== -1 ? true : false;
    }
  };

  const handleAddToCart = () => {
    if (isSuccess) {
      const product = data.product;
      if (data.product.has_lager && areAllVarSpecsFilled) {
        const newCartProduct = {
          id: variationData.id,
          photo: variationPhotoForCart || product.gallery[0],
          discount: variationData.discount,
          name: product.name,
          regular_price: product.regular_price,
          discount_price: variationData.discount_price,
          specs: product.variations.find(variation => variation.id === variationData.id).variationSpecs.map(spec => spec.measure ? spec.value + ' ' + spec.measure : spec.value).join(' '),
          qty: 1,
          limit_per_customer: product.limit_per_customer,
          productId: product.id
        };
        const updatedCart = [...cart, newCartProduct];
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        setCart([...updatedCart]);
      } else {
        const newCartProduct = {
          id: product.simpleProduct.id,
          photo: product.gallery[0],
          discount: product.simpleProduct.discount,
          name: product.name,
          regular_price: product.regular_price,
          discount_price: product.simpleProduct.discount_price,
          specs: product.simpleProduct.simpleProductSpecs.map(spec => spec.measure ? spec.value + ' ' + spec.measure : spec.value).join(' '),
          qty: 1,
          limit_per_customer: product.limit_per_customer,
          productId: product.id
        };
        const updatedCart = [...cart, newCartProduct];
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        setCart([...updatedCart]);
      }
    }
  };

  const handleRemoveFromCart = () => {
    if (isSuccess) {
      const updatedCart = [...cart];
      const idToRemove = !data.product.has_lager ? data.product.simpleProduct.id : variationData.id;
      const index = updatedCart.findIndex(cartItem => cartItem.id === idToRemove);
      updatedCart.splice(index, 1);
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      setCart([...updatedCart]);
    }
  };

  React.useEffect(() => {
    if (isSuccess) {
      setGalleryToShow([...data.product.gallery]);
      const specs = data.product.specifications;
      if (specs.length) {
        setSelectedSpecifications((prevSpecs) => {
          const newSpecs = {};
          specs.forEach((value) => {
            newSpecs[value] = '';
          });
          return { ...prevSpecs, ...newSpecs };
        });
        setAvailableSpecs((prevAvailableSpecs) => {
          const newSpecs = {};
          specs.forEach((value) => {
            newSpecs[value] = [];
          });
          return { ...prevAvailableSpecs, ...newSpecs };
        });
      }
      if (!data.product.has_lager) {
        if (data.product.simpleProduct.qty > 0) {
          setCheckAddToCart(false);
        }
        setAreAllVarSpecsFilled(true);
        const product = data.product.simpleProduct;
        const { variationGallery, variationSpecs, ...restData } = product;
        setVariationData({ ...restData });
      }
    }
  }, [isSuccess]);


  React.useEffect(() => {
    console.log({
      availableSpecs,
      selectedSpecifications
    });
  }, [availableSpecs, selectedSpecifications]);

  React.useEffect(() => {
    if (data?.product?.has_lager) {
      if (JSON.stringify(selectedSpecifications) !== '{}') {
        let flag = true;
        for (let property in selectedSpecifications) {
          if (selectedSpecifications[property] === '') {
            flag = false;
            break;
          }
        }
        setAreAllVarSpecsFilled(flag);
      }
      if (areAllVarSpecsFilled && data.product.has_lager) {
        const variations = data.product.variations;
        const variation = variations.find(variation => {
          let flag = true;
          for (let prop in selectedSpecifications) {
            const specObject = variation.variationSpecs.find(so => so.specification_name === prop);
            if (specObject.type === 'input') continue;
            if (specObject.value !== selectedSpecifications[prop]) {
              flag = false;
              break;
            }
          }
          return flag;
        });
        if (variation) {
          const { variationGallery, variationSpecs, ...restData } = variation;
          if (restData.qty > 0) setCheckAddToCart(false);
          setVariationData({ ...restData });
        } else {
          setVariationData(initialVariationData);
        }
      }
    }
  }, [selectedSpecifications, areAllVarSpecsFilled]);

  React.useEffect(() => {
    if (isSuccess && (JSON.stringify(selectedSpecifications) !== '{}')) {
      const checkSelected = Object.values(selectedSpecifications).findIndex(selected => selected !== '');
      if (checkSelected !== -1) {
        const variations = data.product.variations;
        const newAvailableSpecs = availableSpecs;
        for (const prop in newAvailableSpecs) {
          newAvailableSpecs[prop] = [];
        }

        const filtered = variations.filter(variation => {
          return Object.entries(selectedSpecifications).every(([specKey, specValue]) => {
            if (specValue === "") return true;
            return variation.variationSpecs.some(spec => {
              return spec.specification_name === specKey && spec.value === specValue;
            });
          });
        });

        for (const prop in selectedSpecifications) {
          if (selectedSpecifications[prop] === '') {

            filtered.forEach( variation => {
              const value = variation.variationSpecs.find(varSpec => varSpec.specification_name === prop).value;
              newAvailableSpecs[prop] = [...newAvailableSpecs[prop], value];
            });

          }else {
            newAvailableSpecs[prop] = [selectedSpecifications[prop]];
          }
        }

        for (const prop in newAvailableSpecs) {
          newAvailableSpecs[prop] = [...new Set(newAvailableSpecs[prop])];
        }

        setAvailableSpecs({ ...newAvailableSpecs });

        console.log(newAvailableSpecs);

      }
    }
  }, [isSuccess, selectedSpecifications]);

  const checkSelected = () => {
    return Object.entries(selectedSpecifications).findIndex(([specKey, specValue])=> specValue !== '' ) !== -1
  };

  const checkDisabled = (spec, item) => {
    if (JSON.stringify(availableSpecs) !== '{}') {
      return availableSpecs[spec].length ? !Boolean(availableSpecs[spec].find(value => value === item)) : false;
    }
  };

  const getMenuItems = (spec) => {
    const items = [];
    data?.product?.variations?.forEach(variation => {
      const specIndex = variation.variationSpecs.findIndex((varSpec) => varSpec.specification_name === spec);
      items.push(variation.variationSpecs[specIndex].value);
    });
    const uniqueItems = Array.from(new Set(items));
    return (
      uniqueItems.map((item, index) => {
        return <MenuItem
          key={index}
          value={item}
          disabled={checkDisabled(spec, item)}
        >
          {item}
        </MenuItem>
      })
    )
  };

  const changeGallery = (color) => {
    if (isSuccess) {
      const { variations } = data.product;
      if (variations.length) {
        const variationGallery = variations.filter(variation =>
          variation.variationSpecs.findIndex(specification => specification.value === color) !== -1
        ).find(searchedVariation => searchedVariation.variationGallery.length)?.variationGallery;
        if (variationGallery?.length) {
          setVariationPhotoForCart(variationGallery[0]);
          setGalleryToShow([...variationGallery]);
          setIsVariationGallery(true);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    }
  };

  const resetVariation = () => {
    setAvailableSpecs((prevData) => {
      for (const prop in prevData) {
        prevData[prop] = [];
      }
      return prevData;
    });
    setSelectedSpecifications((prevData) => {
      for (const prop in prevData) {
        prevData[prop] = '';
      }
      return prevData;
    });
    setVariationData(initialVariationData);
    setAreAllVarSpecsFilled(false);
    setIsVariationGallery(false);
    setGalleryToShow([...data?.product?.gallery]);
    setCheckAddToCart(true)
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={classes.prevArrow}
        onClick={onClick}
        style={{ ...style }}
      >
        <LeftArrow fontSize='35' opacity='0.5' />
      </div>
    )
  };

  const NextArrow = (props) => (

    <div
      className={classes.nextArrow}
      onClick={props.onClick}
    >
      <RightArrow fontSize='35' opacity='0.5' />
    </div>
  );

  var settings = {
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    dots: true,
    dotsClass: `slick-dots ${classes.dots}`,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  };

  return (
    <>
      {
        isLoading ?
          <div className={classes.status}>
            <ShowLoading />
          </div>
          :
          null
      }
      {
        isError ?
          <div className={classes.status}>
            <ShowError />
          </div>
          :
          null
      }
      {
        isSuccess
          ?
          <div className={classes.productDiv}>

            <Lightbox
              controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
              index={currentSliderPhoto}
              styles={{ container: { background: 'rgba(0,0,0,0.5)' } }}
              open={showLightBox}
              close={() => { setShowLightBox(false); }}
              slides={galleryToShow.map(photo => { return { src: process.env.REACT_APP_UPLOADS_PATH + photo } })}
            // render={{
            //   iconPrev: () => <PrevArrow style={{ opacity : '100' }} />,
            //   iconNext: () => <NextArrow />,
            // }}
            />

            <div className={classes.sliderDiv}>
              <Slider
                {...settings}
                className={classes.slider}
                afterChange={(e) => { setCurrentSliderPhoto(e); }} >
                {
                  galleryToShow.map((photo, index) => {
                    return (
                      <div key={index} onClick={() => { setShowLightBox(true); }}>
                        <div
                          className={classes.photo}
                          style={{ backgroundImage: `url('${process.env.REACT_APP_UPLOADS_PATH + photo}')` }}>

                        </div>
                      </div>
                    )
                  })
                }
              </Slider>
              <div
                style={{
                  display: isSuccessGlobalDiscount && globalDiscountData.global_discount ? 'block' : 'none',
                  position: 'absolute',
                  top: '10px',
                }}
                className={classes.globalDiscountDiv}>
                <Typography style={{ fontSize: '14px' }}>
                  {
                    data?.product?.has_lager
                      ?
                      `Popust na sve varijacije ${parseInt(globalDiscountData.global_discount)}% !`
                      :
                      `Popust na proizvod ${parseInt(globalDiscountData.global_discount)}% !`
                  }
                </Typography>
              </div>
              <div
                style={{
                  display: isVariationGallery ? 'block' : 'none',
                  position: 'absolute',
                  top: isSuccessGlobalDiscount && globalDiscountData.global_discount ? '50px' : '10px',
                }}
                className={classes.resetGallery}>
                <Button
                  variant='contained'
                  size='small'
                  disableElevation
                  style={{ backgroundColor: 'black', color: 'white', textTransform: 'none' }}
                  onClick={() => {
                    setIsVariationGallery(false);
                    if (isSuccess) setGalleryToShow([...data.product.gallery]);
                  }}
                >
                  Prikaži glavnu galeriju
                </Button>
              </div>
            </div>
            <div className={classes.info}>
              <div className={classes.title}>
                <Typography style={{ fontSize: '18px' }} >
                  {data?.product?.name}
                </Typography>
                {/* <Divider></Divider> */}
              </div>
              {
                areAllVarSpecsFilled
                  ?
                  <>
                    <div className={classes.prices}>
                      {
                        variationData.discount
                          ?
                          <>
                            <div className={classes.infoRow}>
                              <Typography style={{ fontSize: '12.5px', color: '#757D75' }}>
                                Redovna cijena: {data?.product?.regular_price}&euro;
                              </Typography>
                              <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#DC3023' }}>
                                AKCIJA -{Math.floor(variationData.discount)}%
                              </Typography>
                            </div>
                            <div className={classes.infoRow}>
                              <div className={classes.infoRow}>
                                <Typography style={{ fontSize: '12.5px' }}>
                                  Cijena na popustu:&nbsp;
                                </Typography>
                                <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#DC3023' }}>
                                  {variationData.discount_price}&euro;
                                </Typography>
                              </div>
                              <Typography style={{ fontSize: '12.5px' }}>
                                Akcija važi do {variationData.discount_date}
                              </Typography>
                            </div>
                          </>
                          :
                          <Typography style={{ fontSize: '12.5px', color: '#757D75' }}>
                            Cijena varijacije: {data?.product?.regular_price}&euro;
                          </Typography>

                      }
                    </div>
                    <div className={classes.description}>
                      <Typography style={{ fontSize: '13px' }}>
                        OPIS ARTIKLA
                      </Typography>
                      <Divider style={{ marginBottom: '15px' }}></Divider>
                      <div className={classes.infoRow}>
                        <Typography style={{ fontSize: '12.5px', color: '#757D75' }}>
                          {data?.product?.parentCategoryName} 8171-46 123
                        </Typography>
                        <Typography style={{ fontSize: '12.5px', color: '#757D75' }}>
                          DOSTUPNO: {variationData.qty}
                        </Typography>
                      </div>
                      <div className={classes.infoRow}>
                        <Typography style={{ fontSize: '12.5px', color: '#757D75' }}>
                          {data?.product?.categoryName}
                        </Typography>
                        <Typography style={{ fontSize: '12.5px', color: '#757D75' }}>
                          MAKSIMALNO PO KORISNIKU
                        </Typography>
                      </div>
                      <div className={classes.infoRow}>
                        <Typography style={{ fontSize: '12.5px', color: '#757D75' }}>
                          Barkod: {variationData.barcode}
                        </Typography>
                        <Typography style={{ fontSize: '12.5px', color: '#757D75' }}>
                          {data?.product?.limit_per_customer} komada
                        </Typography>
                      </div>
                    </div>
                  </>
                  :
                  <div className={classes.notSelectedVarInfo}>
                    <Typography style={{ fontSize: '13px', marginBottom: '10px' }}>
                      OSNOVNI PODACI
                    </Typography>
                    <Divider style={{ marginBottom: '15px' }}></Divider>
                    <Typography style={{ fontSize: '13x', color: '#757D75' }}>
                      Redovna cijena: {data?.product?.regular_price}&euro;
                    </Typography>
                    <Typography style={{ fontSize: '13px', color: '#757D75' }}>
                      Maksimalno po korisniku {data?.product?.limit_per_customer} komada
                    </Typography>
                    <div style={{ marginLeft: '10px', margin: '7px' }}>
                      <Typography style={{ fontSize: '12.5px', color: '#757D75' }}>
                        {data?.product?.parentCategoryName} 8171-46 123
                      </Typography>
                      <Typography style={{ fontSize: '12.5px', color: '#757D75' }}>
                        {data?.product?.categoryName}
                      </Typography>
                    </div>
                    <Typography style={{ fontSize: '12.5px', textAlign: 'center' }}>
                      <strong>&#10058;</strong> Za dodatne informacije o popustu i cijenama na popust odaberite varijaciju proizvoda
                    </Typography>
                  </div>
              }
              <div className={classes.variations} style={{ marginTop: '15px' }}>
                {
                  data?.product?.has_lager
                    ?
                    <div className={classes.variationTitleDiv}>
                      <Typography style={{ fontSize: '13px' }}>
                        VARIJACIJE
                      </Typography>
                      {
                        checkSelected()
                          ?
                          <Button
                            variant='contained'
                            size='small'
                            disableElevation
                            style={{
                              backgroundColor: '#BDC3C7',
                              color: 'black',
                              textTransform: 'none'
                            }}
                            onClick={resetVariation}
                          >
                            Obriši
                          </Button>
                          :
                          null
                      }
                    </div>
                    :
                    <Typography style={{ fontSize: '13px' }}>
                      SPECIFIKACIJE
                    </Typography>
                }
                <Divider style={{ marginBottom: '15px' }}></Divider>

                <div className={classes.specButtons}>

                  <Grid container spacing={2} display='flex' justifyContent='center' alignItems='center'>

                    {
                      data?.product?.has_lager
                        ?
                        data?.product?.specifications?.map((spec, index) => {
                          return (

                            [<Grid item xs={4} textAlign='center' key={index}>
                              <Typography variant='subtitle2'>
                                {spec}
                              </Typography>
                            </Grid>,
                            <Grid item xs={8} key={index + 1}>
                              <FormControl fullWidth size='small'>
                                <InputLabel
                                  //style={{ color: '#ec1767' }}
                                  id="demo-simple-select-label">Izaberi</InputLabel>
                                <Select
                                  sx={{
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                      borderColor: '#f86673',
                                    },
                                  }}
                                  className={classes.select}
                                  variant='outlined'
                                  labelId="demo-simple-select-label"
                                  value={selectedSpecifications[spec] || ''}
                                  label="Kategorija"
                                  onChange={(e) => {
                                    if (spec === 'Boja') changeGallery(e.target.value);
                                    setSelectedSpecifications({
                                      ...selectedSpecifications,
                                      [spec]: e.target.value
                                    })
                                  }}
                                >
                                  {getMenuItems(spec)}
                                </Select>

                              </FormControl>
                            </Grid>
                            ]
                          )
                        })
                        :
                        data?.product?.simpleProduct.simpleProductSpecs.map((spec, index) => {
                          return (
                            [<Grid item xs={6} textAlign='center' key={index}>
                              <Typography variant='subtitle2'>
                                {spec.specification_name}
                              </Typography>
                            </Grid>,
                            <Grid item xs={6} textAlign='center' key={index}>
                              <Typography variant='subtitle2'>
                                {spec.value} {spec.type === 'input' ? spec.measure : null}
                              </Typography>
                            </Grid>
                            ]
                          )
                        })
                    }

                    <Grid
                      item
                      xs={12}
                      marginTop={2}
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      {
                        !auth
                          ?
                          <Button disabled variant='contained'>
                            Da biste dodali proizvod u korpu molimo vas da se prijavite
                          </Button>
                          :
                          !checkCart()
                            ?
                            <Button
                              disabled={checkAddToCart}
                              sx={{
                                "&.MuiButton-contained": {
                                  "&.Mui-disabled": {
                                    background: "#D2D7D3",
                                    color: 'white'
                                  },
                                  backgroundColor: '#ec1767',
                                  width: '182px'
                                }
                              }}
                              onClick={handleAddToCart}
                              //  style={{ color: 'white', backgroundColor: '#ec1767' }}
                              className={classes.addToCartButton}
                              disableElevation
                              variant='contained'>
                              Dodaj u korpu
                              <AddToCartIcon
                                fontSize={25}
                                style={{ marginLeft: '8px', color: 'white' }} />

                            </Button>
                            :
                            <Button
                              onClick={handleRemoveFromCart}
                              style={{ color: 'black', backgroundColor: '#D2D7D3', width: '182px' }}
                              className={classes.addToCartButton}
                              disableElevation
                              variant='contained'>
                              Ukloni iz korpe
                              <RemoveFromCartIcon
                                fontSize={25} style={{ marginLeft: '8px', color: '#ec1767' }} />
                            </Button>
                      }
                    </Grid>

                  </Grid>

                </div>

              </div>
            </div>
          </div>
          :
          null
      }
    </>
  );
};

export default ShowProduct;