import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({

    productCard: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        //height: '135px',
        width: 'calc(33vw - 10px)',
        //height: 'calc(23vh - 3px)',
        height : '170px',
        boxShadow: '0px 0.458px 0.645px -0.292px rgba(0,0,0,0.2), 3.5px 3.5px 1.583px 0.125px rgba(0,0,0,0.18), 0px 0.375px 1.916px 0.333px rgba(0,0,0,0.12)',
        borderCollapse: 'separate',
        overflow: 'hidden',
        borderRadius: '4px',
        marginBottom: '10px'
    },
    productImg: {
        //border : '1px solid green',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover', // ili 100%
        backgroundPosition: 'center center',
        backgroundColor: 'white',
        height : '70%',
    },
    productText: {
       // border : '1px solid red',
        backgroundColor: '#fdf4ed',
        padding: '3px',
        height: '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    productPrices: {
        width: '100%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '10px'
    },
    adminActions: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '15px',
        opacity: '0',
        visibility: 'hidden',
        transition: 'opacity 0.5s ease-in-out, visibility 0.5s ease-in-out'
    },
    button: {
        '&.MuiButton-root': {
            minWidth: '55px',
            padding: '8px 10px'
        }
    }
}));

export default useStyles;