import React from 'react';
import useStyles from './style';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BiCircle as Circle, BiRadioCircleMarked as CircleActive } from 'react-icons/bi';
import { Button, Typography, LinearProgress, Alert, AlertTitle } from '@mui/material';
import { useGetSlidesQuery } from '../../store/reduxApi';
import { useNavigate } from 'react-router-dom';
import ShowLoading from '../Alerts/ShowLoading';
import ShowError from '../Alerts/ShowError';

const BigSlider = () => {

  const classes = useStyles();
  const navigate = useNavigate();

  const [currentSliderPhoto, setCurrentSliderPhoto] = React.useState(0);

  const { isLoading, isError, data = {} } = useGetSlidesQuery();

  var settings = {
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000
  };

  return (
    <div style={{ position: 'relative' }}>
      {isLoading ? <ShowLoading /> : null}
      {isError ? <ShowError errorText='Neuspješno prikazivanje slajdera' /> : null}
      <Slider {...settings} className={classes.bigSlider} afterChange={(e) => { setCurrentSliderPhoto(e); }} >
        {
          data?.allSlides?.map((slide, index) => {
            return (
              <div key={index}>
                <div
                  // style={{ backgroundImage: `url(${slide.url})` }}
                  className={classes.singleSlide}>
                  <img src={process.env.REACT_APP_UPLOADS_PATH + slide.photo} style={{ width: '100%', height: '100%', borderRadius: '8px', }} />
                  {
                    slide.link
                      ?
                      <div className={classes.sliderButton}>
                        <Button
                          onClick={() => { window.open(slide.link, '_blank', 'noreferrer') }}
                          style={{ backgroundColor: 'hsla(0, 0%, 0%, 0.5)', textTransform: 'none' }}
                          variant='contained'
                          size='small'
                          disableElevation>
                          Saznaj više
                        </Button>
                      </div>
                      :
                      null
                  }
                </div>
              </div>
            )
          })
        }
      </Slider>
      <div className={classes.sliderCircles}>
        {
          data?.allSlides?.map((_, index) => {
            return (
              index === currentSliderPhoto
                ?
                <CircleActive key={index} className={classes.circle} />
                :
                <Circle key={index} className={classes.circle} />
            )
          })
        }
      </div>
    </div>
  )
}

export default BigSlider