import React from 'react';
import {
  Toolbar, AppBar, Button, Slide, Typography,
  Dialog, DialogContent, Grid, TextField,
  FormControl, Select, InputLabel, MenuItem, InputAdornment, FormHelperText, Divider
} from '@mui/material';
import { TfiClose as CloseIcon } from 'react-icons/tfi';
import useStyles from './style';
import { FaTrashAlt as DeleteIcon } from 'react-icons/fa';
import moment from 'moment';
import { FaPlus as AddIcon } from 'react-icons/fa';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { MdDateRange as DateIcon } from 'react-icons/md';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialProductVariationData = {
  price: '',
  discount: '',
  discount_price: '',
  discount_date: '13/04/2023',
  barcode: '1234567891234',
  qty: '12',
  variationGallery: [],
  variationSpecs: []
};

const AddProductVariation = ({
  open,
  setOpen,
  category,
  productPrice,
  specs,
  productVariations,
  setProductVariations,
  getTextFieldType,
  edit,
  setEdit
}) => {

  const classes = useStyles();

  const [productVariationData, setProductVariationData] = React.useState(initialProductVariationData);

  const [openDate, setOpenDate] = React.useState(false);

  const [previewPhotoGallery, setPreviewPhotoGallery] = React.useState([]);

  const inputRef = React.useRef();

  const handleImageChange = (e) => {
    if (e.target.files) {
      setProductVariationData((prevData) => {
        return {
          ...prevData,
          variationGallery: prevData.variationGallery.concat([...e.target.files])
        }
      });
      const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
      setPreviewPhotoGallery((prevImageGallery) => prevImageGallery.concat(filesArray));
    }
  };

  const handleImageRemoval = (index) => {
    setProductVariationData((prevData) => {
      return {
        ...prevData,
        variationGallery: prevData.variationGallery.filter((photo, photoIndex) => photoIndex !== index)
      }
    });
    setPreviewPhotoGallery((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
  };

  const handleClose = () => {
    setOpen(false);
    setProductVariationData(initialProductVariationData);
    setPreviewPhotoGallery([]);
    setEdit({
      variation: false,
      index: false
    });
  };

  React.useEffect(()=>{
      setProductVariationData({...productVariationData, price : productPrice});
  },[open]);

  React.useEffect(() => {
    if (edit.variation) {
      setProductVariationData({ ...edit.variation });
      const previewGallery = edit.variation.variationGallery.map((file) => URL.createObjectURL(file));
      setPreviewPhotoGallery([...previewGallery]);
    }
  }, [edit.variation]);

  React.useEffect(() => {
    if (productVariationData.discount.length && productVariationData.price.length) {
      const newPrice = productVariationData.price - productVariationData.discount / 100 * productVariationData.price;
      setProductVariationData({
        ...productVariationData,
        discount_price: newPrice.toFixed(2)
      })
    }
    if (!productVariationData.discount.length) {
      setProductVariationData({
        ...productVariationData,
        discount_price: ''
      })
    }
    // if (productPrice.length) {
    //   setProductVariationData({
    //     ...productVariationData,
    //     price: productPrice
    //   })
    // }
  }, [productVariationData.discount]);

  const handleAddProductVariation = () => {
    if (edit.variation) {
      setProductVariations((prevArray) => {
        prevArray[edit.index] = { ...productVariationData }
        return prevArray;
      })
    } else {
      setProductVariations([...productVariations, productVariationData]);
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: '#d92130'
        }}>
        <Toolbar sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'white'
        }}>

          <CloseIcon
            fontSize='22'
            onClick={handleClose}
            color='#ffffff'
            style={{ color: 'white' }} />

          <Button
            onClick={handleAddProductVariation}
            variant='outlined'
            disableElevation
            type='submit'
            style={{ backgroundColor: 'white', color: 'black', border: 'black' }}
          >
            {
              !edit.variation
                ?
                'Dodaj varijaciju'
                :
                'Azuriraj varijaciju'
            }
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent style={{ marginTop: '15px' }}>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <TextField
              autoComplete='off'
              label='Cijena varijacije &euro;'
              type='number'
              value={productVariationData.price}
              onChange={(e) => { setProductVariationData({ ...productVariationData, price: e.target.value }) }}
              size='small'
              name='price'
              fullWidth
              variant='outlined'
            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              autoComplete='off'
              label='Popust %'
              type='number'
              value={productVariationData.discount}
              onChange={(e) => { setProductVariationData({ ...productVariationData, discount: e.target.value }) }}
              size='small'
              fullWidth
              variant='outlined'
            />
          </Grid>

          <Grid item xs={7}>
            <TextField
              disabled={!Boolean(productVariationData.discount)}
              autoComplete='off'
              label='Cijena na popust &euro;'
              type='number'
              value={productVariationData.discount_price}
              onChange={(e) => { setProductVariationData({ ...productVariationData, discount_price: e.target.value }) }}
              size='small'
              name='discount_price'
              fullWidth
              variant='outlined'
            />
          </Grid>

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                closeOnSelect
                disablePast
                showToolbar={false}
                inputFormat="DD/MM/YYYY"
                open={openDate}
                onChange={(e) => {
                  setProductVariationData({
                    ...productVariationData,
                    discount_date: moment(e._d).format('DD/MM/YYYY')
                  })
                }}
                onClose={() => { setOpenDate(false); }}
                renderInput={(params) => <TextField style={{ display: 'none' }} />}
              />
            </LocalizationProvider>
            <TextField
              disabled={!Boolean(productVariationData.discount)}
              onClick={() => { if (Boolean(productVariationData.discount)) { setOpenDate(true); } }}
              value={productVariationData.discount_date}
              label="Datum popusta"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DateIcon size='22' />
                  </InputAdornment>
                )
              }}
              // {...params}
              fullWidth
              size='small' />
          </Grid>

          <Grid item xs={6}>
            <TextField
              autoComplete='off'
              label='Barkod'
              type='text'
              value={productVariationData.barcode}
              onChange={(e) => {
                setProductVariationData({
                  ...productVariationData,
                  barcode: e.target.value
                })
              }}
              size='small'
              name='barcode'
              fullWidth
              variant='outlined'
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              autoComplete='off'
              label='Dostupnost'
              type='number'
              value={productVariationData.qty}
              onChange={(e) => {
                setProductVariationData({
                  ...productVariationData,
                  qty: e.target.value
                })
              }}
              size='small'
              name='qty_available'
              fullWidth
              variant='outlined'
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='subtitle2'>
              Slike varijacije proizvoda:
            </Typography>
            <Button
              onClick={() => { inputRef.current.click(); }}
              variant='contained'
              disableElevation
              style={{ backgroundColor: '#26A65B', marginTop: '5px' }}
            >
              <AddIcon />
              <input
                ref={inputRef}
                id='dugme'
                type="file"
                onChange={handleImageChange}
                multiple
                accept='image/*'
                hidden
              />
            </Button>
            <div className={classes.productGallery}>
              {previewPhotoGallery.map((image, index) => (
                <div
                  key={index}
                  className={classes.singleImage}>
                  <div
                    className={classes.image}
                    style={{ backgroundImage: `url('${image}')` }}>
                    <div
                      onTouchStartCapture={(e) => {
                        handleImageRemoval(index)
                      }}
                      className={classes.deleteImage}>
                      <DeleteIcon style={{ color: '#DC3023', fontSize: '20px' }} />
                    </div>
                  </div>
                </div>
              ))}

            </div>

          </Grid>

          <Grid item xs={12} textAlign='center'>
            {
              category
                ?
                <Typography variant='body1'>
                  Specifikacije
                </Typography>
                :
                <Typography variant='body1'>
                  Izaberite kategoriju da biste dobili specifikacije
                </Typography>
            }
          </Grid>

          {
            category
              ?
              specs?.map((spec, index) => {
                return (
                  spec.type === 'select'
                    ?
                    <Grid item xs={12} key={index}>
                      <FormControl fullWidth size='small'>
                        <InputLabel
                          id="demo-simple-select-label">{spec.name}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          value={productVariationData.variationSpecs[spec.name] || ''}
                          label={spec.name}
                          name={spec.name}
                          onChange={(e) => {
                            const varSpecs = productVariationData.variationSpecs;
                            varSpecs[spec.name] = e.target.value;
                            setProductVariationData({
                              ...productVariationData,
                              variationSpecs: { ...varSpecs }
                            })
                          }}
                        >
                          {
                            spec.options.map((option, index) => {
                              return <MenuItem key={index} value={option.id}>{option.title}</MenuItem>
                            })
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    :
                    <Grid item xs={12} key={index}>
                      <TextField
                        helperText={spec.placeholder}
                        autoComplete='off'
                        type={getTextFieldType(spec.allowed_characters)}
                        value={productVariationData.variationSpecs[spec.name] || ''}
                        label={spec.name}
                        name={spec.name}
                        onChange={(e) => {
                          const varSpecs = productVariationData.variationSpecs;
                          varSpecs[spec.name] = e.target.value;
                          setProductVariationData({
                            ...productVariationData,
                            variationSpecs: { ...varSpecs }
                          })
                        }}
                        size='small'
                        fullWidth
                        variant='outlined'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography>{spec.measure}</Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                )
              })
              :
              null
          }

        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default AddProductVariation;