import React from 'react';
import useStyles from './style';
import { Button, LinearProgress, Typography, Alert, AlertTitle, TextField, InputAdornment } from '@mui/material';
import { FaPencilAlt as EditIcon, FaTrashAlt as DeleteIcon, FaPlus as AddIcon } from 'react-icons/fa';
import { RxMagnifyingGlass as MagnifyIcon } from 'react-icons/rx';
import AddSpecDialog from './AddSpecDialog';
import UpdateSpecDialog from './UpdateSpecDialog';
import DeleteSpecDialog from './DeleteSpecDialog';
import { useGetSpecificationsAdminQuery } from '../../../store/reduxApi';
import ShowLoading from '../../Alerts/ShowLoading';
import ShowError from '../../Alerts/ShowError';
import ShowSuccess from '../../Alerts/ShowSuccess';

const SpecificationsManager = () => {

    const classes = useStyles();

    const [openAddDialog, setOpenAddDialog] = React.useState(false);

    const [searchWord, setSearchWord] = React.useState('');

    const [updateDialog, setUpdateDialog] = React.useState({
        open: false,
        spec: false
    });

    const [deleteDialog, setDeleteDialog] = React.useState({
        open: false,
        specId: false
    });

    const { isLoading, isError, data = {} } = useGetSpecificationsAdminQuery(searchWord);

    return (
        <div className={classes.bigDiv}>
            <div className={classes.addButtonDiv}>
                <Button
                    onClick={() => { setOpenAddDialog(true); }}
                    style={{ backgroundColor: '#26A65B' }}
                    variant='contained'
                    disableElevation
                >
                    <AddIcon style={{ marginRight: '5px' }} />
                    Dodaj
                </Button>
            </div>
            <div className={classes.searchDiv}>
                <TextField
                    //disabled={isError}
                    autoComplete='off'
                    sx={{
                        '& .MuiInputAdornment-root': {
                            marginRight: '15px'
                        },
                        '& .MuiOutlinedInput-root': {
                            height: '40px',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                        },
                    }}
                    fullWidth
                    inputProps={{ style: { fontSize: '14px' } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <MagnifyIcon />
                            </InputAdornment>
                        ),
                    }}
                    placeholder='Pretraži specifikacije'
                    value={searchWord}
                    onChange={(e) => { setSearchWord(e.target.value); }}
                    size="small"
                //helperText='Minimum 3 karaktera'
                />

            </div>

            {isLoading ? <ShowLoading /> : null}
            {isError ? <ShowError errorText=' Neuspješno prikazivanje svih specifikacija' /> : null}

            <div className={classes.specificationsTable}>
                <table className={classes.table}>
                    <tbody>
                        <tr>
                            <th>Naziv</th>
                            <th>Tip</th>
                            <th style={{ textAlign: 'center' }}>Opcije</th>
                        </tr>
                        {
                            data?.specifications?.map((spec, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{spec.name}</td>
                                        <td>{spec.type}</td>
                                        <td>
                                            <div style={{
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 1fr',
                                                gap: '10px'
                                            }}>
                                                <Button
                                                    onClick={() => {
                                                        setUpdateDialog({
                                                            open: true,
                                                            spec
                                                        });
                                                    }}
                                                    variant='contained'
                                                    disableElevation
                                                    style={{ backgroundColor: '#006442' }}>
                                                    <EditIcon fontSize='12' />
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setDeleteDialog({
                                                            open: true,
                                                            specId: spec.id
                                                        });
                                                    }}
                                                    variant='contained'
                                                    disableElevation
                                                    style={{ backgroundColor: '#DC3023' }}>
                                                    <DeleteIcon fontSize='12' />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <AddSpecDialog
                open={openAddDialog}
                setOpen={setOpenAddDialog}
                ShowLoading={ShowLoading}
                ShowSuccess={ShowSuccess}
                ShowError={ShowError} />
            <UpdateSpecDialog
                open={updateDialog.open}
                setOpen={setUpdateDialog}
                spec={updateDialog.spec}
                ShowLoading={ShowLoading}
                ShowSuccess={ShowSuccess}
                ShowError={ShowError} />
            <DeleteSpecDialog
                open={deleteDialog.open}
                setOpen={setDeleteDialog}
                specId={deleteDialog.specId}
                ShowLoading={ShowLoading}
                ShowSuccess={ShowSuccess}
                ShowError={ShowError} />
        </div>
    );
};

export default SpecificationsManager;