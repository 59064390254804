import React from 'react';
import useStyles from './style';
import BigSlider from '../Big Slider/BigSlider';
import SmallSlider from '../Small Slider/SmallSlider';
import HomeProducts from '../Home Products/HomeProducts';
import { Typography } from '@mui/material';
import { useGetGlobalDiscountQuery } from '../../store/reduxApi';
import { CiDiscount1 as DiscountIcon } from 'react-icons/ci';
import moment from 'moment';

const Home = () => {

    const classes = useStyles();

    const { data = {}, isSuccess } = useGetGlobalDiscountQuery();

    const GlobalDiscount = () => {
        return (
            <div className={classes.globalDiscountDiv}>
                <div className={classes.discountIconDiv}>
                <DiscountIcon fontSize='50' style={{ color : '#ec1767' }}/>
                </div>
                <div style={{ textAlign : 'left' }}>
                    <Typography style={{ fontSize: '16px' }}>
                        Popust na sve proizvode {parseInt(data.global_discount)}%
                    </Typography>
                    <Typography style={{ fontSize: '13px' }}>
                        popust istice {moment(data.expiry_date).format("DD.MM.YYYY.")} u {moment(data.expiry_date).format("HH:mm")}h
                    </Typography>
                </div>
            </div>
        )
    };

    return (
        <div style={{ position: 'relative' }}>

            <div className={classes.bigSliderDiv}>
                <BigSlider />
            </div>

            <SmallSlider />

            {
                isSuccess && data.global_discount
                    ?
                    <GlobalDiscount />
                    :
                    null
            }

            <HomeProducts />

        </div>
    )
}

export default Home