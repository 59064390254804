import React from 'react';
import { Typography, Button, InputAdornment, TextField, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import useStyles from './style';
import { FaPlus as AddIcon } from 'react-icons/fa';
import { RxMagnifyingGlass as MagnifyIcon } from 'react-icons/rx';
import AddProductDialog from './AddProductDialog';
import { useLazyGetChildCategoriesQuery } from '../../../store/reduxApi';
import { HiArrowUp as IconUp, HiArrowDown as IconDown } from 'react-icons/hi';
import { useGetAllProductsAdminQuery } from '../../../store/reduxApi';
import ShowError from '../../Alerts/ShowError';
import ShowLoading from '../../Alerts/ShowLoading';
import ProductCard from '../../Product Card/ProductCard';
import UpdateProductDialog from './UpdateProductDialog';
import DeleteProductDialog from './DeleteProductDialog';

const Products = () => {

  const classes = useStyles();

  const [openAddProductDialog, setOpenAddProductDialog] = React.useState(false);

  const [trigger, { data = {} }] = useLazyGetChildCategoriesQuery();

  const [openUpdateProductDialog, setOpenUpdateProductDialog] = React.useState({
    open: false,
    product: false
  });

  const [openDeleteProductDialog, setOpenDeleteProductDialog] = React.useState({
    open: false,
    product: false
  });

  const [filter, setFilter] = React.useState({
    name: '',
    category: '',
    dateAdded: true
  });

  const { isLoading, isError, data: dataProducts = {}, error } = useGetAllProductsAdminQuery(filter);
  
  const handleFilterChange = (e) => { setFilter({ ...filter, [e.target.name]: e.target.value }) };

  React.useEffect(() => {
    trigger();
  }, []);

  return (
    <>
      <div className={classes.bigDiv}>
        <div className={classes.addButtonDiv}>
          <Button
            onClick={() => { setOpenAddProductDialog(true); }}
            style={{ backgroundColor: '#26A65B' }}
            variant='contained'
            disableElevation
          >
            <AddIcon style={{ marginRight: '5px' }} />
            dodaj
          </Button>

        </div>
        <div className={classes.searchDiv}>
          <div>
            <TextField
              disabled={(isError || isLoading) || !dataProducts?.products?.length}
              autoComplete='off'
              sx={{
                '& .MuiInputAdornment-root': {
                  marginRight: '15px'
                },
                '& .MuiOutlinedInput-root': {
                  height: '40px',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                  "&.Mui-focused fieldset": {
                    borderColor: "#f86673"
                  }
                },
              }}
              fullWidth
              inputProps={{ style: { fontSize: '16px' } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <MagnifyIcon />
                  </InputAdornment>
                ),
              }}
              placeholder='Pretraži proizvode'
              value={filter.name}
              onChange={handleFilterChange}
              name='name'
              size="small"
            />
          </div>
          <div className={classes.catDateFilter}>

            <FormControl fullWidth size='small'>
              <InputLabel
                id="demo-simple-select-label">Kategorija</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.category}
                label="Kategorija"
                name='category'
                disabled={isError || isLoading}
                onChange={handleFilterChange}
              >
                <MenuItem value=''>Sve</MenuItem>
                {
                  data?.categories?.map((category, index) => {
                    return <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                  })
                }
              </Select>

            </FormControl>

            <Button
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                textTransform: 'none',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                color: 'rgba(0, 0, 0, 0.6)',
                fontWeight: '400'
              }}
              onClick={() => {
                setFilter({ ...filter, dateAdded: !filter.dateAdded })
              }}
              variant='outlined'
              disableElevation
              disabled={(isError || isLoading) || !dataProducts?.products?.length}
            >
              {
                filter.dateAdded
                  ?
                  <><Typography>
                    Novo
                  </Typography>
                    <IconDown fontSize='18' />
                  </>
                  :
                  <><Typography>
                    Staro
                  </Typography>
                    <IconUp fontSize='18' />
                  </>
              }
            </Button>

          </div>
        </div>

        {
          dataProducts?.products?.length === 0
            ?
            <Typography style={{ marginTop: '20px' }}>Nema proizvoda</Typography>
            :
            null
        }

        {isLoading ? <ShowLoading /> : null}
        {isError ? <ShowError errorText='Neuspješno prikazivanje svih proizvoda' /> : null}
        <AddProductDialog 
          open={openAddProductDialog} 
          setOpen={setOpenAddProductDialog}
          categories={data.categories || []} />
        <UpdateProductDialog open={openUpdateProductDialog.open} product={openUpdateProductDialog.product} setOpen={setOpenUpdateProductDialog} />
        <DeleteProductDialog open={openDeleteProductDialog.open} product={openDeleteProductDialog.product} setOpen={setOpenDeleteProductDialog} />
      </div>

      <div className={classes.productsList}>
        {
          dataProducts?.products?.map((product, index) => {
            return <ProductCard
              setOpenDelete={setOpenDeleteProductDialog}
              setOpenUpdate={setOpenUpdateProductDialog}
              product={product}
              key={index}
              isAdmin={true} />
          })
        }
      </div>
    </>
  )
}

export default Products;