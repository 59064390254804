import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    registerBigDiv: {
        //border : '1px solid red',
        width: '91%',
        margin: 'auto',
        marginBottom: '25px'
    },
    registerBackIcon: {
        width: '100%',
        margin: 'auto',
        height: '45px',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        marginBottom: '25px',
        backgroundColor: '#ec1767',
        color: 'white',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'

    },
    registerTitle: {
        marginBottom: '18px'
    },
    registerTextField: {

        '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
            color: 'black',
            // paddingLeft : '0px',
            // paddingRight : '0px',
            '& fieldset': {
                border: 'none',
                boxShadow: '0px 0.458px 0.645px -0.292px rgba(0,0,0,0.2), 0px 1px 1.583px 0.125px rgba(0,0,0,0.14), 0px 0.375px 1.916px 0.333px rgba(0,0,0,0.12)',
            },
            "& .Mui-focused fieldset": {
                border: '2px solid red',
                //#BDC3C7
            }
        }
    },
    registerGridItem: {
        display: 'flex',
        flexDirection: 'column',
        gap: '3px',
    },
    cityDialog: {
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        gap: '7px'
    },
    noProfileDiv: {
        backgroundColor: 'white',
        height: '200px'
    },
    noProfileButton: {
        backgroundColor: 'white',
        color: 'black',
        border: '1px solid #f86673',
        width: '50%'
    },
}));

export default useStyles;