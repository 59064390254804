import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    productDiv: {
        //border: '2px solid red'
        marginBottom: '85px'
    },
    sliderDiv: {

        position: 'relative'
    },
    resetGallery: {
        right: '5px',
        color: 'black'
    },
    globalDiscountDiv: {
        textAlign: 'center',
        padding: '4px 10px',
        borderRadius: '4px',
        backgroundColor: '#ec1767',
        color: 'white',
        right: '5px'
    },
    slider: {
        //border : '2px solid red'
    },
    dots: {
        bottom: '8px',
        "& li.slick-active button::before": {
            color: '#ec1767',
            fontSize: '14px',
            opacity: '100'
        },
        "& li": {
            "& button::before": {
                fontSize: '8px',
                color: "black",
                opacity: '0.4',
            }
        }
    },
    prevArrow: {
        position: 'absolute',
        top: '50%',
        display: 'block',
        padding: '0',
        transform: 'translate(0, -50%)',
        border: 'none',
        outline: 'none',
        zIndex: '100'
    },
    nextArrow: {
        position: 'absolute',
        top: '50%',
        display: 'block',
        padding: '0',
        transform: 'translate(0, -50%)',
        border: 'none',
        outline: 'none',
        zIndex: '100',
        right : '0px'
    },
    variationTitleDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px'
    },
    photo: {
        height: '400px',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        // borderBottom : '1px solid rgb(236, 23, 103)'
    },
    status: {
        width: '80%',
        margin: 'auto',
        marginTop: '30px'
    },
    info: {
        //border : '2px solid tomato',
        width: '95%',
        margin: 'auto',
    },
    title: {
        textAlign: 'center'
    },
    prices: {
        marginTop: '15px'
    },
    infoRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '3px'
    },
    description: {
        marginTop: '15px'
    },
    specButtons: {

    },
    addToCartButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ec1767'
    },
    notSelectedVarInfo: {
        //border : '2px solid tomato',
        marginTop: '10px',

    }
}));

export default useStyles;