import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        backgroundColor: 'white',
        color: 'black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        //height: '35px'
        height : '100%'
    },
    navIcon: {
        color: 'black',
        fontSize: '24px'
    },
}));

export default useStyles;