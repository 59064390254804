import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    bigSliderDiv: {
        width: '92%',
        margin: 'auto',
        marginTop: '25px',
    },
    discountIconDiv : {
        display : 'flex', 
        alignItems : 'center', 
        justifyContent : 'center', 
        backgroundColor : 'white',
        borderRadius : '50%'
    },
    globalDiscountDiv : {
        width : '90%',
        margin : 'auto',
        marginTop : '16px',
        marginBottom : '16px',
        textAlign : 'center',
        padding : '5px',
        paddingTop : '8px',
        paddingBottom : '8px',
        borderRadius : '4px',
        backgroundColor : '#ec1767',
        color : 'white',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'center',
        alignItems : 'center',
        gap : '20px'
    }
}));

export default useStyles;