import { Button, TextField, Typography, Grid, Alert, AlertTitle, LinearProgress, Divider } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginUserMutation } from '../../store/reduxApi';
import { MdArrowBack as BackIcon } from 'react-icons/md';
import useStyles from './style';
import { loginValidate } from '../../validation/validator';
import ShowLoading from '../Alerts/ShowLoading';
import ShowError from '../Alerts/ShowError';

const loginInitialData = {
  emailOrPhone: '',
  password: ''
};

const Login = () => {

  const navigate = useNavigate();
  const classes = useStyles();

  const [loginUser, loginUserResult] = useLoginUserMutation();
  const { isLoading, isError, data: loginResultData, error } = loginUserResult;

  const [formErrors, setFormErrors] = React.useState({});
  const [isSubmit, setIsSubmit] = React.useState(false);

  const [loginData, setLoginData] = React.useState(loginInitialData);

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    setFormErrors(loginValidate(loginData));
    setIsSubmit(true);
  };

  React.useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      loginUser(loginData);
    }
  }, [formErrors]);

  React.useEffect(() => {
    if (typeof loginResultData !== 'undefined') {
      if (loginResultData.success) {
        if (loginResultData.isAdmin) {
          navigate('/adminPage');
        } else {
          navigate('/');
        }
      }
    }
  }, [loginResultData]);

  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.registerBackIcon}>
        <BackIcon style={{ fontSize: '22px', marginLeft: '8px' }} onClick={() => { navigate('/'); }} />
      </div>
      <div className={classes.registerBigDiv}>

        <div className={classes.registerTitle}>
          <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>
            Ukoliko ste već registrovali nalog za aplikaciju, prijavite se
          </Typography>
        </div>

        <form onSubmit={handleLoginSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.registerGridItem}>
                <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Unesite vaš email ili broj telefona:</Typography>
                <TextField
                  autoFocus
                  autoComplete='off'
                  placeholder='Unesite email ili telefon*'
                  type='text'
                  value={loginData.emailOrPhone}
                  name='emailOrPhone'
                  onChange={handleChange}
                  size='small'
                  fullWidth
                  variant='outlined'
                  className={classes.registerTextField}
                  error={formErrors.emailOrPhone ? true : false}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      "& fieldset": {
                        border: formErrors.emailOrPhone ? '1px solid black' : 'none'
                      }
                    },
                  }}
                  helperText={formErrors.emailOrPhone}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.registerGridItem}>
                <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Šifra:</Typography>
                <TextField

                  autoComplete='off'
                  placeholder='Unesite šifru*'
                  type='password'
                  value={loginData.password}
                  onChange={handleChange}
                  name='password'
                  size='small'
                  fullWidth
                  variant='outlined'
                  className={classes.registerTextField}
                  error={formErrors.password ? true : false}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      "& fieldset": {
                        border: formErrors.password ? '1px solid black' : 'none'
                      }
                    },
                  }}
                  helperText={formErrors.password}
                />
                <div style={{ marginTop: '5px' }}>
                  <Typography style={{ float: 'right', fontSize: '14px', color: '#22A7F0' }}>
                    Zaboravljena šifra?
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              {
                isError
                  ?
                  <ShowError errorText='Došlo je do greške u prijavljivanju pokušajte ponovo' errCode={500} />
                  :
                  null
              }

              {
                isLoading
                  ?
                  <ShowLoading />
                  :
                  <Button
                    style={{ backgroundColor: '#ec1767' }}
                    type='submit'
                    fullWidth
                    variant='contained'
                    disableElevation >
                    Prijavite se
                  </Button>
              }
            </Grid>
          </Grid>
        </form>
      </div>
      <Divider />
      <div className={classes.noProfileDiv}>
        <div style={{
          width: '90%',
          margin: 'auto',
          paddingTop: '20px'
        }}>
          <Typography style={{ fontSize: '14px', marginBottom: '15px' }}>
            Nemate nalog za aplikaciju?
          </Typography>
          <Button
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #ec1767'
            }}
            onClick={() => { navigate('/register') }}
            type='submit'
            fullWidth
            variant='contained'
            disableElevation >
            Registrujte se
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Login