import React from 'react';
import {
    Toolbar, AppBar, Button, Slide, Typography,
    IconButton, Dialog, DialogContent, Grid, TextField,
    FormControl, Select, InputLabel, MenuItem, InputAdornment, FormHelperText, Divider
} from '@mui/material';
import { TfiClose as CloseIcon } from 'react-icons/tfi';
import useStyles from './style';
import { FaPencilAlt as EditIcon, FaTrashAlt as DeleteIcon } from 'react-icons/fa';
import moment from 'moment';
import { FaPlus as AddIcon } from 'react-icons/fa';
import { useLazyGetChildCategoriesQuery } from '../../../store/reduxApi';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { MdDateRange as DateIcon } from 'react-icons/md';
import { productSchema } from '../../../validation/productValidation';
import { useAddProductMutation } from '../../../store/reduxApi';
import ShowLoading from '../../Alerts/ShowLoading';
import ShowError from '../../Alerts/ShowError';
import ShowSuccess from '../../Alerts/ShowSuccess';
import { RxDragHandleHorizontal as DragIcon } from 'react-icons/rx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useLazyGetSpecOfCatQuery } from '../../../store/reduxApi';
import AddProductVariation from './AddProductVariation';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialProductData = {
    name: 'Pamucna Majica Cotton',
    category: '',
    regular_price: '12',
    limit_per_customer: '10',
    photoGallery: [],
    has_lager: ''
};

const initialErrorsData = {
    name: false,
    category: false,
    regular_price: false,
    limit_per_customer: false,
    photoGallery: false,
    has_lager: false
};


const initialSimpleProductData = {
    price: '',
    discount: '',
    discount_price: '',
    discount_date: '',
    barcode: '1234567891234',
    qty: '20',
    simpleProductSpecs: []
};


const AddProductDialog = ({ open, setOpen, categories }) => {

    const classes = useStyles();

    const [previewPhotoGallery, setPreviewPhotoGallery] = React.useState([]);

    const [productVariationForEditing, setProductVariationForEditing] = React.useState({
        variation: false,
        index: false
    });

    const [openAddProductVariation, setOpenAddProductVariation] = React.useState(false);

    const [errors, setErrors] = React.useState(initialErrorsData);

    const [productData, setProductData] = React.useState(initialProductData);

    const [simpleProductData, setSimpleProductData] = React.useState(initialSimpleProductData);

    const [productVariations, setProductVariations] = React.useState([]);

    const [simpleProductSpecs, setSimpleProductSpecs] = React.useState([]);

    const [openDate, setOpenDate] = React.useState(false);

    const [addProduct, { isLoading, isError, isSuccess, error, reset }] = useAddProductMutation();

    const [trigger, { data: specOfCat, isSuccess: isSuccessSpec }] = useLazyGetSpecOfCatQuery();

    const inputRef = React.useRef();

    const handleClose = () => {
        setOpen(false);
        setProductData(initialProductData);
        setProductVariations([]);
        setErrors(initialErrorsData);
        setPreviewPhotoGallery([]);
        reset();
    };

    React.useEffect(() => {
        if (simpleProductData.discount.length && productData.regular_price.length) {
            const newPrice = productData.regular_price - simpleProductData.discount / 100 * productData.regular_price;
            setSimpleProductData({
                ...simpleProductData,
                discount_price: newPrice.toFixed(2)
            })
        }
        if (!simpleProductData.discount.length) {
            setSimpleProductData({
                ...simpleProductData,
                discount_price: ''
            })
        }
    }, [simpleProductData.discount]);

    React.useEffect(() => {
        if (productData.category) {
            trigger({ categoryId: productData.category });
        }
    }, [productData.category]);


    const getTextFieldType = (allowedCharacters) => {
        switch (allowedCharacters) {
            case 'Natural number':
                return 'number'
            case 'Number':
                return 'number'
            case 'Letters':
                return 'string'
            case 'All':
                return 'string'
            default: return 'string'
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files) {
            setProductData((prevData) => {
                return {
                    ...prevData,
                    photoGallery: prevData.photoGallery.concat([...e.target.files])
                }
            });
            const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
            setPreviewPhotoGallery((prevImageGallery) => prevImageGallery.concat(filesArray));
        }
    };

    const handleImageRemoval = (index) => {
        setProductData((prevData) => {
            return {
                ...prevData,
                photoGallery: prevData.photoGallery.filter((photo, photoIndex) => photoIndex !== index)
            }
        });
        setPreviewPhotoGallery((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
    };

    function handleDragEnd(result) {
        if (!result.destination) {
            // Item was dropped outside of droppable area
            return;
        }

        const reorderedDivs = Array.from(previewPhotoGallery);
        const [reorderedDiv] = reorderedDivs.splice(result.source.index, 1);
        reorderedDivs.splice(result.destination.index, 0, reorderedDiv);

        setPreviewPhotoGallery(reorderedDivs);

        const reorderedDivsGallery = Array.from(productData.photoGallery);
        const [reorderedDivGallery] = reorderedDivsGallery.splice(result.source.index, 1);
        reorderedDivsGallery.splice(result.destination.index, 0, reorderedDivGallery);

        setProductData({
            ...productData,
            photoGallery: reorderedDivsGallery
        });
    }

    const handleAddProduct = (e) => {
        e.preventDefault();
        const formData = new FormData();

        const { photoGallery, ...rest } = productData;

        photoGallery.forEach((photo) => {
            formData.append('photoGallery', photo);
        });

        const productDataJson = JSON.stringify(rest);
        formData.append('productData', productDataJson);

        console.log({
            productData,
            simpleProductData,
            productVariations
        });

        if (productVariations.length) {
            productVariations.forEach((variation, index) => {
                const { variationGallery, ...rest } = variation;
                const varJson = JSON.stringify(rest);
                formData.append(`variation_${index}`, varJson);
                if (variationGallery.length) {
                    variationGallery.forEach((photo) => {
                        formData.append(`variation_${index}`, photo);
                    });
                }
            });
            addProduct(formData);
        } else {
            formData.append('simpleProductData', JSON.stringify(simpleProductData));
            addProduct(formData);
        }
        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ' : ' + pair[1]);
        // }
        // productSchema.validate(productData, { abortEarly: false })
        //     .then(() => {
        //         setErrors(initialErrorsData);
        //         const formData = new FormData();
        //         Object.keys(productData).forEach(key => {
        //             if (key === 'photoGallery') return;
        //             formData.append(key, productData[key]);
        //         });
        //         productData.photoGallery.forEach((photo) => {
        //             formData.append('photoGallery', photo);
        //         })
        //         addProduct(formData);
        //     })
        //     .catch((error) => {
        //         const newErrors = { ...initialErrorsData };
        //         error.inner.forEach(e => {
        //             newErrors[e.path] = e.message
        //         });
        //         setErrors({ ...newErrors });
        //     });
    };

    const handleChange = (e) => {
        setProductData({
            ...productData,
            [e.target.name]: e.target.value
        })
    };

    const GetAlert = () => {
        if (isLoading) return <ShowLoading />
        if (isError) return <ShowError
            errorText={error?.status === 403 ? error?.data?.message : 'Neuspješno dodavanje proizvoda'}
            errCode={error?.status} />
        if (isSuccess) return <ShowSuccess successText='Uspješno dodavanje proizvoda' />
    };

    const getVariationData = (specObject) => {
        let varName = '';

        for (const key in specObject) {
            const specIndex = specOfCat.specData.findIndex((spec) => spec.name === key);
            if (specOfCat.specData[specIndex].type === 'select') {
                const option = specOfCat.specData[specIndex].options.find((option) => option.id === specObject[key]);
                varName += ' ' + option.title;
            } else {
                varName += ' ' + specObject[key] + ' ' + specOfCat.specData[specIndex].measure;
            }
        }

        return varName;
    };

    const handleRemoveVariation = (index) => {
        const updatedVariations = productVariations.filter((variation, index) => index !== 0);
        setProductVariations([...updatedVariations]);
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar
                sx={{
                    position: 'relative',
                    backgroundColor: '#f54554'
                }}>
                <Toolbar sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: 'white'
                }}>
                    {/* <IconButton
                        edge="start"
                        onClick={handleClose}
                    > */}
                    <CloseIcon
                        fontSize='22'
                        onClick={handleClose}
                        color='#ffffff'
                        style={{ color: 'white' }} />
                    {/* </IconButton> */}

                    <Button
                        disabled={isLoading}
                        form="myform"
                        variant='outlined'
                        disableElevation
                        type='submit'
                        style={{ backgroundColor: 'white', color: 'black', border: 'black' }}
                    >
                        Sačuvaj
                    </Button>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ marginTop: '15px' }}>
                <form onSubmit={handleAddProduct} id='myform'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <GetAlert />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete='off'
                                label='Naziv proizvoda'
                                type='text'
                                value={productData.name}
                                onChange={handleChange}
                                size='small'
                                name='name'
                                fullWidth
                                variant='outlined'
                                error={Boolean(errors.name)}
                                helperText={errors.name ?? errors.name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='subtitle2'>
                                Slike proizvoda:
                            </Typography>
                            <Button
                                onClick={() => { inputRef.current.click(); }}
                                variant='contained'
                                disableElevation
                                style={{ backgroundColor: '#26A65B', marginTop: '5px' }}
                            >
                                <AddIcon />
                                <input
                                    ref={inputRef}
                                    id='dugme'
                                    type="file"
                                    onChange={handleImageChange}
                                    multiple
                                    accept='image/*'
                                    hidden
                                />
                            </Button>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="div-list">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            <div className={classes.productGallery}>
                                                {previewPhotoGallery.map((image, index) => (
                                                    <Draggable key={index} draggableId={index.toString()} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <div
                                                                    style={{
                                                                        backgroundImage: index === 0 ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23F86673FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")` : 'inherit',

                                                                    }}
                                                                    key={index}
                                                                    className={classes.singleImage}>
                                                                    <div
                                                                        className={classes.image}
                                                                        style={{ backgroundImage: `url('${image}')` }}>
                                                                        <div
                                                                            onTouchStartCapture={(e) => {
                                                                                handleImageRemoval(index)
                                                                            }}
                                                                            className={classes.deleteImage}>
                                                                            <DeleteIcon style={{ color: '#DC3023', fontSize: '20px' }} />
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={classes.dragImage}>
                                                                        <DragIcon style={{ fontSize: '34px', marginRight: '10px' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {
                                errors.photoGallery
                                    ?
                                    <FormHelperText style={{ color: '#d32f2f' }}>{errors.photoGallery}</FormHelperText>
                                    :
                                    null
                            }
                        </Grid>



                        <Grid item xs={12}>
                            <FormControl fullWidth size='small'>
                                <InputLabel
                                    style={{ color: Boolean(errors.category) ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)' }}
                                    id="demo-simple-select-label">Kategorija</InputLabel>
                                <Select
                                    error={Boolean(errors.category)}
                                    labelId="demo-simple-select-label"
                                    value={productData.category}
                                    label="Kategorija"
                                    name='category'
                                    onChange={handleChange}
                                >
                                    {
                                        categories.map((category, index) => {
                                            return <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    errors.category
                                        ?
                                        <FormHelperText style={{ color: '#d32f2f' }}>{errors.category}</FormHelperText>
                                        :
                                        null
                                }
                            </FormControl>
                        </Grid>

                        <Grid item xs={7}>
                            <TextField
                                error={Boolean(errors.regular_price)}
                                helperText={errors.regular_price ?? errors.regular_price}
                                autoComplete='off'
                                label='Regularna cijena &euro;'
                                type='number'
                                value={productData.regular_price}
                                onChange={handleChange}
                                size='small'
                                name='regular_price'
                                fullWidth
                                variant='outlined'
                            />
                        </Grid>

                        <Grid item xs={5}>
                            <TextField
                                error={Boolean(errors.limit_per_customer)}
                                helperText={errors.limit_per_customer ?? errors.limit_per_customer}
                                autoComplete='off'
                                label='Limit po kupcu'
                                type='number'
                                value={productData.limit_per_customer}
                                onChange={handleChange}
                                size='small'
                                name='limit_per_customer'
                                fullWidth
                                variant='outlined'
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth size='small'>
                                <InputLabel
                                    style={{ color: Boolean(errors.has_lager) ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)' }}
                                    id="demo-simple-select-label">Više varijanti proizvoda ?</InputLabel>
                                <Select
                                    error={Boolean(errors.has_lager)}
                                    labelId="demo-simple-select-label"
                                    value={productData.has_lager}
                                    label="Više varijanti proizvoda ?"
                                    name='has_lager'
                                    onChange={handleChange}
                                >
                                    {/* <MenuItem  value=''></MenuItem> */}
                                    <MenuItem value={true}>Da</MenuItem>
                                    <MenuItem value={false}>Ne</MenuItem>
                                </Select>
                                {
                                    errors.has_lager
                                        ?
                                        <FormHelperText style={{ color: '#d32f2f' }}>{errors.has_lager}</FormHelperText>
                                        :
                                        null
                                }
                            </FormControl>
                        </Grid>

                        {

                            typeof productData.has_lager === 'string'
                                ?
                                null
                                :
                                productData.has_lager
                                    ?
                                    <>
                                        <Grid item xs={12} textAlign='center'>
                                            <Divider />
                                            <Typography variant='subtitle1'>
                                                Varijabilni proizvod
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} marginBottom='10px'>
                                            <Typography variant='subtitle2'>
                                                Dodaj varijaciju:
                                            </Typography>
                                            <Button
                                                onClick={() => { setOpenAddProductVariation(true); }}
                                                variant='contained'
                                                disableElevation
                                                style={{ backgroundColor: '#26A65B', marginTop: '5px' }}
                                            >
                                                <AddIcon />
                                            </Button>

                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                productVariations.length
                                                    ?
                                                    <div className={classes.productVariations}>
                                                        {
                                                            productVariations.map((variation, index) => {
                                                                return (
                                                                    <>
                                                                        <div key={index} className={classes.variation}>
                                                                            <div className={classes.variationData}>
                                                                                <Typography variant='subtitle1'>
                                                                                    {
                                                                                        getVariationData(variation.variationSpecs)
                                                                                    }
                                                                                </Typography>
                                                                            </div>
                                                                            <div className={classes.variationButtons}>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setProductVariationForEditing({
                                                                                            variation,
                                                                                            index
                                                                                        });
                                                                                        setOpenAddProductVariation(true);
                                                                                    }}
                                                                                    disableElevation
                                                                                    variant='contained'
                                                                                    style={{ backgroundColor: '#5B8930', minWidth: '40px' }}>
                                                                                    <EditIcon />
                                                                                </Button>
                                                                                <Button
                                                                                    onClick={() => { handleRemoveVariation(index); }}
                                                                                    disableElevation
                                                                                    variant='contained'
                                                                                    style={{ backgroundColor: '#DC3023', minWidth: '40px' }}>
                                                                                    <DeleteIcon />
                                                                                </Button>
                                                                            </div>

                                                                        </div>

                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    <Typography>
                                                        Za varijabilne proizvode morate dodati minimum 2 varijacije
                                                    </Typography>
                                            }
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid item xs={12} textAlign='center'>
                                            <Divider />
                                            <Typography variant='subtitle1'>
                                                Jednostavni proizvod
                                            </Typography>
                                        </Grid>


                                        <Grid item xs={5}>
                                            <TextField
                                                autoComplete='off'
                                                label='Popust %'
                                                type='number'
                                                value={simpleProductData.discount}
                                                onChange={(e) => { setSimpleProductData({ ...simpleProductData, discount: e.target.value }) }}
                                                size='small'
                                                fullWidth
                                                variant='outlined'
                                                error={Boolean(errors.discount)}
                                                helperText={errors.discount ?? errors.discount}
                                            />
                                        </Grid>

                                        <Grid item xs={7}>
                                            <TextField
                                                error={Boolean(errors.discount_price)}
                                                helperText={errors.discount_price ?? errors.discount_price}
                                                disabled={!Boolean(simpleProductData.discount)}
                                                autoComplete='off'
                                                label='Cijena na popust &euro;'
                                                type='number'
                                                value={simpleProductData.discount_price}
                                                onChange={(e) => { setSimpleProductData({ ...simpleProductData, discount_price: e.target.value }) }}
                                                size='small'
                                                name='discount_price'
                                                fullWidth
                                                variant='outlined'
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <MobileDatePicker
                                                    //value={moment(simpleProductData.discount, 'YYYY/MM/DD').toDate() }                            
                                                    closeOnSelect
                                                    disablePast
                                                    showToolbar={false}
                                                    inputFormat="DD/MM/YYYY"
                                                    open={openDate}
                                                    onChange={(e) => {
                                                        setSimpleProductData({
                                                            ...simpleProductData,
                                                            discount_date: moment(e._d).format('DD/MM/YYYY')
                                                        })
                                                    }}
                                                    onClose={() => { setOpenDate(false); }}
                                                    renderInput={(params) => <TextField style={{ display: 'none' }} />}
                                                />
                                            </LocalizationProvider>
                                            <TextField
                                                error={Boolean(errors.discount_date)}
                                                helperText={errors.discount_date ?? errors.discount_date}
                                                disabled={!Boolean(simpleProductData.discount)}
                                                onClick={() => { if (Boolean(simpleProductData.discount)) { setOpenDate(true); } }}
                                                value={simpleProductData.discount_date}
                                                label="Datum popusta"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <DateIcon size='22' />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                // {...params}
                                                fullWidth
                                                size='small' />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                autoComplete='off'
                                                label='Barkod'
                                                type='text'
                                                value={simpleProductData.barcode}
                                                onChange={(e) => {
                                                    setSimpleProductData({
                                                        ...simpleProductData,
                                                        barcode: e.target.value
                                                    })
                                                }}
                                                size='small'
                                                name='barcode'
                                                fullWidth
                                                variant='outlined'
                                                error={Boolean(errors.barcode)}
                                                helperText={errors.barcode ?? errors.barcode}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                error={Boolean(errors.qty)}
                                                helperText={errors.qty ?? errors.qty}
                                                autoComplete='off'
                                                label='Dostupnost'
                                                type='number'
                                                value={simpleProductData.qty}
                                                onChange={(e) => {
                                                    setSimpleProductData({
                                                        ...simpleProductData,
                                                        qty: e.target.value
                                                    })
                                                }}
                                                size='small'
                                                name='qty_available'
                                                fullWidth
                                                variant='outlined'
                                            />
                                        </Grid>



                                        <Grid item xs={12} textAlign='center'>
                                            {
                                                productData.category
                                                    ?
                                                    specOfCat.specData.length
                                                        ?
                                                        <Typography variant='body1'>
                                                            Specifikacije
                                                        </Typography>
                                                        :
                                                        <Typography variant='body1'>
                                                            Izabrana kategorija nema specifikacije
                                                        </Typography>
                                                    :
                                                    <Typography variant='body1'>
                                                        Izaberite kategoriju da biste dobili specifikacije
                                                    </Typography>
                                            }
                                        </Grid>

                                        {

                                            isSuccessSpec
                                                ?
                                                specOfCat.specData.map((spec, index) => {
                                                    return (
                                                        spec.type === 'select'
                                                            ?
                                                            <Grid item xs={12} key={index}>
                                                                <FormControl fullWidth size='small'>
                                                                    <InputLabel
                                                                        id="demo-simple-select-label">{spec.name}</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        value={simpleProductData.simpleProductSpecs[spec.name] || ''}
                                                                        label={spec.name}
                                                                        name={spec.name}
                                                                        onChange={(e) => {
                                                                            const varSpecs = simpleProductData.simpleProductSpecs;
                                                                            varSpecs[spec.name] = e.target.value;
                                                                            setSimpleProductData({
                                                                                ...simpleProductData,
                                                                                simpleProductSpecs: { ...varSpecs }
                                                                            });
                                                                        }}
                                                                    >
                                                                        {
                                                                            spec.options.map((option, index) => {
                                                                                return <MenuItem key={index} value={option.id}>{option.title}</MenuItem>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            :
                                                            <Grid item xs={12} key={index}>
                                                                <TextField
                                                                    helperText={spec.placeholder}
                                                                    autoComplete='off'
                                                                    type={getTextFieldType(spec.allowed_characters)}
                                                                    value={simpleProductData.simpleProductSpecs[spec.name] || ''}
                                                                    label={spec.name}
                                                                    name={spec.name}
                                                                    onChange={(e) => {
                                                                        const varSpecs = simpleProductData.simpleProductSpecs;
                                                                        varSpecs[spec.name] = e.target.value;
                                                                        setSimpleProductData({
                                                                            ...simpleProductData,
                                                                            simpleProductSpecs: { ...varSpecs }
                                                                        });
                                                                    }}
                                                                    size='small'
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <Typography>{spec.measure}</Typography>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </Grid>
                                                    )
                                                })
                                                :
                                                null

                                        }

                                    </>

                        }

                    </Grid>
                </form>
            </DialogContent>
            <AddProductVariation
                open={openAddProductVariation}
                setOpen={setOpenAddProductVariation}
                category={productData.category}
                productPrice={productData.regular_price}
                specs={specOfCat?.specData}
                productVariations={productVariations}
                setProductVariations={setProductVariations}
                getTextFieldType={getTextFieldType}
                edit={productVariationForEditing}
                setEdit={setProductVariationForEditing}
            />
        </Dialog>
    )
}

export default AddProductDialog