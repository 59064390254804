import React, { createContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Home from './components/Home/Home';
import UserPage from './components/User Page/UserPage';
import Cart from './components/Cart/Cart';
import AdminPage from './components/Admin/AdminPage';
import ProtectedRoute from './components/ProtectedRoute';
import { useAuth } from './Hooks/useAuth';
import { Navigate } from 'react-router-dom';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import Notifications from './components/Notifications/Notifications';
import ProductsPage from './components/Products Page/ProductsPage';
import WithNavBar from './WithNavBar';
import ShowProduct from './components/Show Product/ShowProduct';

export const GlobalContext = createContext();


const THEME = createTheme({
  typography: {
    fontFamily: "'Playfair Display', sans-serif",
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          //backgroundColor: "yellow",
          "&.Mui-selected": {
            backgroundColor: "#ec1767",
            color: 'white',
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#ec1767",
            color: 'white',
          },
          
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ec1767'
          },
          '& label.Mui-focused': {
            color: 'black', // Set your desired color for the focused label
          },

        }
      }
    },
    MuiSelect: {
     
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#ec1767'
        },
        bar: {
          backgroundColor: '#f86673'
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          '& .MuiList-root': {
            paddingTop: '0px',
            paddingBottom: '0px',
          }
        }
      }
    }
  }
});


const App = () => {

  const { auth, isAdmin } = useAuth();

  const [loggedUserData, setLoggedUserData] = React.useState({});

  const [currentPage, setCurrentPage] = React.useState(JSON.parse(localStorage.getItem('currentPage')) || 0);

  const [savedProducts, setSavedProducts] = React.useState(JSON.parse(localStorage.getItem('savedProducts')) || []);

  const [searchProductWord, setSearchProductWord] = React.useState('');

  const [currentProduct, setCurrentProduct] = React.useState(JSON.parse(localStorage.getItem('currentProduct')) || null);

  const [cart, setCart] = React.useState(JSON.parse(localStorage.getItem('cart')) || []);

  React.useEffect(() => {
    if (typeof auth !== 'undefined' && auth === true) {
      setLoggedUserData(JSON.parse(localStorage.getItem('loggedUserData')) || {});
    }
    if (window.location.pathname === '/') {
      setCurrentPage(0);
    }
  }, [auth]);

  return (
    <ThemeProvider theme={THEME}>
      <BrowserRouter>
        <GlobalContext.Provider value={{
          loggedUserData,
          currentPage,
          setCurrentPage,
          savedProducts,
          setSavedProducts,
          searchProductWord,
          setSearchProductWord,
          currentProduct,
          setCurrentProduct,
          cart,
          setCart
        }}>
          <Routes>

            <Route element={<WithNavBar auth={auth} />}>
              <Route path='/' element={<Home auth={auth} />} />
              <Route path='products' element={<ProductsPage />} />
              <Route path='/products/:id' element={<ShowProduct auth={auth} />} />
            </Route>

            <Route
              path='/login'
              element={typeof auth !== 'undefined' ? auth ? <Navigate to={isAdmin ? '/adminPage' : '/userPage'} /> : <Login /> : null} />

            <Route
              path='/notifications'
              element={typeof auth !== 'undefined' ? auth ? <Notifications /> : <Login /> : null} />

            <Route path='/register' element={<Register />} />

            <Route
              path='/userPage'
              element={
                typeof auth !== 'undefined'
                  ?
                  !isAdmin
                    ?
                    <ProtectedRoute>
                      <UserPage />
                    </ProtectedRoute>
                    :
                    <Navigate to='/adminPage' />
                  :
                  null
              } />

            <Route
              path='/adminPage'
              element={
                typeof auth !== 'undefined'
                  ?
                  isAdmin
                    ?
                    <ProtectedRoute>
                      <AdminPage />
                    </ProtectedRoute>
                    :
                    <Navigate to='/userPage' />
                  :
                  null
              } />

            <Route
              path='/cart'
              element={typeof auth !== 'undefined' ? auth ? <Cart /> : <Login /> : null}
            />

          </Routes>
        </GlobalContext.Provider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
