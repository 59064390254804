import {
  TextField, Typography, Button, Grid,
  InputAdornment, LinearProgress, DialogContent,
  Dialog, Divider, DialogActions, DialogTitle, Alert, AlertTitle
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRegisterUserMutation } from '../../store/reduxApi';
import useStyles from './style';
import { MdArrowBack as BackIcon } from 'react-icons/md';
import { BsChevronDown as DownIcon } from 'react-icons/bs';
import { registerValidate } from '../../validation/validator';
import ShowError from '../Alerts/ShowError';
import ShowLoading from '../Alerts/ShowLoading';
import ShowSuccess from '../Alerts/ShowSuccess';

const registerInitialData = {
  name: '',
  surname: '',
  phone: '',
  email: '',
  city: '',
  password: '',
  confirmPassword: ''
};

const cities = ['Podgorica', 'Nikšić', 'Pljevlja', 'Bijelo Polje', 'Cetinje', 'Bar', 'Herceg Novi', 'Berane', 'Budva', 'Ulcinj', 'Tivat', 'Rožaje', 'Kotor', 'Danilovgrad', 'Mojkovac', 'Plav', 'Kolašin', 'Žabljak', 'Plužine', 'Andrijevica', '	Šavnik'];

const Register = () => {

  const classes = useStyles();

  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = React.useState(false);

  const [registerUser, registerUserResult] = useRegisterUserMutation();
  const { isLoading, isError, isSuccess, error, data: registerUserResultData } = registerUserResult;

  const [formErrors, setFormErrors] = React.useState({});
  const [isSubmit, setIsSubmit] = React.useState(false);

  const [registerData, setRegisterData] = React.useState(registerInitialData);

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    setFormErrors(registerValidate(registerData));
    setIsSubmit(true);
  };

  React.useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      registerUser(registerData);
    }
  }, [formErrors]);

  const handleChange = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value
    });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const PickCityDialog = () => {
    return (
      <Dialog open={openDialog} onClose={handleClose} fullWidth>
        <DialogTitle>Odaberi grad: </DialogTitle>
        <DialogContent className={classes.cityDialog}>
          {
            cities.map((city, index) => {
              return (
                <Typography
                  variant='subtitle2'
                  key={index}
                  onClick={() => {
                    setRegisterData({
                      ...registerData, city
                    });
                    handleClose();
                  }}>
                  {city}
                </Typography>
              )
            })
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
  };

  return (
    <>
      <div className={classes.registerBackIcon}>
        <BackIcon style={{ fontSize: '22px', marginLeft: '8px' }} onClick={() => { navigate(-1); }} />
      </div>
      <div className={classes.registerBigDiv}>

        <div className={classes.registerTitle}>
          <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>
            Da biste kreirali nalog unesite Vaše podatke u nastavku
          </Typography>
        </div>

        <form onSubmit={handleRegisterSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.registerGridItem}>
                <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Ime:</Typography>
                <TextField
                  autoFocus
                  autoComplete='off'
                  placeholder='Unesite ime*'
                  type='text'
                  value={registerData.name}
                  name='name'
                  onChange={handleChange}
                  size='small'
                  fullWidth
                  variant='outlined'
                  className={classes.registerTextField}
                  error={formErrors.name ? true : false}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      "& fieldset": {
                        border: formErrors.name ? '1px solid black' : 'none'
                      }
                    },
                  }}
                  helperText={formErrors.name}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.registerGridItem}>
                <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Prezime:</Typography>
                <TextField

                  autoComplete='off'
                  placeholder='Unesite prezime*'
                  type='text'
                  value={registerData.surname}
                  name='surname'
                  onChange={handleChange}
                  size='small'
                  fullWidth
                  variant='outlined'
                  className={classes.registerTextField}
                  error={formErrors.surname ? true : false}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      "& fieldset": {
                        border: formErrors.surname ? '1px solid black' : 'none'
                      }
                    },
                  }}
                  helperText={formErrors.surname}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.registerGridItem}>
                <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Email:</Typography>
                <TextField
                  autoComplete='off'
                  placeholder='Unesite email'
                  type='email'
                  value={registerData.email}
                  name='email'
                  onChange={handleChange}
                  size='small'
                  fullWidth
                  variant='outlined'
                  className={classes.registerTextField}
                  error={formErrors.email ? true : false}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      "& fieldset": {
                        border: formErrors.email ? '1px solid black' : 'none'
                      }
                    },
                  }}
                  helperText={formErrors.email}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.registerGridItem}>
                <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Broj mobilnog telefona:</Typography>
                <TextField

                  autoComplete='off'
                  placeholder='69123456'
                  type='number'
                  value={registerData.phone}
                  name='phone'
                  onChange={handleChange}
                  size='small'
                  fullWidth
                  variant='outlined'
                  className={classes.registerTextField}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+382</InputAdornment>
                  }}
                  sx={{
                    '& .MuiInputAdornment-root': {
                      backgroundColor: '#BDC3C7',
                      padding: '20px',
                      borderTopLeftRadius: '4px',
                      borderBottomLeftRadius: '4px'
                    },
                    '& .MuiOutlinedInput-root': {
                      "& fieldset": {
                        border: formErrors.phone ? '1px solid black' : 'none'
                      },
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    }
                  }}
                  error={formErrors.phone ? true : false}
                  helperText={formErrors.phone}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.registerGridItem}>
                <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Grad:</Typography>
                <TextField

                  onClick={() => { setOpenDialog(true); }}
                  autoComplete='off'
                  placeholder='Odaberi grad*'
                  value={registerData.city}
                  name='email'
                  size='small'
                  fullWidth
                  variant='outlined'
                  className={classes.registerTextField}
                  InputProps={{
                    endAdornment: <InputAdornment position="end"><DownIcon /></InputAdornment>,
                    readOnly: true
                  }}
                  error={formErrors.city ? true : false}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      "& fieldset": {
                        border: formErrors.city ? '1px solid black' : 'none'
                      }
                    },
                  }}
                  helperText={formErrors.city}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.registerGridItem}>
                <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Šifra:</Typography>
                <TextField

                  autoComplete='off'
                  placeholder='Unesite šifru*'
                  type='password'
                  value={registerData.password}
                  onChange={handleChange}
                  name='password'
                  size='small'
                  fullWidth
                  variant='outlined'
                  className={classes.registerTextField}
                  error={formErrors.password ? true : false}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      "& fieldset": {
                        border: formErrors.password ? '1px solid black' : 'none'
                      }
                    },
                  }}
                  helperText={formErrors.password}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.registerGridItem}>
                <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Potvrda šifre:</Typography>
                <TextField
                  autoComplete='off'
                  placeholder='Potvrdite šifru*'
                  type='password'
                  value={registerData.confirmPassword}
                  onChange={handleChange}
                  name='confirmPassword'
                  size='small'
                  fullWidth
                  variant='outlined'
                  className={classes.registerTextField}
                  error={formErrors.confirmPassword ? true : false}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      "& fieldset": {
                        border: formErrors.confirmPassword ? '1px solid black' : 'none'
                      }
                    },
                  }}
                  helperText={formErrors.confirmPassword}
                />
              </div>
            </Grid>

            <Grid item xs={12} style={{ marginTop: '10px' }}>
              {
                isError
                  ?
                  <ShowError errorText='Došlo je do greške u registraciji, pokušajte ponovo' errCode={error?.status} />
                  :
                  null
              }
              {
                isSuccess
                  ?
                  <ShowSuccess successText='Uspješna registracija, verifikujte svoj nalog sa kodom poslatim na vaš telefon' />
                  :
                  null
              }
              {
                isLoading
                  ?
                  <ShowLoading />
                  :
                  <Button
                    style={{ backgroundColor: '#f86673' }}
                    type='submit'
                    fullWidth
                    variant='contained'
                    disableElevation >
                    Napravi profil
                  </Button>
              }
            </Grid>

          </Grid>
        </form>
      </div>
      <PickCityDialog />
      <Divider />
      <div className={classes.noProfileDiv}>
        <div style={{
          width: '90%',
          margin: 'auto',
          paddingTop: '20px'
        }}>
          <Typography style={{ fontSize: '14px', marginBottom: '15px' }}>
            Već imate nalog?
          </Typography>
          <Button
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #f86673'
            }}
            onClick={() => { navigate('/login') }}
            type='submit'
            fullWidth
            variant='contained'
            disableElevation >
            Prijavite se
          </Button>
        </div>
      </div>
    </>
  );
};

export default Register