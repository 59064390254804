import React, { useContext } from 'react';
import useStyles from './style';
import { Typography } from '@mui/material';
import { useGetNewestProductsQuery } from '../../store/reduxApi';
import ProductCard from '../Product Card/ProductCard';
import ShowLoading from '../Alerts/ShowLoading';
import ShowError from '../Alerts/ShowError';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../App';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const HomeProducts = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const { setCurrentPage } = useContext(GlobalContext);

    const { isLoading, isError, data = {} } = useGetNewestProductsQuery();

    return (
        <div className={classes.homeProductsDiv}>

            {isLoading ? <ShowLoading /> : null}
            {isError ? <ShowError /> : null}

            {
                data?.newestProducts?.map((newest, index) => {
                    return (
                        <div key={index}>
                            <div className={classes.homeProductsTitle}>
                                <Typography variant='subtitle1'> {newest.parentCategory.name} </Typography>
                                <Typography
                                    onClick={() => {
                                        navigate(`/products?category=${newest.parentCategory.seo}`);
                                        localStorage.setItem('currentPage', JSON.stringify(1));
                                        setCurrentPage(1);
                                    }}
                                    variant='caption'
                                    color='blue'>
                                    Pogledaj sve
                                </Typography>
                            </div>
                            <div className={classes.homeProducts}>
                                {
                                    newest.products.map((product, index) => {
                                        return <ProductCard product={product} key={index} />
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }

        </div>
    );
};

export default HomeProducts;