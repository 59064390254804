import * as yup from 'yup';
import moment from 'moment';

export const productSchema = yup.object().shape({
  name: yup.string().min(2, 'Naziv proizvoda mora sadržati minimum 2 karaktera').required('Obavezno polje'),
  category: yup.lazy((value) => (value === '' ? yup.string().required('Obavezno polje') : yup.number().integer('Mora biti cijeli broj').required('Obavezno polje'))),
  regular_price: yup.lazy((value) => (value === '' ? yup.string().required('Obavezno polje') : yup.number().positive('Cijena mora biti pozitivna').required('Obavezno polje'))),
  limit_per_customer: yup.lazy((value) => (value === '' ? yup.string().required('Obavezno polje') : yup.number().positive('Limit mora biti pozitivan').required('Obavezno polje'))),
  photoGallery: yup.array().min(1, 'Galerija mora imati minimum 1 sliku'),
  has_lager : yup.bool().required('Obavezno polje')
});

/*
discount: yup.lazy((value) => (value === '' ? yup.string().notRequired() : yup.number().min(1, 'Minimum 1%').max(99, 'Max 99%').required('Obavezno polje'))),
discount_price: yup.number()
  .nullable()
  .transform((value, originalValue) => typeof originalValue === 'string' ? originalValue.trim() === "" ? null : value : value)
  .when('discount', (discount, schema) => {
    if (discount > 0) {
      return schema.lessThan(yup.ref("regular_price"), 'Popust mora biti manji od regularne cijene').positive('Cijena mora biti pozitivna').required('Obavezno polje');
    } else {
      return schema.notRequired();
    }
  }),
discount_date: yup.string()
  .nullable()
  .transform((value, originalValue) => originalValue.trim() === "" ? null : value)
  .when('discount', (discount, schema) => {
    if (discount > 0) {
      return schema.required('Obavezno polje').test('isValid', 'Nepravilan format datuma', function (value) {
        return moment(value, 'DD/MM/YYYY', true).isValid();
      });
    } else {
      return schema.notRequired();
    }
  }),
barcode: yup.lazy((value) => (value === '' ? yup.string().required('Obavezno polje') : yup.string().matches(/^\d{13}$/, 'Barkod mora imati 13 cifara').required('Obavezno polje'))),
qty_available: yup.lazy((value) => (value === '' ? yup.string().required('Obavezno polje') : yup.number().positive('Količina mora biti pozitivna').required('Obavezno polje'))),
*/