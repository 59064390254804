import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    productPageMainDiv: {
        //border : '3px solid red',
        marginBottom : '70px'
    },
    productsDiv: {
        //border : '1px solid red',
        display: 'grid',
        gridTemplateColumns: '24% 76%',
        //marginBottom : '20px'
        //height : '90vh'
    },
    productsCategories: {
        backgroundColor: 'white',
        //height: '90vh',
        height: 'calc(90vh - 70px)', // micemo 70px koji su dodati kao marginBottom gore 
        overflowX: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    products : {
        // height: '90vh',
        // boxSizing : 'border-box',
    },
    productsCount : {
        //border : '1px solid red',
        marginLeft : '10px',
        height : '25px', // dodato 25px na vrh !
        display : 'flex',
        justifyContent : 'flex-start',
        alignItems : 'center'
    },
    productsList: {
        position : 'relative',
        boxSizing : 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        //paddingTop: '15px',
        gap: '10px 0px',
        height: 'calc(90vh - 95px)', // oduzeto 95px tj 25 koji su dodati gore kao height od productsCount i 70 koji su dodati kao marginBottom u productPageMainDiv
        overflowX: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    },
    topRef : {
        position : 'absolute', 
        top : '0', 
        border : '2px solid red', 
        height : '100%'
    },
    singleCategory: {
        //border : '1px solid purple',
        color: '#a7aead',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '5px',
        height: '40px',
        borderBottom: '1px solid #efefef',

    },
    activeCategory: {
        // borderLeft : '4px solid #f86773',
        boxShadow: 'inset 4px 0 0 0px #ec1767',
        backgroundColor: '#fae0e1',
        color: 'black',
        //borderBottom: 'none',
    },
    
    categoryIcon : {
        width : '20px',
        height : '20px'
    },
    status : {
        //border : '2px solid red',
        marginTop : '30px',
        width : '90%',
        margin : 'auto'
    },
}));

export default useStyles;