import {
    Button, Divider, Typography, Grid, TextField, InputAdornment, LinearProgress, DialogContent,
    Dialog, DialogActions, DialogTitle, Alert, AlertTitle
} from '@mui/material'
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../../store/reduxApi';
import { useContext } from 'react';
import { GlobalContext } from '../../App';
import useStyles from './style';
import { FaPencilAlt as EditIcon } from 'react-icons/fa';
import { MdArrowBack as BackIcon } from 'react-icons/md';
import { AiOutlineLock as LockIcon, AiOutlineInfoCircle as InfoIcon, AiOutlineShoppingCart as CartIcon } from 'react-icons/ai';
import { GoLocation as LocationIcon } from 'react-icons/go';
import { SlLogout as LogoutIcon } from 'react-icons/sl';
import { BsChevronRight as RightIcon } from 'react-icons/bs';
import { BsChevronDown as DownIcon } from 'react-icons/bs';
import { useRegisterUserMutation } from '../../store/reduxApi';

const cities = ['Podgorica', 'Nikšić', 'Pljevlja', 'Bijelo Polje', 'Cetinje', 'Bar', 'Herceg Novi', 'Berane', 'Budva', 'Ulcinj', 'Tivat', 'Rožaje', 'Kotor', 'Danilovgrad', 'Mojkovac', 'Plav', 'Kolašin', 'Žabljak', 'Plužine', 'Andrijevica', '	Šavnik'];


const menuItems = [
    {
        title: 'Promjena šifre',
        icon: <LockIcon />
    },
    {
        title: 'O aplikaciji',
        icon: <InfoIcon />
    },
    {
        title: 'Lista adresa',
        icon: <LocationIcon />
    },
    {
        title: 'Uslovi kupovine',
        icon: <CartIcon />
    },
]

const UserPage = () => {

    const { loggedUserData, setCurrentPage } = useContext(GlobalContext);

    const [currentUserPageToDisplay, setCurrentUserPageToDisplay] = React.useState(0);

    const classes = useStyles();

    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = React.useState(false);

    const [registerUser, registerUserResult] = useRegisterUserMutation();
    const { isLoading, isError, error, data: registerUserResultData } = registerUserResult;

    const [formErrors, setFormErrors] = React.useState({});
    const [isSubmit, setIsSubmit] = React.useState(false);

    const [editUserData, setEditUserData] = React.useState({});
    const [changePasswordData, setChangePasswordData] = React.useState({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    })

    const [logout, logoutResult] = useLogoutMutation();
    const { data, isSuccess } = logoutResult;

    const handleLogout = () => {
        logout();
    };

    const handleChange = (e) => {
        setEditUserData({
            ...editUserData,
            [e.target.name]: e.target.value
        });
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    React.useEffect(() => {
        if (isSuccess) {
            navigate('/');
            localStorage.setItem('currentPage', JSON.stringify(0));
                setCurrentPage(0);
        }
        if (JSON.stringify(loggedUserData) !== '{}') {
            setEditUserData(loggedUserData);
        }
    }, [loggedUserData, isSuccess]);

    const ShowError = () => {
        return (
            <Alert variant='filled' severity="error" style={{ margin: 'auto', marginBottom: '15px', width: '80%' }}>
                <AlertTitle>Neuspješno</AlertTitle>
                Došlo je do greške u ažuriranju profila pokušajte ponovo
            </Alert>
        )
    };

    const ShowSuccess = () => {
        return (
            <Alert variant='filled' severity="success" style={{ margin: 'auto', marginBottom: '15px', width: '80%' }}>
                <AlertTitle>Uspješno</AlertTitle>
                Uspješno ste ažurirali profil
            </Alert>
        )
    };

    const ShowLoading = () => {
        return (
            <div className={classes.registerLoading}>
                <LinearProgress
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#f86673'
                        }
                    }}
                />
            </div>
        )
    };

    const PickCityDialog = () => {
        return (
            <Dialog open={openDialog} onClose={handleClose} fullWidth>
                <DialogTitle>Odaberi grad: </DialogTitle>
                <DialogContent className={classes.cityDialog}>
                    {
                        cities.map((city, index) => {
                            return (
                                <Typography
                                    variant='subtitle2'
                                    key={index}
                                    onClick={() => {
                                        setEditUserData({
                                            ...editUserData, city
                                        });
                                        handleClose();
                                    }}>
                                    {city}
                                </Typography>
                            )
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    };

    const getCurrentPage = () => {
        switch (currentUserPageToDisplay) {
            case 0: return <UserMainPage />
            case 1: return <UserEditDataPage />
            case 2: return <UserChangePasswordPage />
            case 3: return <AboutAppPage />
            case 4: return <LocationsPage />
            case 5: return <ConditionsOfPurchase />
            default: return <UserMainPage />
        }
    };

    const handleMenuPageSwitch = (index) => {
        switch (index) {
            case 0: setCurrentUserPageToDisplay(2); break;
            case 1: setCurrentUserPageToDisplay(3); break;
            case 2: setCurrentUserPageToDisplay(4); break;
            case 3: setCurrentUserPageToDisplay(5); break;
            default: setCurrentUserPageToDisplay(0);
        };
    };

    const handleRegisterSubmit = (e) => {
        e.preventDefault()
    };

    const UserEditDataPage = () => {
        return (
            <>
                <div className={classes.userPageNav}>
                    <BackIcon style={{ fontSize: '22px', marginLeft: '12px' }} onClick={() => { setCurrentUserPageToDisplay(0); }} />
                </div>
                <div className={classes.userPageData}>
                    <Typography style={{ fontSize: '26px', fontWeight: 'bold' }}>
                        Moji podaci
                    </Typography>
                </div>
                <div className={classes.editUserDataBigDiv}>
                    <form onSubmit={handleRegisterSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className={classes.registerGridItem}>
                                    <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Ime:</Typography>
                                    <TextField
                                        autoFocus
                                        autoComplete='off'
                                        placeholder='Unesite ime*'
                                        type='text'
                                        value={editUserData.name}
                                        name='name'
                                        onChange={handleChange}
                                        size='small'
                                        fullWidth
                                        variant='outlined'
                                        className={classes.registerTextField}
                                        error={formErrors.name ? true : false}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                "& fieldset": {
                                                    border: formErrors.name ? '1px solid black' : 'none'
                                                }
                                            },
                                        }}
                                        helperText={formErrors.name}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.registerGridItem}>
                                    <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Prezime:</Typography>
                                    <TextField

                                        autoComplete='off'
                                        placeholder='Unesite prezime*'
                                        type='text'
                                        value={editUserData.surname}
                                        name='surname'
                                        onChange={handleChange}
                                        size='small'
                                        fullWidth
                                        variant='outlined'
                                        className={classes.registerTextField}
                                        error={formErrors.surname ? true : false}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                "& fieldset": {
                                                    border: formErrors.surname ? '1px solid black' : 'none'
                                                }
                                            },
                                        }}
                                        helperText={formErrors.surname}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.registerGridItem}>
                                    <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Email:</Typography>
                                    <TextField
                                        autoComplete='off'
                                        placeholder='Unesite email'
                                        type='email'
                                        value={editUserData.email}
                                        name='email'
                                        onChange={handleChange}
                                        size='small'
                                        fullWidth
                                        variant='outlined'
                                        className={classes.registerTextField}
                                        error={formErrors.email ? true : false}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                "& fieldset": {
                                                    border: formErrors.email ? '1px solid black' : 'none'
                                                }
                                            },
                                        }}
                                        helperText={formErrors.email}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.registerGridItem}>
                                    <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Broj mobilnog telefona:</Typography>
                                    <TextField

                                        autoComplete='off'
                                        placeholder='69123456'
                                        type='number'
                                        value={editUserData.phone}
                                        name='phone'
                                        onChange={handleChange}
                                        size='small'
                                        fullWidth
                                        variant='outlined'
                                        className={classes.registerTextField}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">+382</InputAdornment>
                                        }}
                                        sx={{
                                            '& .MuiInputAdornment-root': {
                                                backgroundColor: '#BDC3C7',
                                                padding: '20px',
                                                borderTopLeftRadius: '4px',
                                                borderBottomLeftRadius: '4px'
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                "& fieldset": {
                                                    border: formErrors.phone ? '1px solid black' : 'none'
                                                },
                                                paddingLeft: '0px',
                                                paddingRight: '0px',
                                            }
                                        }}
                                        error={formErrors.phone ? true : false}
                                        helperText={formErrors.phone}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.registerGridItem}>
                                    <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Grad:</Typography>
                                    <TextField

                                        onClick={() => { setOpenDialog(true); }}
                                        autoComplete='off'
                                        placeholder='Odaberi grad*'
                                        value={editUserData.city}
                                        name='email'
                                        size='small'
                                        fullWidth
                                        variant='outlined'
                                        className={classes.registerTextField}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end"><DownIcon /></InputAdornment>,
                                            readOnly: true
                                        }}
                                        error={formErrors.city ? true : false}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                "& fieldset": {
                                                    border: formErrors.city ? '1px solid black' : 'none'
                                                }
                                            },
                                        }}
                                        helperText={formErrors.city}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '10px' }}>
                                {
                                    isError
                                        ?
                                        <ShowError />
                                        :
                                        null
                                }
                                {
                                    typeof registerUserResultData !== 'undefined'
                                        ?
                                        registerUserResultData.success
                                            ?
                                            <ShowSuccess />
                                            :
                                            null
                                        :
                                        null
                                }
                                {
                                    isLoading
                                        ?
                                        <ShowLoading />
                                        :
                                        <Button
                                            style={{ backgroundColor: '#f86673' }}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            disableElevation >
                                            Sačuvaj izmjene
                                        </Button>
                                }
                            </Grid>

                        </Grid>
                    </form>

                </div>
                <PickCityDialog />
            </>
        )
    };

    const UserChangePasswordPage = () => {
        return (
            <>
                <div className={classes.userPageNav}>
                    <BackIcon style={{ fontSize: '22px', marginLeft: '12px' }} onClick={() => { setCurrentUserPageToDisplay(0); }} />
                </div>
                <div className={classes.userPageData}>
                    <Typography style={{ fontSize: '26px', fontWeight: 'bold' }}>
                        Promjena šifre
                    </Typography>
                    <div style={{ marginTop: '5px' }}>
                        <Typography style={{ fontSize: '14px', color: 'gray' }}>Kreirajte novu šifru</Typography>
                    </div>
                </div>
                <div className={classes.editUserDataBigDiv}>
                    <form onSubmit={handleRegisterSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className={classes.registerGridItem}>
                                    <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Stara šifra:</Typography>
                                    <TextField
                                        autoComplete='off'
                                        placeholder='Unesite staru šifru*'
                                        type='password'
                                        value={changePasswordData.oldPassword}
                                        name='name'
                                        onChange={(e) => {
                                            setChangePasswordData({ ...changePasswordData, oldPassword: e.target.value });
                                        }}
                                        size='small'
                                        fullWidth
                                        variant='outlined'
                                        className={classes.registerTextField}
                                        error={formErrors.name ? true : false}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                "& fieldset": {
                                                    border: formErrors.name ? '1px solid black' : 'none'
                                                }
                                            },
                                        }}
                                        helperText={formErrors.name}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.registerGridItem}>
                                    <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Nova šifra:</Typography>
                                    <TextField
                                        autoComplete='off'
                                        placeholder='Unesite novu šifru*'
                                        type='password'
                                        value={changePasswordData.newPassword}
                                        name='name'
                                        onChange={(e) => {
                                            setChangePasswordData({ ...changePasswordData, newPassword: e.target.value });
                                        }}
                                        size='small'
                                        fullWidth
                                        variant='outlined'
                                        className={classes.registerTextField}
                                        error={formErrors.name ? true : false}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                "& fieldset": {
                                                    border: formErrors.name ? '1px solid black' : 'none'
                                                }
                                            },
                                        }}
                                        helperText={formErrors.name}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.registerGridItem}>
                                    <Typography style={{ marginLeft: '8px' }} variant='subtitle1'>Ponovite novu šifru:</Typography>
                                    <TextField
                                        autoComplete='off'
                                        placeholder='Unesite novu šifru*'
                                        type='password'
                                        value={changePasswordData.confirmNewPassword}
                                        name='name'
                                        onChange={(e) => {
                                            setChangePasswordData({ ...changePasswordData, confirmNewPassword: e.target.value });
                                        }}
                                        size='small'
                                        fullWidth
                                        variant='outlined'
                                        className={classes.registerTextField}
                                        error={formErrors.name ? true : false}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                "& fieldset": {
                                                    border: formErrors.name ? '1px solid black' : 'none'
                                                }
                                            },
                                        }}
                                        helperText={formErrors.name}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '10px' }}>
                                {
                                    isError
                                        ?
                                        <ShowError />
                                        :
                                        null
                                }
                                {
                                    typeof registerUserResultData !== 'undefined'
                                        ?
                                        registerUserResultData.success
                                            ?
                                            <ShowSuccess />
                                            :
                                            null
                                        :
                                        null
                                }
                                {
                                    isLoading
                                        ?
                                        <ShowLoading />
                                        :
                                        <Button
                                            style={{ backgroundColor: '#f86673' }}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            disableElevation >
                                            Sačuvaj izmjene
                                        </Button>
                                }
                            </Grid>

                        </Grid>
                    </form>
                </div>
            </>
        )
    };

    const AboutAppPage = () => {
        return (
            <>
                <div className={classes.userPageNav}>
                    <BackIcon style={{ fontSize: '22px', marginLeft: '12px' }} onClick={() => { setCurrentUserPageToDisplay(0); }} />
                </div>
            </>
        )
    };


    const LocationsPage = () => {
        return (
            <>
                <div className={classes.userPageNav}>
                    <BackIcon style={{ fontSize: '22px', marginLeft: '12px' }} onClick={() => { setCurrentUserPageToDisplay(0); }} />
                </div>
            </>
        )
    };

    const ConditionsOfPurchase = () => {
        return (
            <>
                <div className={classes.userPageNav}>
                    <BackIcon style={{ fontSize: '22px', marginLeft: '12px' }} onClick={() => { setCurrentUserPageToDisplay(0); }} />
                </div>
            </>
        )
    };


    const UserMainPage = () => {
        return (
            <>
                <div className={classes.userPageNav}>
                    <BackIcon style={{ fontSize: '22px', marginLeft: '12px' }} onClick={() => { navigate(-1); }} />
                    <EditIcon style={{ fontSize: '22px', marginRight: '12px' }} onClick={() => { setCurrentUserPageToDisplay(1); }} />
                </div>
                <div className={classes.userPageData}>
                    <Typography style={{ fontSize: '26px', fontWeight: 'bold' }}>
                        {loggedUserData.name} {loggedUserData.surname}
                    </Typography>
                    <div style={{ marginTop: '5px' }}>
                        <Typography style={{ fontSize: '14px', color: 'gray' }}>{loggedUserData.email}</Typography>
                        <Typography style={{ fontSize: '14px', color: 'gray' }}>+382{loggedUserData.phone}</Typography>
                    </div>
                    <div className={classes.userPageIsVerified}>
                        {
                            !loggedUserData.is_verified
                                ?
                                [<Typography style={{ fontSize: '14px', color: '#DC3023' }}>Profil nije verifikovan &#x2715;</Typography>,
                                <Typography style={{ fontSize: '14px', color: '#22A7F0', textDecoration: 'underline' }}>Pošalji kod</Typography>]
                                :
                                <Typography style={{ fontSize: '14px', color: '#006442' }}>Profil verifikovan &#x2713;</Typography>

                        }
                    </div>
                </div>
                <div className={classes.userPageMenu}>
                    {
                        menuItems.map((item, index) => {
                            return (
                                [
                                    <div
                                        onClick={() => { handleMenuPageSwitch(index); }}
                                        className={classes.userPageMenuItem}
                                        key={index}>
                                        <div className={classes.userPageMenuItemIconTitle}>
                                            {item.icon}
                                            <div>
                                                <Typography style={{ fontSize: '14px' }}>
                                                    {item.title}
                                                </Typography>
                                            </div>
                                        </div>
                                        <RightIcon />
                                    </div>,
                                    <Divider />
                                ]
                            )
                        })
                    }
                </div>
                <div className={classes.userPageLogoutDiv} onClick={handleLogout}>
                    <LogoutIcon style={{ marginRight: '10px' }} />
                    <Typography>Odjavi se</Typography>
                </div>
            </>
        )
    };

    return (
        <div className={classes.userPageBigDiv}>
            {getCurrentPage()}
        </div>
    );
};

export default UserPage