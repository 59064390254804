import React from 'react';
import useStyles from './style';
import {
    Button, Alert, AlertTitle, LinearProgress,
    DialogContent, DialogActions,
    Dialog, DialogTitle, Typography
} from '@mui/material';
import { useDeleteSlideMutation } from '../../../store/reduxApi';

const DeleteSlideDialog = ({ open, setOpen, slideId, ShowLoading, ShowError, ShowSuccess }) => {

    const classes = useStyles();

    const [deleteSlide, deleteSlideResult] = useDeleteSlideMutation();
    const { isLoading, isError, isSuccess, reset } = deleteSlideResult;

    const handleClose = () => {
        setOpen({
            open: false,
            slideId: false
        });
        reset();
    };

    const handleDeleteNotification = () => {
        if (slideId) deleteSlide({ id: slideId });
    };

    const ShowDeleteState = () => {
        if(isLoading) return <ShowLoading/>
        if(isError) return <ShowError errorText='Neuspješno brisanje slajda pokušajte ponovo'/>
        if(isSuccess) return <ShowSuccess successText='Slajd uspješno obrisan'/>
    };

    return (
        <Dialog
            fullWidth
            onClose={handleClose}
            open={open}>
            <DialogTitle>
                Brisanje slajda
            </DialogTitle>
            <DialogContent>

                <Typography>Da li ste sigurni da želite da obrišete slajd ?</Typography>

            </DialogContent>

            <ShowDeleteState/>

            <DialogActions style={{ padding: '10px' }}>
                <Button
                    variant='outlined'
                    disableElevation
                    onClick={handleClose}
                >
                    Odustani
                </Button>
                <Button
                    disabled={isLoading}
                    onClick={handleDeleteNotification}
                    style={{ backgroundColor: '#DC3023' }}
                    variant='contained'
                    disableElevation
                >
                    Obriši
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteSlideDialog;