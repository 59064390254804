import React from 'react';
import useStyles from './style';
import {
  Button, Alert, AlertTitle, LinearProgress,
  DialogContent, DialogActions,
  Dialog, DialogTitle, Typography
} from '@mui/material';
import { useDeleteProductMutation } from '../../../store/reduxApi';
import ShowLoading from '../../Alerts/ShowLoading';
import ShowError from '../../Alerts/ShowError';
import ShowSuccess from '../../Alerts/ShowSuccess';

const DeleteProductDialog = ({ open, setOpen, product }) => {

  const classes = useStyles();

  const [deleteProduct, deleteProductResult] = useDeleteProductMutation();
  const { isLoading, isError, isSuccess, reset } = deleteProductResult;

  const handleClose = () => {
    setOpen({
      open: false,
      product: false
    });
    reset();
  };

  const handleDeleteProduct = () => {
    if (product) deleteProduct({ id: product.id });
  };

  const ShowDeleteState = () => {
    if (isLoading) return <ShowLoading />
    if (isError) return <ShowError errorText='Neuspješno brisanje proizvoda' />
    if (isSuccess) return <ShowSuccess successText='Proizvod uspješno obrisan' />
  };

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      open={open}>
      <DialogTitle>
        Brisanje proizvoda
      </DialogTitle>
      <DialogContent>

        <Typography>
          Da li ste sigurni da želite da obrišete proizvod <strong>{product.name}</strong>?
        </Typography>

      </DialogContent>

      <ShowDeleteState />

      <DialogActions style={{ padding: '10px' }}>
        <Button
          variant='outlined'
          disableElevation
          onClick={handleClose}
        >
          Odustani
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleDeleteProduct}
          style={{ backgroundColor: '#DC3023' }}
          variant='contained'
          disableElevation
        >
          Obriši
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProductDialog;