import React from 'react';
import { Outlet } from 'react-router-dom';
import Navigation from './components/Navigation/Navigation';
import BottomBar from './components/Bottom Bar/BottomBar';
import { useNavigate } from 'react-router-dom';

const WithNavBar = ({ auth }) => {

    const navigate = useNavigate();

    return (
        <>
            <Navigation auth={auth} navigate={navigate} />
                <Outlet/>
            <BottomBar auth={auth} navigate={navigate} />
        </>
    );
};

export default WithNavBar;