// CUSTOM HOOK
import { useCheckAuthQuery } from "../store/reduxApi";

export const useAuth = () => {

    const { data } = useCheckAuthQuery();

    return ({
        auth: data?.success,
        isAdmin : data?.isAdmin
    });
};
