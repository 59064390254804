
export const registerValidate = (data) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if(data.name.length === 0){
        errors.name = 'Popunite polje';
    };
    if(data.surname.length === 0){
        errors.surname = 'Popunite polje';
    };
    if(data.phone.length < 8 || data.phone.length > 8 ){
        errors.phone = 'Telefon mora biti formata 69123456';
    };
    if(data.city.length === 0){
        errors.city = 'Popunite polje';
    };
    if(data.email.length > 0){
        if(!emailRegex.test(data.email)){
            errors.email = 'Neispravan format email-a'
        }
    };
    if(data.password.length < 6){
        errors.password = 'Šifra mora imati minimun 6 karaktera';
    };
    if(data.confirmPassword.length === 0 || data.confirmPassword !== data.password){
        errors.confirmPassword = 'Šifre moraju biti iste';
    };
    return errors;
};

export const loginValidate = (data) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if(data.emailOrPhone.length === 0){
        errors.emailOrPhone = 'Popunite polje';
    }
    if(data.password.length < 6){
        errors.password = 'Šifra mora imati minimun 6 karaktera';
    };
    return errors;
};