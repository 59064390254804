import React, { useContext } from 'react';
import { Box, AppBar, Toolbar, IconButton, Typography, Button, Badge, Icon, Menu, MenuItem, Fade, TextField, InputAdornment } from '@mui/material';
import { BiMenu } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/lzshop.png';
import useStyles from './style';
import { VscBell as Bell, VscMenu as MenuIcon } from 'react-icons/vsc';
import { RxMagnifyingGlass as MagnifyIcon } from 'react-icons/rx';
import { useLazyCheckForNotificationsQuery } from '../../store/reduxApi';
import { GlobalContext } from '../../App';
import { BsBookmarkHeart as SaveProductIcon, BsBookmarkHeartFill as UnsaveProductIcon } from 'react-icons/bs';
import { BsChevronDoubleLeft as BackIcon } from 'react-icons/bs'
import { AiOutlineClose as DeleteIcon } from 'react-icons/ai';

const menuItems = ['Početna', 'Kontakt', 'Lokacije', 'Profil', 'Korpa'];

const Navigation = ({ auth = null, navigate }) => {

    const {
        currentPage,
        setCurrentPage,
        searchProductWord,
        setSearchProductWord,
        savedProducts,
        setSavedProducts,
        currentProduct
    } = useContext(GlobalContext);

    const classes = useStyles();

    const location = useLocation();
    const previousPath = location?.state?.previousPath;

    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    //Use Lazy Query koristimo kad zelimo da GET nesto pod uslovom npr na klik dugmeta zato odje dobijamo trigger koji 
    //zapocinje GET metodu a drugi parametar je desktruktiran result 

    const [trigger, { data }] = useLazyCheckForNotificationsQuery();

    const handleClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    React.useEffect(() => {
        if (auth) trigger();
    }, [auth]);

    const checkSavedProduct = () => {
        const index = savedProducts.findIndex(product => product === currentProduct);
        return index;
    };

    // 0 home
    // 1 products
    // 2 product
    // 3 cart

    const goBack = () => {
        if (typeof previousPath !== 'undefined') {
            localStorage.setItem('currentPage', JSON.stringify(previousPath));
            setCurrentPage(previousPath);
            switch (previousPath) {
                case 0: navigate('/'); break;
                case 1: navigate('/products'); break;
                case 3: navigate('/cart'); break;
                default: navigate('/')
            }
            // previousPath === 0 ? navigate('/') : navigate('/products');
        }
    };

    return (

        <AppBar position='sticky' elevation={1} >
            <Toolbar
                sx={{
                    // paddingLeft : '10px',
                    // paddingRight : '10px'
                }}
                className={classes.toolbar}>

                {
                    currentPage === 2
                        ?
                        <IconButton onClick={goBack}>
                            <BackIcon className={classes.navIcon} />
                        </IconButton>
                        :
                        <IconButton onClick={handleClick}>
                            <MenuIcon className={classes.navIcon} />
                        </IconButton>
                }

                <Menu
                    className={classes.menu}
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                    onClick={handleClose}
                    TransitionComponent={Fade}
                >
                    {
                        menuItems.map((item, index) => {
                            return <MenuItem
                                key={index}
                                style={{ fontSize: '14px' }}
                                onClick={() => {
                                    navigate('/');
                                    if (index === 0) {
                                        localStorage.setItem('currentPage', JSON.stringify(0));
                                        setCurrentPage(0);
                                    }
                                }}>
                                {item}
                            </MenuItem>
                        })
                    }
                </Menu>


                {
                    currentPage === 1
                        ?
                        <TextField
                            sx={{
                                marginLeft: '10px',
                                marginRight: '10px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '50px',
                                    height: '30px',
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#f86673"
                                    }
                                },
                            }}
                            fullWidth
                            inputProps={{ style: { fontSize: '12px' } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MagnifyIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    searchProductWord.length
                                        ?
                                        <InputAdornment position='end' onClick={() => { setSearchProductWord(''); }}>
                                            <DeleteIcon fontSize='20' />
                                        </InputAdornment>
                                        :
                                        null
                                )
                            }}
                            placeholder='Pretraži proizvode'
                            value={searchProductWord}
                            onChange={(e) => { setSearchProductWord(e.target.value); }}
                            size="small"
                            autoComplete='off'
                        />
                        :
                        <img
                            onClick={() => {
                                localStorage.setItem('currentPage', JSON.stringify(0));
                                setCurrentPage(0);
                                navigate('/');
                            }}
                            src={logo}
                            alt='Logo'
                            width={145} />
                }

                {
                    currentPage === 2
                        ?
                        checkSavedProduct() !== -1
                            ?
                            <IconButton onClick={() => {
                                const newSavedProducts = savedProducts.filter(product => product !== currentProduct);
                                localStorage.setItem('savedProducts', JSON.stringify(newSavedProducts));
                                setSavedProducts([...newSavedProducts]);
                            }} >
                                <UnsaveProductIcon

                                    style={{ color: '#ec1767' }} />
                            </IconButton>
                            :
                            <IconButton onClick={() => {
                                const newSavedProducts = [...savedProducts, currentProduct];
                                setSavedProducts([...newSavedProducts]);
                                localStorage.setItem('savedProducts', JSON.stringify(newSavedProducts));
                            }}>
                                <SaveProductIcon

                                    style={{ color: '#ec1767' }} />
                            </IconButton>
                        :
                        <IconButton onClick={() => { navigate('/notifications'); }}>
                            <Badge
                                sx={{
                                    "& .MuiBadge-dot": {
                                        backgroundColor: '#f86673',
                                    }
                                }}
                                variant={data?.newNotification ? 'dot' : 'standard'}
                            >
                                <Bell className={classes.navIcon} />
                            </Badge>
                        </IconButton>
                }

            </Toolbar>
        </AppBar >

    )
}

export default Navigation