import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    bigSliderDiv: {
        width: '92%',
        margin: 'auto',
        marginTop: '25px',
        //border : '2px solid red',
    },
    bigSlider: {
        //border : '3px solid black'
    },
    singleSlide: {
        position: 'relative',
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        // backgroundPosition: 'center center',
        //height: '130px',
    },
    sliderCircles: {
        position: 'absolute',
        left: '10px',
        bottom: '10px',
        transition: 'all 1s ease-out'
    },
    sliderButton: {
        position: 'absolute',
        right: '10px',
        bottom: '10px'
    },
    circle: {
        fontSize: '14px',
        cursor: 'pointer',
        color: 'white',
        //background: 'RGBA(255,255,255,0.5)',
        borderRadius: '50%'
    },
}));

export default useStyles;