import React from 'react';
import useStyles from './style';
import {
    Button, TextField,
    Grid, Alert, AlertTitle, LinearProgress,
    DialogContent, DialogActions,
    Dialog, DialogTitle, Typography
} from '@mui/material';
import { BiImageAdd as AddImageIcon } from 'react-icons/bi';
import { useAddSlideMutation } from '../../../store/reduxApi';

const slideInitialData = {
    link: 'https://www.w3schools.com/nodejs/nodejs_uploadfiles.asp',
    slideImage: ''
}

const AddSlideDialog = ({ open, setOpen, ShowLoading, ShowError, ShowSuccess }) => {

    const classes = useStyles();

    const inputFileRef = React.useRef();

    const [slideData, setSlideData] = React.useState(slideInitialData);

    const [previewImage, setPreviewImage] = React.useState(null);

    const handleImageChange = (e) => {
        const imgFile = e.target.files[0];
        if (imgFile) {
            setPreviewImage(URL.createObjectURL(imgFile));
            setSlideData({
                ...slideData,
                slideImage: imgFile
            });
        };
    };

    const [addSlide, addSlideResult] = useAddSlideMutation();
    const { isLoading, isError, isSuccess, data , reset } = addSlideResult;

    const handleClose = () => {
        setOpen(false);
        setSlideData(slideInitialData);
        setPreviewImage(null);
        reset();
    };

    const handleAddNewSlide = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('link',slideData.link);
        formData.append('slideImage',slideData.slideImage);
        if(slideData.slideImage){
            addSlide(formData);
        }else {
            alert('Morate dodati sliku');
        }
    };
    return (
        <Dialog
            fullWidth
            onClose={handleClose}
            open={open}>
            <DialogTitle>
                Dodaj novi slajd
            </DialogTitle>
            <DialogContent
                sx={{
                    '&.MuiDialogContent-root': {
                        padding: '12px 10px'
                    }
                }}
            >
                <form onSubmit={handleAddNewSlide} id="myform">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography>{previewImage ? 'Slika dodata' : 'Dodajte sliku'}</Typography>
                            <div
                                style={{ backgroundImage: `url('${previewImage}')` }}
                                onClick={() => { inputFileRef.current.click(); }}
                                className={classes.addSlidePhotoDiv}>
                                {
                                    !previewImage
                                        ?
                                        <AddImageIcon className={classes.addImageIcon} />
                                        :
                                        null
                                }
                                <input
                                    accept='image/*'
                                    onChange={handleImageChange}
                                    type="file"
                                    hidden
                                    ref={inputFileRef}
                                />
                            </div>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete='off'
                                label='Unesite link'
                                type='text'
                                value={slideData.link}
                                onChange={(e) => { setSlideData({ ...slideData, link: e.target.value }) }}
                                size='small'
                                fullWidth
                                variant='outlined'
                            />
                        </Grid>

                    </Grid>
                </form>

            </DialogContent>
            
            { isError ? <ShowError errorText='Neuspješno dodavanje slajda' /> : null }
            { isSuccess ? <ShowSuccess successText='Uspješno dodavanje slajda' /> : null }
            { isLoading ? <ShowLoading /> : null }

            <DialogActions style={{ padding: '10px' }}>
                <Button
                    variant='outlined'
                    disableElevation
                    onClick={handleClose}
                >
                    Odustani
                </Button>
                <Button
                    disabled={isLoading}
                    form='myform'
                    type='submit'
                    style={{ backgroundColor: '#26A65B' }}
                    variant='contained'
                    disableElevation
                >
                    Potvrdi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddSlideDialog;