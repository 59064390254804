import React, { useContext } from 'react';
import useStyles from './style';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetParentCategoriesQuery } from '../../store/reduxApi';
import allCategoriesIcon from '../../assets/allCategories.svg';
import ShowError from '../Alerts/ShowError';
import ShowLoading from '../Alerts/ShowLoading';
import { GlobalContext } from '../../App';

const colors = ['#C3272B', '#19B5FE', '#003171', '#26A65B', '#03A678', '#FFB61E', '#006442', '#FFB3A7', '#48929B', '#006442', '#FFB3A7', '#48929B'];

const SmallSlider = () => {

    const { setCurrentPage } = useContext(GlobalContext);

    const classes = useStyles();

    const navigate = useNavigate();

    const { isLoading, isError, isSuccess, data = {} } = useGetParentCategoriesQuery();

    const AllCategories = () => {
        const title = 'Sve kategorije'
        return (
            <div
                onClick={() => { 
                    navigate('/products');
                    localStorage.setItem('currentPage', JSON.stringify(1));
                    setCurrentPage(1); 
                }}
                className={classes.smallSliderOneItem}>
                <div
                    className={classes.smallSliderIconDiv}
                    style={{ background: `radial-gradient(circle at top left, rgba(255, 255, 255, 0.15) 55%, transparent 20%), linear-gradient(#F62459,#F62459)` }}
                >
                    <img
                        className={classes.categoryIcon}
                        src={allCategoriesIcon}
                        alt='Category Icon' />
                </div>

                {title.split(' ').map((word, index) => {
                    return <Typography key={index} style={{ fontSize: '10px', marginTop: index > 0 ? '-5px' : null }}>{word}</Typography>;
                })}

            </div>
        )
    };

    return (
        <div className={classes.smallSlider}>
            {isLoading ? <ShowLoading /> : null}
            {isError ? <ShowError errorText='Neuspješno prikazivanje kategorija' /> : null}
            <div className={classes.smallSliderDiv}>
                {isSuccess ? <AllCategories /> : null}
                {
                    data?.categories?.map((category, index) => {
                        return (
                            <div
                                onClick={() => { 
                                    navigate(`/products?category=${category.seo}`);
                                    localStorage.setItem('currentPage', JSON.stringify(1));
                                    setCurrentPage(1); 
                                }}
                                key={index}
                                className={classes.smallSliderOneItem}>
                                <div
                                    className={classes.smallSliderIconDiv}
                                    style={{ background: `radial-gradient(circle at top left, rgba(255, 255, 255, 0.15) 55%, transparent 20%), linear-gradient(${colors[index]},${colors[index]})` }}
                                >
                                    <img
                                        className={classes.categoryIcon}
                                        src={process.env.REACT_APP_UPLOADS_PATH + category.icon}
                                        alt='Category Icon' />
                                </div>

                                {category.name.split(' ').map((word, index) => {
                                    return <Typography key={index} style={{ fontSize: '10px', marginTop: index > 0 ? '-5px' : null }}>{word}</Typography>;
                                })}

                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default SmallSlider