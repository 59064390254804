import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    bigDiv: {
        //border : '2px solid red',
        width: '90%',
        margin: 'auto',
        marginTop: '20px',
    },
    addButtonDiv: {

    },
    searchDiv: {
        //border : '2px solid red',
        margin: '20px 0px 15px 0px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '10px'
    },
    catDateFilter: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gap: '10px',
    },
    addProductNavBar: {
        position: 'relative',
        backgroundColor: '#f54554',
        color: 'white'
    },
    productGallery: {
        marginTop: '10px',
        width: '100%',
        minHeight: '26vh',
        // maxHeight: '300px',
        // overflowY : 'scroll',
        // overflowX : 'hidden',
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23BDC3C7FF' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '5px'
    },

    singleImage: {
        //height: 'calc(40vw - 5px)',
        //width: '100%',
        // height : '100px',
        height: '13vh',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '5px',
        border: '1px solid #BDC3C7',
    },
    image: {
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        position: 'relative',
        width: '100%',
        
        //height : 'calc(23vh - 15px)'
    },
    dragImage: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    deleteImage: {
        // border : '1px solid red',
        position: 'absolute',
        top: '5px',
        left: '5px',
    },
    dialog: {
        "& .MuiPickersModal-dialogRoot": {
            width: "90%",
            maxWidth: '400px'
        }
    },
    productsList: {
        display : 'grid',
        gridTemplateColumns : '1fr 1fr 1fr',
        width : '96%',
        margin : 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        placeItems: 'center'
    },
    productVariations : {
        //border : '2px solid red',
        display : 'flex',
        flexDirection : 'column',
        gap : '10px'
    },
    variation : {
        border : '1px solid #D2D7D3',
        display : 'grid',
        gridTemplateColumns : '3fr 2fr',
        justifyContent : 'space-between',
        alignItems : 'center',
        marginBottom : '5px',
        paddingTop : '5px',
        paddingBottom : '5px',
        borderRadius : '6px'
    },
    variationData : {
        //border : '2px solid tomato'
        marginLeft : '5px'
    },
    variationButtons : {
        //border : '2px solid pink',
        display : 'grid',
        gridTemplateColumns : '1fr 1fr',
        gap : '10px',
        marginRight : '5px'
    }
}));

export default useStyles;