import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    notificationsDiv: {
        
    },
    backIconDiv: {
        width: '96%',
        margin: 'auto'
    },
    notificationsTitle: {
        width: '90%',
        margin: 'auto'
    },
    notificationsList: {
        width: '90%',
        margin: 'auto',
        marginTop: '15px',
        marginBottom: '15px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '10px'
    },
    singleNotificationDiv: {
        backgroundColor: 'white',
        borderRadius: '6px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '3px',
        padding: '8px'
    },
    notificationStatusAndTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    notificationDescription: {

    },
    notificationDate: {

    },
    pageNav : {
        width: '100%',
        margin: 'auto',
        height: '45px',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        marginBottom: '20px',
        backgroundColor: '#f86673',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color : 'white'
    },
}));

export default useStyles;
