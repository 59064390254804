import React from 'react';
import useStyles from './style';
import {
  Button, TextField, Grid, DialogContent, DialogActions,
  Dialog, DialogTitle, Typography, InputLabel, FormControl, Select, MenuItem, IconButton
} from '@mui/material';
import { useAddSpecificationMutation } from '../../../store/reduxApi';
import { FaPlus as AddIcon, FaTrashAlt as DeleteIcon } from 'react-icons/fa';

const specificationInitialData = {
  name: '',
  type: '',
  placeholder: '',
  measure: '',
  allowedCharacters: '',
  selectOptions: []
};

const allowedCharactersSelect = [
  {
    name: 'Broj',
    value: 'Number'
  },
  {
    name: 'Prirodan broj',
    value: 'Natural number'
  },
  {
    name: 'Slova (a-z)',
    value: 'Letters'
  },
  {
    name: 'Sve navedeno',
    value: 'All'
  },
]

const AddSpecDialog = ({ open, setOpen, ShowLoading, ShowError, ShowSuccess }) => {

  const classes = useStyles();

  const [specificationData, setSpecificationData] = React.useState(specificationInitialData);

  const [selectedType, setSelectedType] = React.useState(false);

  const [currentSelectOption, setCurrentSelectOption] = React.useState('');

  const [addNewSpecification, newSpecificationResult] = useAddSpecificationMutation();
  const { isLoading, isError, isSuccess, reset } = newSpecificationResult;

  const handleClose = () => {
    setOpen(false);
    setSpecificationData(specificationInitialData);
    setSelectedType(false);
    reset();
  };

  const handleAddNewSpecification = (e) => {
    e.preventDefault();
    if (selectedType === 'input') {
      const { selectOptions, ...rest } = specificationData;
      addNewSpecification(rest);
    } else if (selectedType === 'select') {
      if(!specificationData.selectOptions.length){
        alert('Dodajte opcije');
      }else{
      addNewSpecification({
        name: specificationData.name,
        type: specificationData.type,
        selectOptions : specificationData.selectOptions
      });
    }
    }
  };

  const handleChange = (e) => {
    setSpecificationData({
      ...specificationData,
      [e.target.name]: e.target.value
    });
  };

  const getStatusType = () => {
    if (isLoading) return <ShowLoading />
    if (isError) return <ShowError errorText='Neuspješno dodavanje specifikacije pokušajte ponovo' />
    if (isSuccess) return <ShowSuccess successText='Uuspješno dodavanje specifikacije' />
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          margin: '0',
          width: 'calc(100% - 21px)'
        }
      }}
      fullWidth
      onClose={handleClose}
      open={open}>
      <DialogTitle>
        Dodaj novu specifikaciju
      </DialogTitle>
      <DialogContent className={classes.dialogContent} style={{ paddingTop: '15px' }}>
        <form onSubmit={handleAddNewSpecification} id="myform">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete='off'
                label='Naziv'
                type='text'
                value={specificationData.name}
                name='name'
                onChange={handleChange}
                fullWidth
                variant='outlined'
                required
                size='small'
              />
            </Grid>
            <Grid item xs={12} >
              <Typography style={{ fontSize: '14px' }}>Izaberite tip specifikacije:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  setSpecificationData({
                    ...specificationData,
                    selectOptions: [],
                    type: 'input'
                  });
                  setSelectedType('input');
                }}
                style={{ backgroundColor: selectedType === 'input' ? '#26A65B' : '#ABB7B7' }}
                disableElevation
                required
                fullWidth
                variant='contained'>
                Input
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  setSpecificationData({
                    ...specificationData,
                    placeholder: '',
                    measure: '',
                    allowedCharacters: '',
                    type: 'select'
                  });
                  setSelectedType('select')
                }}
                style={{ backgroundColor: selectedType === 'select' ? '#26A65B' : '#ABB7B7' }}
                disableElevation
                fullWidth
                variant='contained'>
                Select
              </Button>
            </Grid>

            {
              selectedType
                ?
                selectedType === 'input'
                  ?
                  <>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete='off'
                        label='Placeholder'
                        type='text'
                        value={specificationData.placeholder}
                        name='placeholder'
                        onChange={handleChange}
                        fullWidth
                        variant='outlined'
                        size='small'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete='off'
                        label='Mjera npr. kg, GB, cm...'
                        type='text'
                        value={specificationData.measure}
                        name='measure'
                        onChange={handleChange}
                        fullWidth
                        variant='outlined'
                        size='small'
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth size='small'>
                        <InputLabel id="demo-simple-select-label">Dozvoljeni karakteri</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          value={specificationData.allowedCharacters}
                          label="Dozvoljeni karakteri"
                          onChange={(e) => {
                            setSpecificationData({ ...specificationData, allowedCharacters: e.target.value })
                          }}
                        >
                          {
                            allowedCharactersSelect.map((allowedType, index) => {
                              return <MenuItem key={index} value={allowedType.value}>{allowedType.name}</MenuItem>
                            })
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                  :
                  <>
                    <Grid item xs={12} >
                      <Typography style={{ fontSize: '14px' }}>Unesite opcije:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        value={currentSelectOption}
                        onChange={(e) => { setCurrentSelectOption(e.target.value); }}
                        autoComplete='off'
                        type='text'
                        size='small'
                        fullWidth
                        variant='outlined'
                        placeholder='Opcija'

                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        onClick={() => {
                          if (!currentSelectOption.length) {
                            alert('Unesite opciju')
                          } else {
                            setSpecificationData({
                              ...specificationData,
                              selectOptions: [...specificationData.selectOptions, currentSelectOption]
                            });
                            setCurrentSelectOption('');
                          }
                        }}
                        style={{ border: '1px solid #26A65B', height: '100%', backgroundColor: 'white', color: "#26A65B" }}
                        variant='contained'
                        fullWidth
                        disableElevation
                      >
                        <AddIcon />
                      </Button>
                    </Grid>
                    <Grid item xs={12} display='grid' gap='10px'>
                      {
                        specificationData.selectOptions.map((option, index) => {
                          return (
                            <div key={index} className={classes.singleOption}>
                              <Typography>{option}</Typography>
                              <IconButton onClick={() => {
                                const newArr = specificationData.selectOptions.filter((el,elIndex)=> elIndex !== index );
                                setSpecificationData({...specificationData, selectOptions : [...newArr]});
                              }}>
                                <DeleteIcon color='#DC3023' fontSize='20' />
                              </IconButton>
                            </div>
                          )
                        })
                      }
                      {
                        specificationData.selectOptions.length === 0
                        ?
                        <Typography variant='subtitle2'>Nema opcija</Typography>
                        :
                        null
                      }
                    </Grid>
                  </>
                :
                null
            }

          </Grid>
        </form>

      </DialogContent>

      {getStatusType()}

      <DialogActions>
        <Button
          variant='outlined'
          disableElevation
          onClick={handleClose}
        >
          Odustani
        </Button>
        <Button
          disabled={isLoading || !Boolean(selectedType)}
          form='myform'
          type='submit'
          style={{ backgroundColor: '#26A65B' }}
          variant='contained'
          disableElevation
        >
          Potvrdi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSpecDialog;