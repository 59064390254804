import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    bigDiv: {
        //border : '2px solid red',
        width: '96%',
        margin: 'auto',
        marginTop: '20px',
    },
    addButtonDiv: {
      
    },
    addCategoryIconDiv: {
        border: '3px dotted #BDC3C7',
        height: 'calc(40vw - 7px)',
        //width : 'calc(35vh - 5px)',
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'top center',
        marginTop: '5px',
    },
    addImageIcon: {
        position: 'absolute',
        fontSize: '40px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#BDC3C7',
        zIndex: '4'
    },
    loading: {
        width: '80%',
        margin: 'auto',
        marginTop: '15px',
        marginBottom: '15px'
    },
    categoriesList: {
        //border : '2px solid red',
        marginTop: '25px',
        
        marginBottom : '25px'
    },
    table : {
        //border : '2px solid red',
        fontFamily: "'Playfair Display', sans-serif",
        borderCollapse: 'collapse',
        width: '100%',
        '& $td,th': {
            //border: '1px solid black',
            padding: '7px',
            textAlign: 'left',
            borderBottom : '1px solid #bfbfbf',
        },
        '& $th': {
            // textAlign : 'left',
            // backgroundColor: '#f7f9fb',
            // fontSize : '12px'
            backgroundColor: '#ec1767',
            color : 'white'
        },
        '& $td': {
            textAlign : 'left',
            fontSize : '11px'
        },

    },
    subrow : {
        //border : '2px solid red',
        textIndent: '7px',
        
    },
    buttons : {
        //border : '1px solid yellow',
        width : '100%',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'center',
        alignItems : 'center',
        gap : '10px'
    },
    main : {
        backgroundColor : '#bfbfbf'
    },

    singleCategory: {
        position : 'relative',
        height: '40px',
        transition: 'transform 0.6s',
        transformStyle: 'preserve-3d',
        '&:hover': {
            transform: 'rotateY(180deg)'
        },
    },

    frontAndBack : {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backfaceVisibility: 'hidden',
        '*::-webkit-backface-visibility':{
            visibility :'hidden'
        }
    },
    singleCategoryFront: {
        //border : '1px solid green',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '5px',
        boxShadow: '0px 0.458px 0.645px -0.292px rgba(0,0,0,0.2), 0px 1px 1.583px 0.125px rgba(0,0,0,0.14), 0px 0.375px 1.916px 0.333px rgba(0,0,0,0.12)',
        //padding : '5px',
        alignItems: 'center',
        transition: '0.6s',
        height: '100%'
    },
    singleCategoryBack: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '5px',
        alignItems: 'center',
        height: '100%',
        transform: 'rotateY(180deg)'
    },
    singleCategoryIconDiv: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        height: '30px'
    },
    searchDiv : {
        //border : '2px solid red',
        margin : '20px 0px 15px 0px'
    }
}));

export default useStyles;