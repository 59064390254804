import React from 'react';
import useStyles from './style';
import { FaPencilAlt as EditIcon, FaTrashAlt as DeleteIcon, FaPlus as AddIcon } from 'react-icons/fa';
import { Button, Typography, LinearProgress, Alert, AlertTitle } from '@mui/material';
import AddSlideDialog from './AddSlideDialog';
import { useGetSlidesQuery } from '../../../store/reduxApi';
import DeleteSlideDialog from './DeleteSlideDialog';
import UpdateSlideDialog from './UpdateSlideDialog';
import ShowLoading from '../../Alerts/ShowLoading';
import ShowError from '../../Alerts/ShowError';
import ShowSuccess from '../../Alerts/ShowSuccess';

const SlidersManager = () => {

  const classes = useStyles();

  const [openAddDialog, setOpenAddDialog] = React.useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState({
    open: false,
    slideId: false
  });

  const [openUpdateDialog, setOpenUpdateDialog] = React.useState({
    open: false,
    slide: false
  });

  const { isLoading, isError, data = {} } = useGetSlidesQuery();


  return (
    <div className={classes.bigDiv}>
      <div className={classes.addButtonDiv}>
        <Button
          onClick={() => { setOpenAddDialog(true); }}
          style={{ backgroundColor: '#26A65B' }}
          variant='contained'
          disableElevation
        >
          <AddIcon style={{ marginRight: '5px' }} />
          Dodaj
        </Button>
      </div>
      <div className={classes.slideList}>
        {isLoading ? <ShowLoading /> : null}
        {isError ? <ShowError errorText='Neuspješno prikazivanje svih slajdova' /> : null}
        {data?.allSlides?.length === 0 ? <Typography>Trenutno nema slajdova</Typography> : null}
        {
          data?.allSlides?.map((slide, index) => {
            return (
              <div className={classes.singleSlide} key={index}>
                <div
                  className={classes.singleSlidePhoto}
                  style={{ backgroundImage: `url('${process.env.REACT_APP_UPLOADS_PATH + slide.photo}')` }}>
                </div>

                <div>
                  <Typography style={{ fontSize: '11px', marginLeft: '5px' }}>
                    {slide.link ? slide.link : 'Bez linka'}
                  </Typography>
                </div>
                <div className={classes.slideActions}>
                  <Button
                    onClick={() => {
                      setOpenUpdateDialog({
                        open: true,
                        slide
                      })
                    }}
                    variant='contained'
                    disableElevation style={{ backgroundColor: '#006442', minWidth: '50px' }}>
                    <EditIcon fontSize='16' />
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenDeleteDialog({
                        open: true,
                        slideId: slide.id
                      })
                    }}
                    variant='contained'
                    disableElevation style={{ backgroundColor: '#DC3023', minWidth: '50px' }}>
                    <DeleteIcon fontSize='16' />
                  </Button>
                </div>
              </div>
            )
          })
        }
      </div>
      <AddSlideDialog
        open={openAddDialog}
        setOpen={setOpenAddDialog}
        ShowLoading={ShowLoading}
        ShowSuccess={ShowSuccess}
        ShowError={ShowError} />
      <DeleteSlideDialog
        open={openDeleteDialog.open}
        setOpen={setOpenDeleteDialog}
        slideId={openDeleteDialog.slideId}
        ShowLoading={ShowLoading}
        ShowSuccess={ShowSuccess}
        ShowError={ShowError} />
      <UpdateSlideDialog
        open={openUpdateDialog.open}
        setOpen={setOpenUpdateDialog}
        slide={openUpdateDialog.slide}
        ShowLoading={ShowLoading}
        ShowSuccess={ShowSuccess}
        ShowError={ShowError} />
    </div>
  )
}

export default SlidersManager