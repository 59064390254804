import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    bigDiv : {
        //border : '2px solid red',
        width : '90%',
        margin : 'auto',
        marginTop : '20px',
    },
    table: {
        fontFamily: "'Playfair Display', sans-serif",
        borderCollapse: 'collapse',
        width: '100%',
        '& $td,th': {
            border: '1px solid #cccccc',
            textAlign: 'center',
            padding: '8px'
        },
        '& $th': {
            textAlign : 'left',
            backgroundColor: '#f7f9fb',
            fontSize : '12px'
        },
        '& $td': {
            textAlign : 'left',
            fontSize : '11px'
        }
    },
    searchDiv : {
        margin : '20px 0px 15px 0px'
    },
}));

export default useStyles;