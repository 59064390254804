import React from 'react';
import {
  Toolbar, AppBar, Button, Slide, Typography,
  IconButton, Dialog, DialogContent, Grid, TextField,
  FormControl, Select, InputLabel, MenuItem, InputAdornment, FormHelperText, Divider, Snackbar, Alert, LinearProgress
} from '@mui/material';
import { TfiClose as CloseIcon } from 'react-icons/tfi';
import useStyles from './style';
import moment from 'moment';
import { FaPlus as AddIcon } from 'react-icons/fa';
import { useLazyGetChildCategoriesQuery, useLazyGetSpecOfCatQuery } from '../../../store/reduxApi';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { MdDateRange as DateIcon } from 'react-icons/md';
import { productSchema } from '../../../validation/productValidation';
import { useUpdateProductMutation } from '../../../store/reduxApi';
import ShowLoading from '../../Alerts/ShowLoading';
import ShowError from '../../Alerts/ShowError';
import ShowSuccess from '../../Alerts/ShowSuccess';
import { RxDragHandleHorizontal as DragIcon } from 'react-icons/rx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import UpdateProductVariation from './UpdateProductVariation';
import AddProductVariation from './AddProductVariation';
import { FaPencilAlt as EditIcon, FaTrashAlt as DeleteIcon } from 'react-icons/fa';
import { useDeleteVariationMutation } from '../../../store/reduxApi';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialProductData = {
  id: '',
  name: '',
  regular_price: '',
  limit_per_customer: '',
  category: '',
  has_lager: '',
  photoGallery: []
};

const initialErrorsData = {
  name: false,
  category: false,
  regular_price: false,
  discount: false,
  discount_price: false,
  qty_available: false,
  limit_per_customer: false,
  barcode: false,
  discount_date: false,
  photoGallery: false,
  has_lager: false
};

const initialSimpleProductData = {
  price: '',
  discount: '',
  discount_price: '',
  discount_date: '',
  barcode: '1234567891234',
  qty: '20',
  simpleProductSpecs: []
};

const UpdateProductDialog = ({ open, setOpen, product }) => {

  const classes = useStyles();

  const [snackBarInfo, setSnackBarInfo] = React.useState({
    open: false,
    state: '',
    variationIndex: null
  });

  const [previewPhotoGallery, setPreviewPhotoGallery] = React.useState([]);

  const [photosToDelete, setPhotosToDelete] = React.useState([]);

  const [productVariationForEditing, setProductVariationForEditing] = React.useState({
    variation: false,
    index: false
  });

  const [openAddProductVariation, setOpenAddProductVariation] = React.useState(false);

  const [editProductVariationInfo, setEditProductVariationInfo] = React.useState({
    open: false,
    variation : null,
    index : null
  });

  const [errors, setErrors] = React.useState(initialErrorsData);

  const [simpleProductData, setSimpleProductData] = React.useState(initialSimpleProductData);

  const [productVariations, setProductVariations] = React.useState([]);

  const [triggerGetSpecs, { data: specOfCat, isSuccess: isSuccessSpec }] = useLazyGetSpecOfCatQuery();

  const [productData, setProductData] = React.useState(initialProductData);

  const [openDate, setOpenDate] = React.useState(false);

  const [updateProduct, { isLoading, isError, isSuccess, error, reset }] = useUpdateProductMutation();

  const [deleteVariation, {
    isLoading: isLoadingDeleteVariation,
    isError: isErrorDeleteVariation,
    isSuccess: isSuccessDeleteVariation,
    error: errorDeleteVariation,
    reset: resetDeleteValidationState }] = useDeleteVariationMutation();

  const inputRef = React.useRef();

  const handleClose = () => {
    setOpen({
      open: false,
      product: false
    });
    setProductData(initialProductData);
    setSimpleProductData(initialSimpleProductData);
    setErrors(initialErrorsData);
    reset();
    handleCloseSnackbar();
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarInfo({
      open: false,
      state: '',
      variationIndex: null
    });
    resetDeleteValidationState();
  };

  const [trigger, { data = {} }] = useLazyGetChildCategoriesQuery();

  React.useEffect(() => {
    if (open) {
      trigger();
      triggerGetSpecs({ categoryId: product.category });
      setProductData({
        ...productData,
        ...product
      });
      setPreviewPhotoGallery([...product.photoGallery]);
      if (!product.has_lager && product.simpleProduct) {
        setSimpleProductData({
          ...product.simpleProduct
        });
      }
      if (product.has_lager && product.variations) {
        setProductVariations([...product.variations]);
      }
    }
  }, [open]);

  React.useEffect(() => {
    if (isErrorDeleteVariation) {
      setSnackBarInfo({
        open: true,
        state: 'error'
      });
    }
    if (isSuccessDeleteVariation) {
      setSnackBarInfo({
        open: true,
        state: 'success'
      });
    }
  }, [isSuccessDeleteVariation, isErrorDeleteVariation]);


  const getTextFieldType = (allowedCharacters) => {
    switch (allowedCharacters) {
      case 'Natural number':
        return 'number'
      case 'Number':
        return 'number'
      case 'Letters':
        return 'string'
      case 'All':
        return 'string'
      default: return 'string'
    }
  };

  // React.useEffect(() => {
  //     if (simpleProductData?.discount?.length && productData?.regular_price?.length) {
  //       const newPrice = productData.regular_price - simpleProductData.discount / 100 * productData.regular_price;
  //       setSimpleProductData({
  //         ...productData,
  //         discount_price: newPrice.toFixed(2)
  //       })
  //     }
  //     if (!simpleProductData?.discount?.length) {
  //       setSimpleProductData({
  //         ...productData,
  //         discount_price: ''
  //       })
  //     }
  // }, [simpleProductData.discount]);

  const handleImageChange = (e) => {
    if (e.target.files) {

      const newFiles = Array.from(e.target.files).map((file) => ({
        path: URL.createObjectURL(file),
        id: null
      }));

      setProductData((prevData) => {
        return {
          ...prevData,
          photoGallery: prevData.photoGallery.concat([...e.target.files])
        }
      });
      setPreviewPhotoGallery((prevImageGallery) => prevImageGallery.concat(newFiles));
    }
  };

  const handleImageRemoval = (id = null, index) => {

    setPhotosToDelete((prevData) => [...prevData, id]);

    setProductData((prevData) => {
      return {
        ...prevData,
        photoGallery: prevData.photoGallery.filter((photo, photoIndex) => photoIndex !== index)
      }
    });

    setPreviewPhotoGallery((prevImageGallery) => prevImageGallery.filter((img, indexOfImg) => indexOfImg !== index));
  };

  function handleDragEnd(result) {
    if (!result.destination) {
      // Item was dropped outside of droppable area
      return;
    }

    const reorderedDivs = Array.from(previewPhotoGallery);
    const [reorderedDiv] = reorderedDivs.splice(result.source.index, 1);
    reorderedDivs.splice(result.destination.index, 0, reorderedDiv);

    setPreviewPhotoGallery(reorderedDivs);

    const reorderedDivsGallery = Array.from(productData.photoGallery);
    const [reorderedDivGallery] = reorderedDivsGallery.splice(result.source.index, 1);
    reorderedDivsGallery.splice(result.destination.index, 0, reorderedDivGallery);

    setProductData({
      ...productData,
      photoGallery: reorderedDivsGallery
    });
  }

  const urlToFile = async (imageUrl, id) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const file = new File([blob], 'image.png', { type: blob.type });
      return file;
    } catch (error) {
      console.error('Error retrieving image:', error);
      return null;
    }
  }

  const handleUpdateProduct = async (e) => {
    e.preventDefault();

    const photoGallery = await Promise.all(productData.photoGallery.map(async (photo) => {
      if (photo.id) {
        const file = await urlToFile(process.env.REACT_APP_UPLOADS_PATH + photo.path, photo.id);
        return file
      } else return photo
    }));

    delete productData.simpleProduct;
    delete productData.variations;

    const formData = new FormData();

    Object.keys(productData).forEach(key => {
      if (key === 'photoGallery') return;
      formData.append(key, productData[key]);
    });

    formData.append('photosToDelete', JSON.stringify(photosToDelete));

    formData.append('simpleProductData', JSON.stringify(simpleProductData));

    photoGallery.forEach((photo) => {
      formData.append('photoGallery', photo);
    });

    productData.photoGallery.forEach((photo) => {
      formData.append('existingPhotosIds', photo.id ? photo.id : null);
    });

    console.log(productVariations.filter(variation => !variation.id));

    productVariations.filter(variation => !variation.id)
      .forEach((variation, index) => {
        const { variationGallery, ...rest } = variation;
        const varJson = JSON.stringify(rest);
        formData.append(`newVariation_${index}`, varJson);
        if (variationGallery.length) {
          variationGallery.forEach((photo) => {
            formData.append(`newVariation_${index}`, photo);
          });
        }
      });

    updateProduct(formData);

    console.log({
      productData,
      photosToDelete,
      photoGallery,
      simpleProductData,
      productVariations
    });

    // productSchema.validate(productData, { abortEarly: false })
    //   .then(async () => {
    //     setErrors(initialErrorsData);
    //     const photoGallery = await Promise.all(productData.photoGallery.map(async (photo) => {
    //       if (photo.id) {
    //         const file = await urlToFile(process.env.REACT_APP_UPLOADS_PATH + photo.path, photo.id);
    //         return file
    //       } else return photo
    //     }));
    //     const formData = new FormData();
    //     Object.keys(productData).forEach(key => {
    //       if (key === 'photoGallery') return;
    //       formData.append(key, productData[key]);
    //     });
    //     formData.append('photosToDelete', JSON.stringify(photosToDelete));
    //     photoGallery.forEach((photo) => {
    //       formData.append('photoGallery', photo);
    //     });
    //     productData.photoGallery.forEach((photo) => {
    //       formData.append('existingPhotosIds', photo.id ? photo.id : null);
    //     });
    //     updateProduct(formData);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     const newErrors = { ...initialErrorsData };
    //     error.inner.forEach(e => {
    //       newErrors[e.path] = e.message
    //     });
    //     setErrors({ ...newErrors });
    //   });
  };

  const handleUpdateSimpleProduct = (e) => {
    e.preventDefault();
    console.log(productData.simpleProduct);
  };

  const handleChange = (e) => {
    setProductData({
      ...productData,
      [e.target.name]: e.target.value
    })
  };

  const GetAlert = () => {
    if (isLoading) return <ShowLoading />
    if (isError) return <ShowError
      errorText={error?.status === 403 ? error?.data?.message : 'Neuspješno ažuriranje proizvoda'}
      errCode={error?.status} />
    if (isSuccess) return <ShowSuccess successText='Uspješno ažuriranje proizvoda' />
  };

  const getSpecOptions = (specificationName) => {
    const specData = specOfCat?.specData?.find(spec => spec.name === specificationName);
    return (
      specData?.options?.map((option, index) => {
        return <MenuItem key={index} value={option.id}>{option.title}</MenuItem>
      })
    )
  };

  const getInputData = (inputName) => {
    const input = specOfCat?.specData?.find(spec => spec.name === inputName);
    return {
      helperText: input?.placeholder,
      measure: input?.measure
    }
  };

  const getVariationData = (variation) => {
    let varName = '';

    if (variation.id) {
      variation.variationSpecs.forEach(spec => varName += ' ' + spec.value + ' ');
    } else {
      if (isSuccessSpec) {
        for (const key in variation.variationSpecs) {
          const specIndex = specOfCat.specData.findIndex((spec) => spec.name === key);
          if (specOfCat.specData[specIndex].type === 'select') {
            const option = specOfCat.specData[specIndex].options.find((option) => option.id === variation.variationSpecs[key]);
            varName += ' ' + option.title;
          } else {
            varName += ' ' + variation.variationSpecs[key] + ' ' + specOfCat.specData[specIndex].measure;
          }
        }
      }
    }

    return varName;
  };

  const handleRemoveVariation = (variation, index) => {
    if (variation.id) {
      setSnackBarInfo({ ...snackBarInfo, variationIndex: index });
      deleteVariation({ id: variation.id });
    } else {
      const upVariations = productVariations.filter((variation, var_index) => var_index !== index);
      setProductVariations([...upVariations]);
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: '#bf1f2c'
        }}>
        <Toolbar sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <IconButton
            edge="start"
            onClick={handleClose}
          >
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>

          <Button
            disabled={isLoading}
            form="myform"
            variant='outlined'
            disableElevation
            type='submit'
            style={{ backgroundColor: 'white', color: 'black', border: 'black' }}
          >
            Ažuriraj glavne podatke
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <form onSubmit={handleUpdateProduct} id='myform' style={{ border: '1px solid #D2D7D3', padding: '8px', borderRadius: '4px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GetAlert />
            </Grid>
            <Grid item xs={12} textAlign='center'>
              <Typography variant='subtitle1'>
                Glavni podaci
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete='off'
                label='Ime artikla'
                type='text'
                value={productData.name}
                onChange={handleChange}
                size='small'
                name='name'
                fullWidth
                variant='outlined'
                error={Boolean(errors.name)}
                helperText={errors.name ?? errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle2'>
                Slike proizvoda:
              </Typography>
              <Button
                onClick={() => { inputRef.current.click(); }}
                variant='contained'
                disableElevation
                style={{ backgroundColor: '#26A65B', marginTop: '5px' }}
              >
                <AddIcon />
                <input
                  ref={inputRef}
                  id='dugme'
                  type="file"
                  onChange={handleImageChange}
                  multiple
                  accept='image/*'
                  hidden
                />
              </Button>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="div-list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <div className={classes.productGallery}>
                        {previewPhotoGallery.map((image, index) => (
                          <Draggable key={index} draggableId={index.toString()} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  style={{
                                    backgroundImage: index === 0 ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23F86673FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")` : 'inherit'
                                  }}
                                  key={index}
                                  className={classes.singleImage}>
                                  <div
                                    className={classes.image}
                                    style={{
                                      backgroundImage: `url('${image.id ? process.env.REACT_APP_UPLOADS_PATH + image.path : image.path}')`
                                    }}>
                                    <div
                                      onTouchStartCapture={(e) => {
                                        handleImageRemoval(image.id, index)
                                      }}
                                      className={classes.deleteImage}>
                                      <DeleteIcon style={{ color: '#DC3023', fontSize: '20px' }} />
                                    </div>
                                  </div>
                                  <div
                                    className={classes.dragImage}>
                                    <DragIcon style={{ fontSize: '34px', marginRight: '10px' }} />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {
                errors.photoGallery
                  ?
                  <FormHelperText style={{ color: '#d32f2f' }}>{errors.photoGallery}</FormHelperText>
                  :
                  null
              }
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth size='small'>
                <InputLabel
                  style={{ color: Boolean(errors.category) ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)' }}
                  id="demo-simple-select-label">Kategorija</InputLabel>
                <Select
                  error={Boolean(errors.category)}
                  labelId="demo-simple-select-label"
                  value={productData.category}
                  label="Kategorija"
                  name='category'
                  onChange={handleChange}
                >
                  {
                    data?.categories?.map((category, index) => {
                      return <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                    })
                  }
                </Select>
                {
                  errors.category
                    ?
                    <FormHelperText style={{ color: '#d32f2f' }}>{errors.category}</FormHelperText>
                    :
                    null
                }
              </FormControl>
            </Grid>

            <Grid item xs={7}>
              <TextField
                error={Boolean(errors.regular_price)}
                helperText={errors.regular_price ?? errors.regular_price}
                autoComplete='off'
                label='Regularna cijena &euro;'
                type='number'
                value={productData.regular_price}
                onChange={handleChange}
                size='small'
                name='regular_price'
                fullWidth
                variant='outlined'
              />
            </Grid>

            <Grid item xs={5}>
              <TextField
                error={Boolean(errors.limit_per_customer)}
                helperText={errors.limit_per_customer ?? errors.limit_per_customer}
                autoComplete='off'
                label='Limit po kupcu'
                type='number'
                value={productData.limit_per_customer}
                onChange={handleChange}
                size='small'
                name='limit_per_customer'
                fullWidth
                variant='outlined'
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth size='small'>
                <InputLabel
                  style={{ color: Boolean(errors.has_lager) ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)' }}
                  id="demo-simple-select-label">Više varijanti proizvoda ?</InputLabel>
                <Select
                  error={Boolean(errors.has_lager)}
                  labelId="demo-simple-select-label"
                  value={Boolean(productData.has_lager)}
                  label="Više varijanti proizvoda ?"
                  name='has_lager'
                  onChange={handleChange}
                >
                  {/* <MenuItem  value=''></MenuItem> */}
                  <MenuItem value={true}>Da</MenuItem>
                  <MenuItem value={false}>Ne</MenuItem>
                </Select>
                {
                  errors.has_lager
                    ?
                    <FormHelperText style={{ color: '#d32f2f' }}>{errors.has_lager}</FormHelperText>
                    :
                    null
                }
              </FormControl>
            </Grid>
          </Grid>
        </form>

        <form onSubmit={handleUpdateSimpleProduct} style={{ border: '1px solid #D2D7D3', padding: '8px', borderRadius: '4px', marginTop: '10px' }}>
          <Grid container spacing={2}>
            {

              typeof productData.has_lager === 'string'
                ?
                null
                :
                productData.has_lager
                  ?
                  <>
                    <Grid item xs={12} textAlign='center'>

                      <Typography variant='subtitle1'>
                        Varijabilni proizvod
                      </Typography>
                    </Grid>
                    <Grid item xs={12} marginBottom='10px'>
                      <Typography variant='subtitle2'>
                        Dodaj varijaciju:
                      </Typography>
                      <Button
                        onClick={() => { setOpenAddProductVariation(true); }}
                        variant='contained'
                        disableElevation
                        style={{ backgroundColor: '#26A65B', marginTop: '5px' }}
                      >
                        <AddIcon />
                      </Button>

                    </Grid>

                    <Grid item xs={12}>
                      {
                        productVariations.length
                          ?
                          <div className={classes.productVariations}>
                            {
                              productVariations.map((variation, index) => {
                                return (
                                  index === snackBarInfo.variationIndex && isLoadingDeleteVariation
                                    ?
                                    <div>
                                      <Typography variant='caption'>Uklanjanje varijacije...</Typography>
                                      <LinearProgress />
                                    </div>
                                    :
                                    <div key={index}>
                                      <div className={classes.variation}>
                                        <div className={classes.variationData}>
                                          <Typography variant='subtitle1'>
                                            {
                                              getVariationData(variation)
                                            }
                                          </Typography>
                                        </div>
                                        <div className={classes.variationButtons}>
                                          <Button
                                            onClick={() => {
                                              setEditProductVariationInfo({
                                                open: true,
                                                variation,
                                                index
                                              });
                                            }}
                                            disableElevation
                                            variant='contained'
                                            style={{ backgroundColor: '#5B8930', minWidth: '40px' }}>
                                            <EditIcon />
                                          </Button>
                                          <Button
                                            onClick={() => { handleRemoveVariation(variation, index); }}
                                            disableElevation
                                            variant='contained'
                                            style={{ backgroundColor: '#DC3023', minWidth: '40px' }}>
                                            <DeleteIcon />
                                          </Button>
                                        </div>

                                      </div>

                                    </div>
                                )
                              })
                            }
                          </div>
                          :
                          <Typography>
                            Za varijabilne proizvode morate dodati minimum 2 varijacije
                          </Typography>
                      }
                    </Grid>
                  </>
                  :
                  <>
                    <Grid item xs={12} textAlign='center' marginTop={3}>
                      <Typography variant='subtitle1'>
                        Jednostavni proizvod
                      </Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <TextField
                        autoComplete='off'
                        label='Popust %'
                        type='number'
                        value={simpleProductData.discount}
                        onChange={(e) => { setSimpleProductData({ ...simpleProductData, discount: e.target.value }) }}
                        size='small'
                        fullWidth
                        variant='outlined'
                        error={Boolean(errors.discount)}
                        helperText={errors.discount ?? errors.discount}
                      />
                    </Grid>

                    <Grid item xs={7}>
                      <TextField
                        error={Boolean(errors.discount_price)}
                        helperText={errors.discount_price ?? errors.discount_price}
                        disabled={!Boolean(simpleProductData.discount)}
                        autoComplete='off'
                        label='Cijena na popust &euro;'
                        type='number'
                        value={simpleProductData.discount_price}
                        onChange={(e) => { setSimpleProductData({ ...simpleProductData, discount_price: e.target.value }) }}
                        size='small'
                        name='discount_price'
                        fullWidth
                        variant='outlined'
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDatePicker
                          closeOnSelect
                          disablePast
                          showToolbar={false}
                          inputFormat="DD/MM/YYYY"
                          open={openDate}
                          onChange={(e) => {
                            setSimpleProductData({
                              ...simpleProductData,
                              discount_date: moment(e._d).format('DD/MM/YYYY')
                            })
                          }}
                          onClose={() => { setOpenDate(false); }}
                          renderInput={(params) => <TextField style={{ display: 'none' }} />}
                        />
                      </LocalizationProvider>
                      <TextField
                        error={Boolean(errors.discount_date)}
                        helperText={errors.discount_date ?? errors.discount_date}
                        disabled={!Boolean(simpleProductData.discount)}
                        onClick={() => { if (Boolean(simpleProductData.discount)) { setOpenDate(true); } }}
                        value={simpleProductData.discount_date}
                        label="Datum popusta"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <DateIcon size='22' />
                            </InputAdornment>
                          )
                        }}
                        // {...params}
                        fullWidth
                        size='small' />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        autoComplete='off'
                        label='Barkod'
                        type='text'
                        value={simpleProductData.barcode}
                        onChange={(e) => {
                          setSimpleProductData({
                            ...simpleProductData,
                            barcode: e.target.value
                          })
                        }}
                        size='small'
                        name='barcode'
                        fullWidth
                        variant='outlined'
                        error={Boolean(errors.barcode)}
                        helperText={errors.barcode ?? errors.barcode}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(errors.qty)}
                        helperText={errors.qty ?? errors.qty}
                        autoComplete='off'
                        label='Dostupnost'
                        type='number'
                        value={simpleProductData.qty}
                        onChange={(e) => {
                          setSimpleProductData({
                            ...simpleProductData,
                            qty: e.target.value
                          })
                        }}
                        size='small'
                        name='qty_available'
                        fullWidth
                        variant='outlined'
                      />
                    </Grid>

                    <Grid item xs={12} textAlign='center'>
                      {
                        productData.category
                          ?
                          specOfCat?.specData?.length
                            ?
                            <Typography variant='body1'>
                              Specifikacije
                            </Typography>
                            :
                            <Typography variant='body1'>
                              Izabrana kategorija nema specifikacije
                            </Typography>
                          :
                          <Typography variant='body1'>
                            Izaberite kategoriju da biste dobili specifikacije
                          </Typography>
                      }
                    </Grid>


                    {/* sps => simple product specification */}
                    {
                      isSuccessSpec
                        ?
                        simpleProductData.simpleProductSpecs.map((sps, index) => {
                          return (
                            sps.type === 'select'
                              ?
                              <Grid item xs={12} key={index}>
                                <FormControl fullWidth size='small'>
                                  <InputLabel
                                    id="demo-simple-select-label">{sps.specification_name}</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    value={sps.specification_option}
                                    label={sps.specification_name}
                                    name={sps.specification_name}
                                    onChange={(e) => {

                                      const simpleProductSpecs = [...simpleProductData.simpleProductSpecs];
                                      const index = simpleProductSpecs.findIndex(spec => spec.specification_name === sps.specification_name);

                                      const newObj = { ...simpleProductSpecs[index] };
                                      newObj.specification_option = e.target.value;


                                      simpleProductSpecs[index] = newObj;
                                      setSimpleProductData({ ...simpleProductData, simpleProductSpecs });

                                    }}
                                  >
                                    {
                                      getSpecOptions(sps.specification_name)
                                    }
                                  </Select>
                                </FormControl>
                              </Grid>
                              :
                              <Grid item xs={12} key={index}>
                                <TextField
                                  helperText={getInputData(sps.specification_name).helperText}
                                  autoComplete='off'
                                  type={getTextFieldType(getInputData(sps.specification_name).allowed_characters)}
                                  value={sps.value}
                                  label={sps.specification_name}
                                  onChange={(e) => {
                                    const simpleProductSpecs = [...simpleProductData.simpleProductSpecs];
                                    const index = simpleProductSpecs.findIndex(spec => spec.specification_name === sps.specification_name);

                                    const newObj = { ...simpleProductSpecs[index] };
                                    newObj.value = e.target.value;

                                    simpleProductSpecs[index] = newObj;
                                    setSimpleProductData({ ...simpleProductData, simpleProductSpecs });
                                  }}
                                  size='small'
                                  fullWidth
                                  variant='outlined'
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Typography>{getInputData(sps.specification_name).measure}</Typography>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                          )
                        })
                        :
                        null

                    }

                  </>

            }

          </Grid>
        </form>
      </DialogContent>
      <UpdateProductVariation
        open={editProductVariationInfo.open}
        setOpen={setEditProductVariationInfo}
        category={productData.category}
        productPrice={productData.regular_price}
        specs={specOfCat?.specData}
        getTextFieldType={getTextFieldType}
        variation={editProductVariationInfo.variation}
        getSpecOptions={getSpecOptions}
        getInputData={getInputData}
      />
      <AddProductVariation
        open={openAddProductVariation}
        setOpen={setOpenAddProductVariation}
        category={productData.category}
        productPrice={productData.regular_price}
        specs={specOfCat?.specData}
        productVariations={productVariations}
        setProductVariations={setProductVariations}
        getTextFieldType={getTextFieldType}
        edit={productVariationForEditing}
        setEdit={setProductVariationForEditing}
      />
      <Snackbar
        style={{ marginTop: '60px' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarInfo.open}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackBarInfo.state}
          variant='filled'
          sx={{ width: '100%' }}>
          {
            snackBarInfo.state === 'success'
              ?
              'Varijacija uklonjena'
              :
              snackBarInfo.state === 'error'
                ?
                'Greška u uklanjanju varijacije'
                :
                null
          }
        </Alert>
      </Snackbar>
    </Dialog>
  )
}

export default UpdateProductDialog;