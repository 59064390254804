import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import useStyles from './style';

const ShowSuccess = ({ successText }) => {

    const classes = useStyles();

    return (
        <Alert variant='filled' severity="success" className={classes.success}>
          <AlertTitle>Uspješno</AlertTitle>
          {successText}
        </Alert>
      )
};

export default ShowSuccess;