import { Typography, Button, LinearProgress, Alert, AlertTitle } from '@mui/material';
import React from 'react';
import useStyles from './style';
import { FaPencilAlt as EditIcon, FaTrashAlt as DeleteIcon, FaPlus as AddIcon } from 'react-icons/fa';
import AddNotificationDialog from './AddNotificationDialog';
import UpdateNotificationDialog from './UpdateNotificationDialog';
import { useGetAllNotificationsAdminQuery } from '../../../store/reduxApi';
import DeleteNotificationDialog from './DeleteNotificationDialog';
import ShowLoading from '../../Alerts/ShowLoading';
import ShowError from '../../Alerts/ShowError';
import ShowSuccess from '../../Alerts/ShowSuccess';

const Notifications = () => {

  const classes = useStyles();

  const [openAddDialog, setOpenAddDialog] = React.useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = React.useState({
    open: false,
    notification: false
  });
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState({
    open: false,
    notificationId: false
  });

  const { data = {}, isLoading, isError } = useGetAllNotificationsAdminQuery();

  const cropLongText = (text) => {
    return text.length > 103 ? text.slice(0, 123) + '...' : text;
  };

  return (
    <div className={classes.bigDiv}>
      <div className={classes.addButtonDiv}>
        <Button
          onClick={() => { setOpenAddDialog(true); }}
          style={{ backgroundColor: '#26A65B' }}
          variant='contained'
          disableElevation
        >
          <AddIcon style={{ marginRight: '5px' }} />
          Dodaj
        </Button>
      </div>
      <div className={classes.notificationsList}>
        {isLoading ? <ShowLoading /> : null}
        {isError ? <ShowError errorText='Neuspješno prikazivanje svih obaviještenja' /> : null}
        {
          data?.notifications?.map((notification, index) => {
            return (
              <div className={classes.singleNotificationDiv} key={index}>
                <div className={classes.notificationTitle}>
                  <Typography style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    {notification.title}
                  </Typography>
                </div>
                <div className={classes.notificationDescription}>
                  <Typography style={{ fontSize: '11px' }}>
                    {cropLongText(notification.description)}
                  </Typography>
                </div>
                <div className={classes.notificationActions}>
                  <Button
                    onClick={() => {
                      setOpenUpdateDialog({
                        open: true,
                        notification
                      })
                    }}
                    variant='contained'
                    disableElevation style={{ backgroundColor: '#006442', minWidth: '50px' }}>
                    <EditIcon fontSize='16' />
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenDeleteDialog({
                        open: true,
                        notificationId: notification.id
                      })
                    }}
                    variant='contained'
                    disableElevation style={{ backgroundColor: '#DC3023', minWidth: '50px' }}>
                    <DeleteIcon fontSize='16' />
                  </Button>
                </div>
              </div>
            )
          })
        }
        {
          data?.notifications?.length === 0
            ?
            <Typography>Trenutno nema obaviještenja</Typography>
            :
            null
        }
      </div>
      <AddNotificationDialog
        open={openAddDialog}
        setOpen={setOpenAddDialog}
        ShowLoading={ShowLoading}
        ShowSuccess={ShowSuccess}
        ShowError={ShowError} />
      <UpdateNotificationDialog
        open={openUpdateDialog.open}
        setOpen={setOpenUpdateDialog}
        notification={openUpdateDialog.notification}
        ShowLoading={ShowLoading}
        ShowSuccess={ShowSuccess}
        ShowError={ShowError} />
      <DeleteNotificationDialog
        open={openDeleteDialog.open}
        setOpen={setOpenDeleteDialog}
        notificationId={openDeleteDialog.notificationId}
        ShowLoading={ShowLoading}
        ShowSuccess={ShowSuccess}
        ShowError={ShowError} />
    </div>
  );
};

export default Notifications