import React from 'react';
import { Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLogoutMutation } from '../../store/reduxApi';
import { MdArrowBack as BackIcon } from 'react-icons/md';
import useStyles from './style';
import { FaUser as UserIcon, FaBox as ProductIcon, FaCashRegister as OrderIcon } from 'react-icons/fa';
import { MdSettingsSuggest as SpecIcon } from 'react-icons/md';
import { BiCategory as CategoryIcon } from 'react-icons/bi';
import { SlLogout as LogoutIcon } from 'react-icons/sl';
import { VscBell as Bell } from 'react-icons/vsc';
import { BsImages as SliderIcon } from 'react-icons/bs';
import AdminPageTypes from './AdminPageTypes';
import UsersManager from './Users/UsersManager';
import ProductsManager from './Products/ProductsManager';
import CategoriesManager from './Categories/CategoriesManager';
import NotificationsManager from './Notifications/NotificationsManager';
import SlidersManager from './Sliders/SlidersManager';
import OrdersManager from './Orders/OrdersManager';
import SpecificationsManager from './Specifications/SpecificationsManager';
import { BsPercent as PercentIcon } from 'react-icons/bs';
import GlobalDiscountDialog from './GlobalDiscountDialog/GlobalDiscountDialog';

const pageTypes = [
    {
        title: 'Korisnici',
        icon: <UserIcon />
    },
    {
        title: 'Proizvodi',
        icon: <ProductIcon />
    },
    {
        title: 'Kategorije',
        icon: <CategoryIcon />
    },
    {
        title: 'Specifikacije',
        icon: <SpecIcon />
    },
    {
        title: 'Obaviještenja',
        icon: <Bell />
    },
    {
        title: 'Slider',
        icon: <SliderIcon />
    },
    {
        title: 'Porudžbine',
        icon: <OrderIcon />
    },
    {
        title: 'Popust',
        icon: <PercentIcon />
    },
];

const initialTitleAndSubtitle = {
    title: 'Admin panel',
    subtitle: 'Administracija aplikacije'
};

const AdminPage = () => {

    const navigate = useNavigate();
    const classes = useStyles();

    const [showGlobalDiscountDialog, setShowGlobalDiscountDialog] = React.useState(false);

    const [currentAdminDisplay, setCurrentAdminDisplay] = React.useState(0);
    const [currentAdminTitleAndSubtitle, setCurrentAdminTitleAndSubtitle] = React.useState(initialTitleAndSubtitle);

    const [logout, logoutResult] = useLogoutMutation();
    const { data } = logoutResult;

    const handleLogout = () => {
        logout();
    };

    React.useEffect(() => {
        if (typeof data !== 'undefined') {
            if (data.success) {
                navigate('/');
            }
        }
    }, [data]);

    const getCurrentAdminDisplay = () => {
        switch (currentAdminDisplay) {
            case 0: return <AdminPageTypes classes={classes} pageTypes={pageTypes} setCurrentAdminDisplay={setCurrentAdminDisplay} />
            case 1: return <UsersManager />
            case 2: return <ProductsManager />
            case 3: return <CategoriesManager />
            case 4: return <SpecificationsManager />
            case 5: return <NotificationsManager />
            case 6: return <SlidersManager />
            case 7: return <OrdersManager />
            default: return <AdminPageTypes classes={classes} pageTypes={pageTypes} setCurrentAdminDisplay={setCurrentAdminDisplay} />
        }
    };

    React.useEffect(() => {
        switch (currentAdminDisplay) {
            case 0:
                setCurrentAdminTitleAndSubtitle(initialTitleAndSubtitle);
                break;
            case 1:
                setCurrentAdminTitleAndSubtitle({
                    title: 'Korisnici',
                    subtitle: 'Administracija korisnika'
                });
                break;
            case 2:
                setCurrentAdminTitleAndSubtitle({
                    title: 'Proizvodi',
                    subtitle: 'Administracija proizvoda'
                });
                break;
            case 3:
                setCurrentAdminTitleAndSubtitle({
                    title: 'Kategorije',
                    subtitle: 'Administracija kategorija'
                });
                break;
            case 4:
                setCurrentAdminTitleAndSubtitle({
                    title: 'Specifikacije',
                    subtitle: 'Administracija specifikacija'
                });
                break;
            case 5:
                setCurrentAdminTitleAndSubtitle({
                    title: 'Obaviještenja',
                    subtitle: 'Administracija obaviještenja'
                });
                break;
            case 6:
                setCurrentAdminTitleAndSubtitle({
                    title: 'Slideri',
                    subtitle: 'Administracija slidera'
                });
                break;
            case 7:
                setCurrentAdminTitleAndSubtitle({
                    title: 'Porudžbine',
                    subtitle: 'Administracija porudžbina'
                });
            case 8:
                setShowGlobalDiscountDialog(true);
                break;
            default: setCurrentAdminTitleAndSubtitle(initialTitleAndSubtitle);
        }
    }, [currentAdminDisplay]);

    return (
        <div className={classes.adminPageBigDiv}>
            <div className={classes.adminPageNav}>
                <BackIcon style={{ fontSize: '22px', marginLeft: '8px' }} onClick={() => { navigate(-1); }} />
                <div className={classes.adminPageLogoutDiv} onClick={handleLogout}>
                    <LogoutIcon style={{ marginRight: '10px' }} />
                    <Typography>Odjavi se</Typography>
                </div>
            </div>
            <div className={classes.adminPageData}>
                <Typography style={{ fontSize: '26px', fontWeight: 'bold' }}>
                    {currentAdminTitleAndSubtitle.title}
                </Typography>
                <div style={{ marginTop: '4px' }}>
                    <Typography style={{ fontSize: '14px', color: 'gray' }}>{currentAdminTitleAndSubtitle.subtitle}</Typography>
                </div>
            </div>

            {getCurrentAdminDisplay()}
            <GlobalDiscountDialog 
                open={showGlobalDiscountDialog} 
                setOpen={setShowGlobalDiscountDialog}
                setCurrentAdminDisplay={setCurrentAdminDisplay} />
        </div>
    )
}

export default AdminPage