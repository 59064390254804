import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../Hooks/useAuth';

const ProtectedRoute = ({ children }) => {

    const { auth, isAdmin } = useAuth();

    
    return typeof auth !== 'undefined' ? auth ? children : <Navigate to='/login' /> : null;
};

export default ProtectedRoute;
