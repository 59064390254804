import React, { useContext } from 'react';
import useStyles from './style';
import { Typography, Button } from '@mui/material';
import { FaPencilAlt as EditIcon, FaTrashAlt as DeleteIcon } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../App';

const ProductCard = ({ product, isAdmin, setOpenUpdate, setOpenDelete }) => {

    const classes = useStyles();
    const navigate = useNavigate();

    const { currentPage, setCurrentPage, setCurrentProduct } = useContext(GlobalContext);

    const [cardClicked, setCardClicked] = React.useState(false);
    const cardRef = React.useRef(null);

    const cropLongTitle = (title) => {
        return title.length > 28 ? title.slice(0, 28) + '...' : title;
    };

    function handleDocumentClick(event) {
        if (cardRef.current && !cardRef.current.contains(event.target)) {
            setCardClicked(false);
        };
    };

    React.useEffect(() => {
        if (isAdmin) {
            document.addEventListener("click", handleDocumentClick);
            return () => document.removeEventListener("click", handleDocumentClick);
        }
    }, [isAdmin]);

    return (
        <div
            onClick={() => {
                if (isAdmin) {
                    setCardClicked(!cardClicked);
                } else {
                    navigate(`/products/${product.id}`, { state: { previousPath: currentPage } });
                    localStorage.setItem('currentPage', JSON.stringify(2));
                    localStorage.setItem('currentProduct', JSON.stringify(product.id));
                    setCurrentPage(2);
                    setCurrentProduct(product.id);
                }
            }}
            ref={cardRef}
            className={classes.productCard}>
            <div
                className={classes.productImg}
                style={{
                    backgroundImage: `url('${!product.path ? process.env.REACT_APP_UPLOADS_PATH + product.photoGallery[0].path : process.env.REACT_APP_UPLOADS_PATH + product.path}')`
                }}>
            </div>
            <div className={classes.productText}>
                <div style={{ flex: '1' }}>
                    <Typography style={{ fontSize: '10px' }}>
                        {cropLongTitle(product.name)}
                    </Typography>
                </div>
                {
                    !isAdmin
                        ?
                        <div className={classes.productPrices}>

                            <Typography style={{ fontSize: '12.5px', color: '#ce0b0b' }}>
                                {product.lowest_price}&euro;
                            </Typography>

                            {
                                +product.regular_price > +product.lowest_price
                                    ?
                                    <Typography
                                        style={{
                                            fontSize: +product.regular_price > +product.lowest_price ? '10.5px' : '12.5px',
                                            textDecoration: +product.regular_price > +product.lowest_price ? 'line-through' : 'inherit'
                                        }}>
                                        {product.regular_price}&euro;
                                    </Typography>
                                    :
                                    null
                            }
                        </div>
                        :
                        null

                }
            </div>

            {
                isAdmin
                    ?
                    <div
                        style={{
                            visibility: cardClicked ? 'visible' : 'hidden',
                            opacity: cardClicked ? '1' : '0'
                        }}
                        className={classes.adminActions}>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenUpdate({
                                    open: true,
                                    product 
                                });
                            }}
                            className={classes.button}
                            variant='contained'
                            disableElevation style={{ backgroundColor: '#006442' }}>
                            <EditIcon fontSize='16' />
                        </Button>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenDelete({
                                    open: true,
                                    product: { id: product.id, name: product.name }
                                });
                            }}
                            className={classes.button}
                            variant='contained'
                            disableElevation style={{ backgroundColor: '#DC3023' }}>
                            <DeleteIcon fontSize='16' />
                        </Button>
                    </div>
                    :
                    null
            }

        </div>
    );
}

export default ProductCard