import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import useStyles from './style';

const ShowError = ({ errorText, errCode = null }) => {

    const classes = useStyles();

    const [errCodeText, setErrorCodeText] = React.useState('');

    React.useEffect(() => {
        if (errCode) {
            switch (errCode) {
                case 400: setErrorCodeText('Neispravni podaci pokušajte ponovo'); break;
                case 409: setErrorCodeText('Korisnik sa istim telefonom ili email-om već postoji'); break;
                default : setErrorCodeText('');
            }
        }
    }, [errCode]);

    return (
        <Alert variant='filled' severity="error" className={classes.error}>
            <AlertTitle>Neuspješno</AlertTitle>
            { errCodeText || errorText }
        </Alert>
    );
};

export default ShowError;