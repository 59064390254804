import { Typography, LinearProgress, Alert, AlertTitle } from '@mui/material';
import React from 'react';
import useStyles from './style';
import { GoPrimitiveDot as DotIcon } from 'react-icons/go';
import { MdArrowBack as BackIcon } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useGetAllNotificationsUserQuery } from '../../store/reduxApi';
import { useMarkNotificationAsReadMutation } from '../../store/reduxApi';

const Notifications = () => {

    const classes = useStyles();

    const navigate = useNavigate();

    const { data = {}, isLoading, isError } = useGetAllNotificationsUserQuery();

    const [ markNotificationAsRead ] = useMarkNotificationAsReadMutation();

    const cropLongText = (text) => {
        return text.length > 103 ? text.slice(0, 123) + '...' : text;
    };

    const ShowLoading = () => {
        return (
            <div className={classes.loading}>
                <Typography variant='caption'>Učitavanje...</Typography>
                <LinearProgress
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#f86673'
                        }
                    }}
                />
            </div>
        )
    };

    const ShowError = () => {
        return (
            <Alert variant='filled' severity="error" style={{ margin: 'auto', marginBottom: '15px', marginTop: '15px', width: '80%' }}>
                <AlertTitle>Neuspješno</AlertTitle>
                Neuspješno prikazivanje svih obaviještenja
            </Alert>
        )
    };

    const getDate = (date) => {
        var myDate = new Date(date);
        return (myDate.getDate()) + '.' + (myDate.getMonth()+1) + '.' + myDate.getFullYear() + '.'
    };

    return (
        <div className={classes.notificationsDiv}>
            <div className={classes.pageNav}>
                <BackIcon style={{ fontSize: '22px', marginLeft: '8px' }} onClick={() => { navigate(-1); }} />
            </div>
            <div className={classes.notificationsTitle}>
                <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                    Obavještenja
                </Typography>
            </div>
            <div className={classes.notificationsList}>
                {
                    isLoading
                        ?
                        <ShowLoading />
                        :
                        null
                }
                {
                    isError
                        ?
                        <ShowError />
                        :
                        null
                }
                 {
                    data?.notifications?.map((notification, index) => {
                        return (
                            <div
                                onClick={()=>{
                                    markNotificationAsRead({notificationId : notification.id});
                                    //window.open(notification.link, '_blank', 'noreferrer')
                                }} 
                                className={classes.singleNotificationDiv} 
                                key={index}>
                                <div className={classes.notificationStatusAndTitle}>
                                    {
                                        !notification.isRead
                                            ?
                                            <DotIcon style={{ color: '#f86673', marginRight : '5px' }} />
                                            :
                                            null
                                    }
                                    <Typography style={{ fontWeight: 'bold', fontSize : '12px' }}>
                                        {notification.title}
                                    </Typography>
                                </div>
                                <div className={classes.notificationDescription}>
                                    <Typography style={{ fontSize : '11px' }}>
                                        {cropLongText(notification.description)}
                                    </Typography>
                                </div>
                                <div className={classes.notificationDate}>
                                    <Typography variant='subtitle2' style={{ color: !notification.isRead ? '#f86673' : 'black', float : 'right', fontWeight : 'bold' }}>
                                        {
                                            getDate(notification.date)
                                        }
                                    </Typography>
                                </div>
                            </div>
                        )
                    })
                } 
                {
                    data?.notifications?.length === 0
                    ?
                    <Typography>Trenutno nema obaviještenja</Typography>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default Notifications;