import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    bottomBar: {
        position: 'fixed',
        left: '0',
        bottom: '0',
        width: '100%',
        height: '45px',
        paddingTop: '5px',
        boxShadow: '0px -4px 6px -1px rgba(0,0,0,0.15)',
        backgroundColor: 'white',
        zIndex : '100'
    },
    bar: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
        margin: 'auto',
        height: '100%',
        backgroundColor: 'white'
    },
    menuToggleBar: {
        boxShadow: '0px -4px 6px -1px rgba(0,0,0,0.15)',
        position: 'absolute',
        //left: '50%',
        //transform: 'translateX(-50%, 0)',
        width: '45px',
        height: '45px',
        //backgroundColor : '#f86673',
        border: '5px solid white',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '10',
        transform: 'translateY(-15.5px)',
    },
    menuBar: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
    menuBarSection: {
        //border : '1px solid red',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '37%'
    },
    bottomBarButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#ec1767',
    },
}));

export default useStyles;