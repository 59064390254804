import React from 'react';
import useStyles from './style';
import {
    Button, DialogContent, DialogActions,
    Dialog, DialogTitle, Typography
} from '@mui/material';
import { useDeleteSpecificationMutation } from '../../../store/reduxApi';

const DeleteSpecDialog = ({ open, setOpen, specId, ShowLoading, ShowError, ShowSuccess  }) => {

    const classes = useStyles();

    const [deleteSpecification, deleteSpecificationResult] = useDeleteSpecificationMutation();
    const { isLoading, isError, isSuccess, reset } = deleteSpecificationResult;

    const handleClose = () => {
        setOpen({
            open: false,
            spedId: false
        });
        reset();
    };

    const handleDeleteSpecification = () => {
        if (specId) deleteSpecification({ id: specId });
    };

    const ShowDeleteState = () => {
        if(isLoading) return <ShowLoading/>
        if(isError) return <ShowError errorText='Neuspješno brisanje specifikacije pokušajte ponovo'/>
        if(isSuccess) return <ShowSuccess successText='Specifikacija uspješno obrisana'/>
    };

    return (
        <Dialog
            fullWidth
            onClose={handleClose}
            open={open}>
            <DialogTitle>
                Brisanje specifikacije
            </DialogTitle>
            <DialogContent>

                <Typography>Da li ste sigurni da želite da obrišete specifikaciju ?</Typography>

            </DialogContent>

            <ShowDeleteState/>

            <DialogActions style={{ padding: '10px' }}>
                <Button
                    variant='outlined'
                    disableElevation
                    onClick={handleClose}
                >
                    Odustani
                </Button>
                <Button
                    disabled={isLoading}
                    onClick={handleDeleteSpecification}
                    style={{ backgroundColor: '#DC3023' }}
                    variant='contained'
                    disableElevation
                >
                    Obriši
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteSpecDialog;