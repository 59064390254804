import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    bigDiv : {
        width : '90%',
        margin : 'auto',
        marginTop : '20px',
    },
    addButtonDiv : {

    },
    searchDiv : {
        margin : '20px 0px 15px 0px'
    },
    dialogContent : {
        '&.MuiDialogContent-root' : {
            //border : '2px solid red'
            paddingTop : '15px',
            //marginTop : '30px',
            marginBottom : '15px',
    
        },
        paddingTop : '15px'
        // marginTop : '15px',
        // marginBottom : '20px',
        // paddingTop : '15px'
    },
    specificationsTable : {
        //border : '2px solid red'
    },
    table: {
        fontFamily: "'Playfair Display', sans-serif",
        borderCollapse: 'collapse',
        width: '100%',
        '& $td,th': {
            border: '1px solid #cccccc',
            textAlign: 'center',
            padding: '8px'
        },
        '& $th': {
            textAlign : 'left',
            backgroundColor: '#f7f9fb',
            fontSize : '14px'
        },
        '& $td': {
            textAlign : 'left',
            fontSize : '12px'
        }
    },
    singleOption : {
        border : '1px solid #D2D7D3',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        alignItems : 'center',
        padding : '10px',
        borderRadius : '4px'
    }
}));

export default useStyles;