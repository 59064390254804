import React from 'react';
import useStyles from './style';
import {
    Button, Alert, AlertTitle, LinearProgress,
    DialogContent, DialogActions,
    Dialog, DialogTitle, Typography, Checkbox, FormGroup, FormControlLabel
} from '@mui/material';
import { useDeleteCategoryMutation } from '../../../store/reduxApi';

const DeleteCategoryDialog = ({ open, setOpen, category, ShowError, ShowLoading, ShowSuccess }) => {

    const classes = useStyles();

    const [deleteCategory, deleteCategoryResult] = useDeleteCategoryMutation();
    const { isLoading, isError, isSuccess, reset } = deleteCategoryResult;

    const [deleteChildCategories, setDeleteChildCategories] = React.useState(false);

    const handleClose = () => {
        setOpen({
            open: false,
            category: false
        });
        reset();
    };

    const handleDeleteCategory = () => {
        if (category) deleteCategory({ id: category.id, deleteChildCategories });
    };

    const ShowDeleteState = () => {
        if (isLoading) return <ShowLoading />
        if (isError) return <ShowError errorText='Neuspješno brisanje kategorije' />
        if (isSuccess) return <ShowSuccess successText='Kategorija uspješno obrisana' />
    };

    return (
        <Dialog
            fullWidth
            onClose={handleClose}
            open={open}>
            <DialogTitle>
                Brisanje kategorije
            </DialogTitle>
            <DialogContent>

                <Typography>
                    Da li ste sigurni da želite da obrišete {category.parent_category ? 'podkategoriju' : 'kategoriju'} <strong>{category.name}</strong>?
                </Typography>

                {
                    !category.parent_category
                        ?
                        <FormGroup>
                            <FormControlLabel
                                onChange={() => { setDeleteChildCategories(!deleteChildCategories); }}
                                control={<Checkbox value={deleteChildCategories} />}
                                label="Obriši podkategorije?" />
                        </FormGroup>
                        :
                        null
                }

            </DialogContent>

            <ShowDeleteState />

            <DialogActions style={{ padding: '10px' }}>
                <Button
                    variant='outlined'
                    disableElevation
                    onClick={handleClose}
                >
                    Odustani
                </Button>
                <Button
                    disabled={isLoading}
                    onClick={handleDeleteCategory}
                    style={{ backgroundColor: '#DC3023' }}
                    variant='contained'
                    disableElevation
                >
                    Obriši
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteCategoryDialog;