import React from 'react';
import { Typography, LinearProgress } from '@mui/material';
import useStyles from './style';

const ShowLoading = () => {

    const classes = useStyles();

    return (
        <div className={classes.loading}>
            <Typography variant='caption'>Učitavanje...</Typography>
            <LinearProgress/>
        </div>
    )
};

export default ShowLoading;