import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    cartPageBigDiv : {
        //marginBottom : '20px'
    },
    cartPageNav : {
        width: '100%',
        margin: 'auto',
        height: '45px',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        marginBottom: '20px',
        backgroundColor: '#ec1767',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color : 'white'
    },
    cartPageAddress : {
        padding : '3px',
        borderRadius :'4px',
        color : 'white',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-start',
        alignItems : 'center',
        marginRight : '8px',
        backgroundColor:'rgba(189, 195, 199, 0.4)',
        //backgroundColor : '#BDC3C7',
        color : 'white',
    },
    cartItemsNumDiv : {
        marginLeft : '20px',
        marginBottom : '20px'
    },
    cartItemsDiv : {
        //border : '2px solid red',
        width : '95%',
        margin : 'auto'
    },
    cartItemsList : {
        //border : '2px solid green',
        display : 'grid',
        gridTemplateColumns : '1fr',
        gap : '15px',
        marginBottom : '20px'
    },
    cartItem : {
        //border : '2px solid tomato',
        display : 'grid',
        //height : '150px', ??
        gridTemplateColumns : '38% 62%',
        //paddingTop : '5px',
        //paddingBottom : '5px',
        borderRadius : '6px',
        boxShadow: '0px 0.458px 0.645px -0.292px rgba(0,0,0,0.2), 0px 1px 1.583px 0.125px rgba(0,0,0,0.14), 0px 0.375px 1.916px 0.333px rgba(0,0,0,0.12)',
        width : '100%',
        backgroundColor : 'white'
    },
    emptyButton : {
        marginBottom : '80px'
    },
    emptyButtonQuestion : {
        //border : '2px solid red',
        marginBottom : '80px',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-start',
        alignItems : 'center',
        gap : '10px'
    },
    cartItemData : {
        padding : '3px',
        //border : '1px solid tomato',
        display : 'grid',
        gridTemplateColumns : '1fr',
        gap : '8px',
        marginLeft : '5px',
        paddingRight : '5px'
    },
    cartItemPhoto : {
        //border : '2px solid orange',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover', // ili contain ili 110% ?
        backgroundPosition: 'center center',
        backgroundColor: 'white',
        height: '100%',
        borderTopLeftRadius : '6px',
        borderBottomLeftRadius : '6px',
        borderRight : '1px solid #BDC3C7',
    },
    discountAndRemove : {
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        alignItems : 'center',
        width : '100%',
        paddingTop : '5px'
    },
    discount : {
        paddingLeft : '4px',
        paddingRight : '4px',
        borderRadius : '20px',
        color : 'white',
        backgroundColor : '#ec1767'
    },
    data : {
        display : 'flex',
        flexDirection : 'column',
        gap : '4px'
    },
   
    qtyDiv : {
        //border : '2px solid red',
        display : 'flex',
        justifyContent : 'flex-end',
        alignItems : 'center'
    },
    qty : {
        float : 'right',
        //border : '2px solid #BDC3C7',
        width : '50%',
        padding : '1px',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-around',
        alignItems : 'center'
    },
    bottomBar: {
        position: 'fixed',
        left: '0',
        bottom: '0',
        width: '100%',
        marginTop: '40px',
        height: '50px',
        boxShadow: '0px -4px 6px -1px rgba(0,0,0,0.15)',
        zIndex : '20',
        backgroundColor: 'white',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        alignItems : 'center',
        boxSizing : 'border-box',
        paddingLeft : '20px',
        paddingRight : '20px'
    },
}));

export default useStyles;