import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    bigDiv : {
        width : '90%',
        margin : 'auto',
        marginTop : '20px',
    },
    addButtonDiv : {

    },
    slideList : {
        //border : '2px solid red',
        marginTop: '25px',
        marginBottom: '15px',
        display : 'flex',
        flexDirection : 'column',
        gap : '20px'
    },
    singleSlide : {
        //border : '2px solid green',
        display : 'flex',
        flexDirection : 'column',
        gap : '15px',
        backgroundColor : 'white',
        // padding : '0px 5px 5px 5px',
        borderRadius : '6px'
    },
    singleSlidePhoto : {
        //border : '2px solid green',
        height: 'calc(40vw - 5px)',
        //width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'top center',
    },
    slideActions : {
        //border : '2px solid red',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-end',
        alignItems : 'center',
        gap : '14px',
        padding : '5px'
    },
    addSlidePhotoDiv : {
        border : '3px dotted #BDC3C7',
        height: 'calc(35vw - 5px)',
        position : 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'top center',
        marginTop : '5px'
    },
    addImageIcon : {
        position : 'absolute',
        fontSize : '40px', 
        top: '50%', 
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color : '#BDC3C7',
        zIndex : '4'
    }
}));

export default useStyles;