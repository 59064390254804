import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    smallSlider: {
        margin: "auto",
        marginTop: '25px',
        width: '90%',
        //height: '60px',
        whiteSpace: 'nowrap',
        position: 'relative',
        overflowX: 'scroll',
        overflowY: 'hidden',
        scrollBehavior: 'smooth',
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    // .example::-webkit-scrollbar {
    //     display: none;
    //   }
    smallSliderDiv: {
        //width : '720px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '26px'
    },
    smallSliderOneItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
       // border : '1px solid green'
    },
    categoryIcon : {
        width : '28px',
        height : '28px',
        filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(310deg) brightness(110%) contrast(101%)'
    },
    smallSliderIconDiv: {
        //border : '1px solid red',
        //backgroundColor : 'red',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '45px',
        height: '45px',
        borderRadius: '6px',
        color: 'white',
        fontSize: '18px'
    },
}));

export default useStyles;