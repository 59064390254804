import React from 'react';
import useStyles from './style';
import {
    Button, TextField,
    Grid, Alert, AlertTitle, LinearProgress,
    DialogContent, DialogActions,
    Dialog, DialogTitle, Typography, Menu, MenuItem, FormControl, InputLabel, Select, Chip, Box
} from '@mui/material';
import { BiImageAdd as AddImageIcon } from 'react-icons/bi';
import { useAddCategoryMutation, useLazyGetParentCategoriesAdminQuery, useLazyGetSpecificationsQuery } from '../../../store/reduxApi';

const categoryInitialData = {
    name: '',
    seo: '',
    parentCategory: '',
    icon: '',
    specifications: []
};

const AddCategoryDialog = ({ open, setOpen, ShowLoading, ShowError }) => {

    const classes = useStyles();

    const inputFileRef = React.useRef();

    const [categoryData, setCategoryData] = React.useState(categoryInitialData);

    const [previewIcon, setPreviewIcon] = React.useState(null);

    const handleImageChange = (e) => {
        const imgFile = e.target.files[0];
        if (imgFile) {
            setPreviewIcon(URL.createObjectURL(imgFile));
            setCategoryData({
                ...categoryData,
                icon: imgFile
            });
        };
    };

    const [addCategory, addCategoryResult] = useAddCategoryMutation();
    const { isLoading, isError, isSuccess, reset } = addCategoryResult;

    const [triggerSpec, { data: dataSpec = {} }] = useLazyGetSpecificationsQuery();

    const [trigger, { data = {} }] = useLazyGetParentCategoriesAdminQuery();

    React.useEffect(() => {
        if (open) {
            trigger();
            triggerSpec();
        }
    }, [open, categoryData.specifications]);

    React.useEffect(() => {
        if (isSuccess) handleClose();
    }, [isSuccess]);

    const handleClose = () => {
        setOpen(false);
        setCategoryData(categoryInitialData);
        setPreviewIcon(null);
        reset();
    };

    const handleAddNewCategory = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', categoryData.name);
        formData.append('seo', categoryData.seo);
        formData.append('parentCategory', categoryData.parentCategory);
        formData.append('icon', categoryData.icon);
        formData.append('specifications', JSON.stringify(categoryData.specifications));
        if (!categoryData.icon) {
            alert('Dodajte ikonicu');
        } else {
            addCategory(formData);
        }
    };

    return (
        <Dialog
            fullWidth
            onClose={handleClose}
            open={open}>
            <DialogTitle>
                Dodaj novu kategoriju
            </DialogTitle>
            <DialogContent
                sx={{
                    '&.MuiDialogContent-root': {
                        padding: '12px 10px'
                    }
                }}
            >
                <form onSubmit={handleAddNewCategory} id="myform">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography>{previewIcon ? 'Ikonica dodata' : 'Dodajte ikonicu'}</Typography>
                            <div
                                style={{ backgroundImage: `url('${previewIcon}')` }}
                                onClick={() => { inputFileRef.current.click(); }}
                                className={classes.addCategoryIconDiv}>
                                {
                                    !previewIcon
                                        ?
                                        <AddImageIcon className={classes.addImageIcon} />
                                        :
                                        null
                                }
                                <input
                                    accept='image/*'
                                    onChange={handleImageChange}
                                    type="file"
                                    hidden
                                    ref={inputFileRef}
                                />
                            </div>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                autoComplete='off'
                                label='Unesite ime'
                                type='text'
                                value={categoryData.name}
                                onChange={(e) => { setCategoryData({ ...categoryData, name: e.target.value }) }}
                                size='small'
                                fullWidth
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                autoComplete='off'
                                label='Unesite seo'
                                type='text'
                                value={categoryData.seo}
                                onChange={(e) => { setCategoryData({ ...categoryData, seo: e.target.value }) }}
                                size='small'
                                fullWidth
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-simple-select-label">Parent kategorija</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={categoryData.parentCategory}
                                    label="Parent kategorija"
                                    onChange={(e) => {
                                        setCategoryData({ ...categoryData, parentCategory: e.target.value })
                                    }}
                                >
                                    <MenuItem value=''>Nema</MenuItem>
                                    {
                                        data?.categoriesForSelect?.map((category, index) => {
                                            return <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>


                        {
                        categoryData.parentCategory 
                        ?            
                        <Grid item xs={12}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-simple-select-label">Specifikacije</InputLabel>
                                <Select
                                    sx={{
                                        '& 	.MuiSelect-outlined': {
                                            padding: '20px'
                                        }
                                    }}
                                    multiple
                                    labelId="demo-simple-select-label"
                                    value={categoryData.specifications}
                                    label="Specifikacije"
                                    onChange={(e) => {
                                        setCategoryData({
                                            ...categoryData,
                                            specifications: e.target.value
                                        })
                                    }}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={dataSpec?.specifications?.find(spec => spec.id === value).name} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {
                                        dataSpec?.specifications?.map((spec, index) => {
                                            return <MenuItem
                                                key={index}
                                                value={spec.id}>
                                                {spec.name}
                                            </MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        :
                        null
                        }

                    </Grid>
                </form>

            </DialogContent>

            {isError ? <ShowError errorText='Neuspješno dodavanje kategorije' /> : null}
            {isLoading ? <ShowLoading /> : null}

            <DialogActions style={{ padding: '10px' }}>
                <Button
                    variant='outlined'
                    disableElevation
                    onClick={handleClose}
                >
                    Odustani
                </Button>
                <Button
                    disabled={isLoading}
                    form='myform'
                    type='submit'
                    style={{ backgroundColor: '#26A65B' }}
                    variant='contained'
                    disableElevation
                >
                    Potvrdi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddCategoryDialog;