import React from 'react';
import useStyles from './style';
import {
    Button, TextField,
    Grid, Alert, AlertTitle, LinearProgress,
    DialogContent, DialogActions,
    Dialog, DialogTitle, Typography, MenuItem, FormControl, InputLabel, Select, Box, Chip
} from '@mui/material';
import { useUpdateCategoryMutation, useLazyGetParentCategoriesAdminQuery, useLazyGetSpecificationsQuery } from '../../../store/reduxApi';

const UpdateCategoryDialog = ({ open, setOpen, category, ShowLoading, ShowError, ShowSuccess }) => {

    const classes = useStyles();

    const inputFileRef = React.useRef();

    const [categoryData, setCategoryData] = React.useState({});

    const [previewIcon, setPreviewIcon] = React.useState(null);

    const handleImageChange = (e) => {
        const imgFile = e.target.files[0];
        if (imgFile) {
            setPreviewIcon(URL.createObjectURL(imgFile));
            setCategoryData({
                ...categoryData,
                icon: imgFile
            });
        };
    };

    const [updateCategory, updateCategoryResult] = useUpdateCategoryMutation();
    const { isLoading, isError, isSuccess, reset } = updateCategoryResult;

    const [triggerSpec, { data: dataSpec = {} }] = useLazyGetSpecificationsQuery();

    const [trigger, { data = {} }] = useLazyGetParentCategoriesAdminQuery();

    React.useEffect(() => {
        if (category) {
            trigger();
            triggerSpec();
            setCategoryData(category);
        }
    }, [category]);

    const handleClose = () => {
        setOpen({
            open: false,
            category: false
        });
        setCategoryData({});
        setPreviewIcon(null);
        reset();
    };

    const handleUpdateCategory = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('id', categoryData.id);
        formData.append('name', categoryData.name);
        formData.append('seo', categoryData.seo);
        formData.append('parentCategory', categoryData.parent_category ? categoryData.parent_category : '');
        formData.append('icon', categoryData.icon);
        formData.append('specifications', JSON.stringify(categoryData.specifications));
        if (!categoryData.icon) {
            alert('Dodajte ikonicu');
        } else {
            updateCategory(formData);
        }
    };

    return (
        <Dialog
            fullWidth
            onClose={handleClose}
            open={open}>
            <DialogTitle>
                Ažuriraj kategoriju
            </DialogTitle>
            <DialogContent
                sx={{
                    '&.MuiDialogContent-root': {
                        padding: '12px 10px'
                    }
                }}
            >
                <form onSubmit={handleUpdateCategory} id="myform">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography>
                                {previewIcon || typeof categoryData.icon === 'string' ? 'Slika dodata' : 'Dodajte sliku'}
                            </Typography>
                            <div
                                style={{
                                    backgroundImage: `url('${previewIcon ? previewIcon : process.env.REACT_APP_UPLOADS_PATH + categoryData.icon}')`
                                }}
                                onClick={() => { inputFileRef.current.click(); }}
                                className={classes.addCategoryIconDiv}>
                                <input
                                    accept='image/*'
                                    onChange={handleImageChange}
                                    type="file"
                                    hidden
                                    ref={inputFileRef}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                autoComplete='off'
                                label='Unesite ime'
                                type='text'
                                value={categoryData.name || ''}
                                onChange={(e) => { setCategoryData({ ...categoryData, name: e.target.value }) }}
                                size='small'
                                fullWidth
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                autoComplete='off'
                                label='Unesite seo'
                                type='text'
                                value={categoryData.seo || ''}
                                onChange={(e) => { setCategoryData({ ...categoryData, seo: e.target.value }) }}
                                size='small'
                                fullWidth
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-simple-select-label">Kategorija</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={categoryData.parent_category || ''}
                                    label="Kategorija"
                                    onChange={(e) => {
                                        setCategoryData({ ...categoryData, parent_category: e.target.value })
                                    }}
                                >
                                    <MenuItem value=''>Nema</MenuItem>
                                    {
                                        data?.categoriesForSelect?.map((category, index) => {
                                            return <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        {
                            categoryData.parent_category
                                ?
                                <Grid item xs={12}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel id="demo-simple-select-label">Specifikacije</InputLabel>
                                        <Select
                                            multiple
                                            labelId="demo-simple-select-label"
                                            value={categoryData.specifications || []}
                                            label="Specifikacije"
                                            onChange={(e) => {
                                                //const check = categoryData.specifications.findIndex( specId => specId === e.target.value );
                                                setCategoryData({
                                                    ...categoryData,
                                                    specifications: e.target.value
                                                })
                                            }}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={dataSpec?.specifications?.find(spec => spec.id === value).name} />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {
                                                dataSpec?.specifications?.map((spec, index) => {
                                                    return <MenuItem key={index} value={spec.id}>{spec.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                :
                                null
                        }

                    </Grid>
                </form>

            </DialogContent>

            {isError ? <ShowError errorText='Neuspješno ažuriranje kategorije' /> : null}
            {isSuccess ? <ShowSuccess successText='Uspješno ažuriranje kategorije' /> : null}
            {isLoading ? <ShowLoading /> : null}

            <DialogActions style={{ padding: '10px' }}>
                <Button
                    variant='outlined'
                    disableElevation
                    onClick={handleClose}
                >
                    Odustani
                </Button>
                <Button
                    disabled={isLoading}
                    form='myform'
                    type='submit'
                    style={{ backgroundColor: '#26A65B' }}
                    variant='contained'
                    disableElevation
                >
                    Potvrdi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateCategoryDialog;