import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    homeProductsDiv: {
        width: '96%',
        margin: 'auto',
        marginTop: '25px',
        marginBottom : '70px'
    },
    homeProductsTitle: {
        margin: 'auto',
        marginTop: '15px',
        marginBottom: '8px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '95%',
    },
    homeProducts: {

        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        //  width : '96%',
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        placeItems: 'center'

    }
}));

export default useStyles;