import React from 'react';
import { Typography } from '@mui/material';

const AdminPageTypes = ({ classes, pageTypes, setCurrentAdminDisplay }) => {
    return (
        <div className={classes.pageTypes}>
            {
                pageTypes.map((type, index) => {
                    return (
                        <div
                            onClick={() => {
                                setCurrentAdminDisplay(index + 1);
                            }}
                            className={classes.pageType}
                            key={index}>
                            <div style={{ fontSize: '25px' }}>
                                {type.icon}
                            </div>
                            <Typography variant='subtitle2'>
                                {type.title}
                            </Typography>
                        </div>
                    )
                })
            }
        </div>

    )
}

export default AdminPageTypes