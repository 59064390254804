import React from 'react';
import useStyles from './style';
import {
    Button, TextField,
    Grid, Alert, AlertTitle, LinearProgress,
    DialogContent, DialogActions,
    Dialog, DialogTitle
} from '@mui/material';
import { useAddNewNotificationMutation } from '../../../store/reduxApi';

const initialNotificationData = {
    title: '',
    description: '',
    link: ''
}

const AddNotificationDialog = ({ open, setOpen, ShowLoading, ShowError, ShowSuccess }) => {

    const classes = useStyles();

    const [notificationData, setNotificationData] = React.useState(initialNotificationData);

    const [addNewNotification, newNotificationResult] = useAddNewNotificationMutation();
    const { isLoading, isError, isSuccess, reset } = newNotificationResult;

    const handleClose = () => {
        setOpen(false);
        reset();
        setNotificationData(initialNotificationData);
    };

    const handleAddNewNotification = (e) => {
        e.preventDefault();
        addNewNotification(notificationData);
    };

    const handleChange = (e) => {
        setNotificationData({
            ...notificationData,
            [e.target.name]: e.target.value
        });
    };

    React.useEffect(() => {
        if (isSuccess) handleClose();
    }, [isSuccess]);

    return (
        <Dialog
            fullWidth
            onClose={handleClose}
            open={open}>
            <DialogTitle>
                Dodaj novo obaviještenje
            </DialogTitle>
            <DialogContent style={{ padding: '15px', width: '85%', margin: 'auto' }}>
                <form onSubmit={handleAddNewNotification} id="myform">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                autoComplete='off'
                                label='Unesite naslov'
                                type='text'
                                value={notificationData.title}
                                name='title'
                                onChange={handleChange}
                                fullWidth
                                variant='outlined'
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField

                                autoComplete='off'
                                label='Unesite link'
                                type='text'
                                value={notificationData.link}
                                name='link'
                                onChange={handleChange}
                                size='small'
                                fullWidth
                                variant='outlined'
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField

                                autoComplete='off'
                                label='Unesite opis'
                                type='text'
                                value={notificationData.description}
                                name='description'
                                onChange={handleChange}
                                fullWidth
                                variant='outlined'
                                multiline
                                rows={7}
                                required
                            />
                        </Grid>
                    </Grid>
                </form>

                {
                    isLoading
                        ?
                        <div style={{ marginTop: '10px' }}>
                            <ShowLoading />
                        </div>
                        :
                        null
                }

            </DialogContent>

            {isError ? <ShowError errorText='Neuspješno dodavanje obaviještenja pokušajte ponovo' /> : null}
            {/* { isSuccess ? <ShowSuccess successText='Uspješno dodavanje obaviještenja' /> : null } */}



            <DialogActions style={{ padding: '10px' }}>
                <Button
                    variant='outlined'
                    disableElevation
                    onClick={handleClose}
                >
                    Odustani
                </Button>
                <Button
                    disabled={isLoading}
                    form='myform'
                    type='submit'
                    style={{ backgroundColor: '#26A65B' }}
                    variant='contained'
                    disableElevation
                >
                    Potvrdi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddNotificationDialog