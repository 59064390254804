import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    loading: {

    },
    error: {
        margin: 'auto', 
        marginBottom: '15px', 
        width: '80%'
    },
    success: {
        margin: 'auto', 
        marginBottom: '15px', 
        width: '80%'
    }
}));

export default useStyles;