import React, { useContext } from 'react';
import useStyles from './style';
import { Typography } from '@mui/material';
import allCategoriesIcon from '../../assets/allCategories.svg';
import { useGetAllCategoriesQuery } from '../../store/reduxApi';
import { useSearchParams } from 'react-router-dom';
import ProductCard from '../Product Card/ProductCard';
import { useLazyGetProductsQuery } from '../../store/reduxApi';
import ShowLoading from '../Alerts/ShowLoading';
import ShowError from '../Alerts/ShowError';
import { GlobalContext } from '../../App';
import imageNotFound from '../../assets/imageNotFound.svg';

const test = ['Pol', 'Boja', 'Cijena'];

const ProductsPage = () => {

    const classes = useStyles();

    const { searchProductWord } = useContext(GlobalContext);

    const scrollableDivRef = React.useRef(null);

    const [products, setProducts] = React.useState([]);

    const [page, setPage] = React.useState(0);

    const [searchParams] = useSearchParams();

    const [seo, setSeo] = React.useState(searchParams.get('category') || null);

    const [currentCategory, setCurrentCategory] = React.useState(searchParams.get('category') || 'all');

    const [currentCategoryIndex, setCurrentCategoryIndex] = React.useState('all');

    const [currentCategories, setCurrentCategories] = React.useState([]);

    const [trigger, { isLoading, isError, data: dataProducts = {}, error }] = useLazyGetProductsQuery();

    const { data = false } = useGetAllCategoriesQuery();

    React.useEffect(() => {
        //console.log({ page });
        trigger({ category: currentCategory, searchProductWord, page: page < 0 ? 0 : page });
    }, [currentCategory, searchProductWord, page, seo]);

    React.useEffect(() => {
        if (typeof dataProducts.products !== 'undefined') {
            setProducts((prevData) => {
                return page > 0 ? [...prevData, ...dataProducts?.products] : [...dataProducts?.products];
            });
        }
    }, [dataProducts]);

    React.useEffect(() => {
        if (searchProductWord.length > 0) {
            setPage(0)
        }
    }, [searchProductWord]);

    React.useEffect(() => {
        if (data) {
            setCurrentCategories([...data.categories]);
            if (seo) {
                const index = data.categories.findIndex(category => category.seo === seo);
                if (data.categories[index].childCategories) {
                    setCurrentCategories(data.categories[index].childCategories);
                    setCurrentCategoryIndex(0);
                    setCurrentCategory(seo);
                } else {
                    setCurrentCategoryIndex(index);
                }
            }
        }
    }, [data, seo]);

    const handleScroll = (e) => {
        // console.log('scrolling...page is ', page);
        const div = e.target;
        if (div.scrollTop + div.clientHeight >= div.scrollHeight - 1) {
            console.log('end');
            setPage(page + 1);
        }
    };

    const resetScrollAndPage = () => {
        setPage(0);
        //topRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        // topRef.current.focus();
        // topRef.current.scrollTop = 0;
        //scrollableDivRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        scrollableDivRef.current.scrollTop = 0;
    };

    return (
        <div className={classes.productPageMainDiv}>
            <div className={classes.productsDiv}>
                <div className={classes.productsCategories}>
                    <div
                        onClick={() => {
                            //refetch();
                            resetScrollAndPage();
                            setCurrentCategories(data?.categories);
                            setCurrentCategoryIndex('all');
                            setCurrentCategory('all');
                        }}
                        className={
                            currentCategoryIndex === 'all'
                                ?
                                `${classes.singleCategory} ${classes.activeCategory}`
                                :
                                `${classes.singleCategory}`
                        }>
                        <div style={{ fontSize: '18px' }}>
                            <img
                                style={{ width: '20px', height: '20px' }}
                                src={allCategoriesIcon}
                                alt='Category Icon'
                            />
                        </div>
                        <Typography style={{ fontSize: '9px' }}>
                            Sve kategorije
                        </Typography>
                    </div>

                    {
                        currentCategories.map((category, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => {
                                        resetScrollAndPage();
                                        setCurrentCategory(category.seo);
                                        if (category.childCategories) {
                                            setCurrentCategories(category.childCategories);
                                            setCurrentCategoryIndex(0);
                                        } else {
                                            setCurrentCategoryIndex(index);
                                        }
                                    }}
                                    className={index === currentCategoryIndex ? `${classes.singleCategory} ${classes.activeCategory}` : `${classes.singleCategory}`}>
                                    <div style={{ fontSize: '18px' }}>
                                        <img
                                            className={classes.categoryIcon}
                                            src={
                                                category.icon
                                                ?
                                                process.env.REACT_APP_UPLOADS_PATH + category.icon
                                                :
                                                imageNotFound
                                            }
                                            alt='Category Icon' />
                                    </div>
                                    <Typography style={{ fontSize: '9px' }}>
                                        {category.name}
                                    </Typography>
                                </div>
                            )
                        })
                    }
                </div>

                {
                    isLoading
                        ?
                        <div className={classes.status}>
                            <ShowLoading />
                        </div>
                        :
                        isError
                            ?
                            <div className={classes.status}>
                                <ShowError errorText='Greška u prikazivanju proizvoda' errCode={error?.status} />
                            </div>
                            :
                            <div className={classes.products}>
                                <div className={ classes.productsCount }>
                                    <Typography style={{ fontSize : '13px', color: '#BDC3C7' }}>
                                        Broj proizvoda: <strong>{dataProducts?.numOfProducts}</strong>
                                    </Typography>
                                </div>
                                <div className={classes.productsList} onScroll={handleScroll} ref={scrollableDivRef}>
                                {
                                    products.map((product, index) => {
                                        return <ProductCard product={product} key={index} />
                                    })
                                }
                                {
                                    products.length === 0
                                        ?
                                        <Typography>Nema proizvoda</Typography>
                                        :
                                        null
                                }
                            </div>
                            </div>

                }

            </div>

        </div>
    );
};

export default ProductsPage;