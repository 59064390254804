import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    bigDiv : {
        //border : '2px solid red',
        width : '90%',
        margin : 'auto',
        marginTop : '20px',
    },
    addButtonDiv : {

    },
    loading : {
        width : '80%',
        margin : 'auto',
        marginTop : '15px',
        marginBottom : '15px'
    },
    notificationsList: {
        //border : '2px solid green',
        //width: '90%',
        margin: 'auto',
        marginTop: '25px',
        marginBottom: '15px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '10px'
    },
    singleNotificationDiv: {
        backgroundColor: 'white',
        borderRadius: '6px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '3px',
        padding: '8px'
    },
    notificationTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    notificationActions : {
        //border : '2px solid red',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-end',
        alignItems : 'center',
        gap : '14px'
    }
}));

export default useStyles;