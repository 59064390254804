import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    userPageBigDiv: {

    },
    userPageNav: {
        width: '100%',
        margin: 'auto',
        height: '45px',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        marginBottom: '25px',
        backgroundColor: '#f86673',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color : 'white'
    },
    userPageData : {
        marginLeft : '20px'
    },
    userPageMenu : {
        width : '100%',
        marginTop : '20px',
        backgroundColor : 'white'
    },
    userPageMenuItem : {
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        alignItems : 'center',
        padding : '15px'
    },
    userPageMenuItemIconTitle : {
        display : 'grid',
        gridTemplateColumns : '1fr 3fr',
        gap : '5px',
        alignItems : 'center',
        fontSize : '20px',
        width : '48%'
    },
    userPageLogoutDiv : {
        // border : '2px solid red',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-start',
        alignItems : 'center',
        color: '#22A7F0',
        marginTop  : '20px',
        marginLeft : '10px'
    },
    userPageIsVerified : {
        marginTop : '10px',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-start',
        gap : '15px'
    },
    editUserDataBigDiv: {
        //border : '1px solid red',
        width: '91%',
        margin: 'auto',
        marginBottom: '25px',
        marginTop : '20px'
    },
    registerTitle: {
        marginBottom: '18px'
    },
    registerTextField: {

        '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
            color: 'black',
            // paddingLeft : '0px',
            // paddingRight : '0px',
            '& fieldset': {
                border: 'none',
                boxShadow: '0px 0.458px 0.645px -0.292px rgba(0,0,0,0.2), 0px 1px 1.583px 0.125px rgba(0,0,0,0.14), 0px 0.375px 1.916px 0.333px rgba(0,0,0,0.12)',
            },
            "& .Mui-focused fieldset": {
                border: '2px solid red',
                //#BDC3C7
            }
        }
    },
    registerGridItem: {
        display: 'flex',
        flexDirection: 'column',
        gap: '3px',
    },
}));

export default useStyles;