import React from 'react';
import useStyles from './style';
import {
    Button, TextField,
    Grid, Alert, AlertTitle, LinearProgress,
    DialogContent, DialogActions,
    Dialog, DialogTitle, Typography
} from '@mui/material';
import { useUpdateSlideMutation } from '../../../store/reduxApi';

const UpdateSlideDialog = ({ open, setOpen, slide, ShowLoading, ShowError, ShowSuccess }) => {

    const classes = useStyles();

    const inputFileRef = React.useRef();

    const [slideData, setSlideData] = React.useState({});

    const [previewPhoto, setPreviewPhoto] = React.useState(null);

    const handleImageChange = (e) => {
        const imgFile = e.target.files[0];
        if (imgFile) {
            setPreviewPhoto(URL.createObjectURL(imgFile));
            setSlideData({
                ...slideData,
                photo: imgFile
            });
        };
    };

    React.useEffect(() => {
        if (slide) setSlideData(slide);
    }, [slide]);

    const [updateSlide, updateSlideResult] = useUpdateSlideMutation();
    const { isLoading, isError, isSuccess, reset } = updateSlideResult;

    const handleClose = () => {
        setOpen({
            open: false,
            slide: false
        });
        setPreviewPhoto(null);
        reset();
    };

    const handleAddNewSlide = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('id', slideData.id);
        formData.append('link', slideData.link);
        formData.append('slidePhoto', slideData.photo);
        if (slideData.photo) {
            updateSlide(formData);
        } else {
            alert('Morate dodati sliku');
        }
    };

    return (
        <Dialog
            fullWidth
            onClose={handleClose}
            open={open}>
            <DialogTitle>
                Dodaj novi slajd
            </DialogTitle>
            <DialogContent
                sx={{
                    '&.MuiDialogContent-root': {
                        padding: '12px 10px'
                    }
                }}
            >
                <form onSubmit={handleAddNewSlide} id="myform">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography>
                                {previewPhoto || typeof slide.photo === 'string' ? 'Slika dodata' : 'Dodajte sliku'}
                            </Typography>
                            <div
                                style={{
                                    backgroundImage: `url('${previewPhoto ? previewPhoto : process.env.REACT_APP_UPLOADS_PATH + slide.photo}')`
                                }}
                                onClick={() => { inputFileRef.current.click(); }}
                                className={classes.addSlidePhotoDiv}>
                                <input
                                    accept='image/*'
                                    onChange={handleImageChange}
                                    type="file"
                                    hidden
                                    ref={inputFileRef}
                                />
                            </div>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete='off'
                                label='Unesite link'
                                type='text'
                                value={slideData.link || ''}
                                onChange={(e) => { setSlideData({ ...slideData, link: e.target.value }) }}
                                size='small'
                                fullWidth
                                variant='outlined'
                            />
                        </Grid>

                    </Grid>
                </form>

            </DialogContent>

            { isError ? <ShowError errorText='Neuspješno ažuriranje slajda' /> : null }
            { isSuccess ? <ShowSuccess successText='Uspješno ažuriranje slajda' /> : null }
            { isLoading ? <ShowLoading /> : null }

            <DialogActions style={{ padding: '10px' }}>
                <Button
                    variant='outlined'
                    disableElevation
                    onClick={handleClose}
                >
                    Odustani
                </Button>
                <Button
                    disabled={isLoading}
                    form='myform'
                    type='submit'
                    style={{ backgroundColor: '#26A65B' }}
                    variant='contained'
                    disableElevation
                >
                    Potvrdi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateSlideDialog;