import React from 'react';
import useStyles from './style';
import {
     Typography, IconButton, TextField, InputAdornment,
    LinearProgress, Alert, AlertTitle
} from '@mui/material';
import { useGetUsersQuery } from '../../../store/reduxApi';
import { TiTick as TickIcon } from 'react-icons/ti';
import { MdClose as CrossIcon } from 'react-icons/md';
import { RxMagnifyingGlass as MagnifyIcon } from 'react-icons/rx';

const Users = () => {

    const classes = useStyles();

    const { isLoading, isError, data = {} } = useGetUsersQuery();

    const [searchWord, setSearchWord] = React.useState('');
    const [searchStart, setSearchStart] = React.useState(false);

    const ShowLoading = () => {
        return (
            <div className={classes.loading}>
                <Typography variant='caption'>Učitavanje...</Typography>
                <LinearProgress
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#f86673'
                        }
                    }}
                />
            </div>
        )
    };

    const ShowError = () => {
        return (
            <Alert variant='filled' severity="error" style={{ margin: 'auto', marginBottom: '15px', marginTop: '15px', width: '80%' }}>
                <AlertTitle>Neuspješno</AlertTitle>
                Neuspješno prikazivanje svih korisnika
            </Alert>
        )
    };

    return (
        <div className={classes.bigDiv}>
            {isLoading ? <ShowLoading /> : null}
            {isError ? <ShowError /> : null}
            <div className={classes.searchDiv}>
                <TextField
                    //disabled={isError}
                    autoComplete='off'
                    sx={{
                        '& .MuiInputAdornment-root': {
                            marginRight: '15px'
                        },
                        '& .MuiOutlinedInput-root': {
                            height: '40px',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                            "&.Mui-focused fieldset": {
                                borderColor: "#f86673"
                            }
                        },
                    }}
                    fullWidth
                    inputProps={{ style: { fontSize: '14px' } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <MagnifyIcon />
                            </InputAdornment>
                        ),
                    }}
                    placeholder='Pretraži korisnike'
                    value={searchWord}
                    onChange={(e) => { setSearchStart(true); setSearchWord(e.target.value); }}
                    size="small"
                //helperText='Minimum 3 karaktera'
                />

            </div>
            <table className={classes.table}>
                <tbody>
                <tr>
                    <th>Ime i prezime</th>
                    <th>Kontakt</th>
                    <th>Grad</th>
                    <th>Verifikovan</th>
                </tr>
                {
                    data?.users?.map((user, index) => {
                        return (
                            <tr key={index}>
                                <td>{user.name} {user.surname}</td>
                                <td>{user.phone}</td>
                                <td>{user.city}</td>
                                <td style={{ textAlign : 'center' }}>
                                    {
                                        user.is_verified
                                        ?
                                        <TickIcon/>
                                        :
                                        <CrossIcon style={{ fontSize : '20px', color : '#DC3023' }}/>
                                    }
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div>
    );
};

export default Users